export class RequestError extends Error {
  private response: Response;

  private statusCode: number;

  private responseData: Record<string, number | string>;

  constructor(
    response: Response,
    responseData: Record<string, number | string>,
  ) {
    super(`${response.statusText} ${response.status}`);
    this.response = response;
    this.statusCode = response.status;
    this.responseData = responseData;
  }

  public getResponse() {
    return this.response;
  }

  public getStatusCode() {
    return this.statusCode;
  }

  public getResponseData() {
    return this.responseData;
  }
}
