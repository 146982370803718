import { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './checkboxField.module.scss';

interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
}

export function CheckboxField({ label, className, ...props }: ICheckbox) {
  return (
    <label htmlFor={props.id} className={clsx(styles.root, className)}>
      <input type="checkbox" {...props} className={styles.swCheckbox} />
      {label}
    </label>
  );
}
