import { useMobileSidebarModal } from '@widgets/mobile-sidebar';
import { IMenuElement } from '@entities/menu';
import { useCheckCountries } from '@entities/countries';
import { IHeaderMenu } from '@entities/header-menu';
import { Burger } from '@shared/ui/burger/Burger';
import styles from './mobileSidebarOpener.module.scss';

interface IMobileSidebarOpenerProps {
  mainDomain: string;
  menuList: IMenuElement[];
  cityName: string;
  cityPhone: string;
  isCheckCountries: ReturnType<typeof useCheckCountries>;
  showSelectCity: () => void;
  headerMenu: IHeaderMenu[];
  cityAvailableLangs?: string[];
}

export function MobileSidebarOpener({ ...props }: IMobileSidebarOpenerProps) {
  const modal = useMobileSidebarModal();
  const activeMenu = () => {
    modal.show(props);
  };

  return (
    <button
      className={styles.root}
      onClick={activeMenu}
      aria-label="burger"
      type="button"
    >
      <Burger />
    </button>
  );
}
