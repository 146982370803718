import { flatten } from 'lodash';
import { IProduct, ISuperProduct } from 'types/product';

export const getCurrentProduct = (
  products: (IProduct | ISuperProduct)[],
  product: IProduct,
): IProduct | ISuperProduct => {
  const superProduct = products.find((el) => {
    const isSuperProduct = 'products' in el;
    if (isSuperProduct) {
      return el.product_toppings?.some((t) => t.product_id === product.id);
    }
    return false;
  });
  return superProduct || product;
};

const buildProductStructure = (products: IProduct[]) => {
  const superAndAnotherProducts = products.reduce<{
    another: IProduct[];
    super: ISuperProduct[];
  }>(
    (acc, product) => {
      if (product.product_type) {
        const superProduct = { ...product, products: [] } as ISuperProduct;
        acc.super.push(superProduct);
        return acc;
      }
      acc.another.push(product);
      return acc;
    },

    { another: [], super: [] },
  );

  const piecesIds = flatten(
    superAndAnotherProducts.super.map((el) =>
      el.product_toppings.map((t) => t.product_id),
    ),
  );

  const piecesOfSuper = superAndAnotherProducts.another.filter((p) =>
    piecesIds.includes(p.id),
  );

  const casual = superAndAnotherProducts.another.filter(
    (p) => !piecesIds.includes(p.id),
  );
  return { super: superAndAnotherProducts.super, piecesOfSuper, casual };
};

const buildSuperProduct = (
  structure: ReturnType<typeof buildProductStructure>,
): ISuperProduct[] =>
  structure.super.map((product) => {
    const pieces = product.product_toppings.map((el) => {
      const findedProduct = structure.piecesOfSuper.find(
        (piece) => piece.id === el.product_id,
      );
      if (!findedProduct) {
        throw new Error("don't found piece product");
      }
      return findedProduct;
    });
    return {
      ...product,
      products: pieces,
    };
  });

export const normalizeProducts = (products: IProduct[]) => {
  const productsWithoutHide = products.filter((f) => !f.hide);
  const structure = buildProductStructure(productsWithoutHide);
  const superProducts = buildSuperProduct(structure);

  const result: (IProduct | ISuperProduct)[] = [
    ...superProducts,
    ...structure.casual,
  ].sort((a, b) => a.position - b.position);
  return result;
};
