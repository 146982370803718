import { useAppSelector } from '@shared/lib/store';
import {
  IDeliveryCheckParams,
  deliverySliceSelectors,
  useDeliveryCheckQuery,
} from '@entities/delivery';
import * as cartHelper from 'modules/helpers/cart-helper';
import { skipToken } from '@reduxjs/toolkit/query';
import { ICart } from 'types/cartTypes';
import { ICoordinates } from '@shared/third-party-libs/commonMap';
import { useTranslation } from 'react-i18next';

const getDeliveryCheckParams = ({
  cart,
  geoData,
  delivery_date,
  cityTextId,
  lang,
}: {
  cart: ICart;
  geoData: { latitude: string; longitude: string };
  delivery_date: string;
  cityTextId: string;
  lang: string;
}) => {
  const { total: order_sum, phone_bonuses, promo_code, bonus_phone } = cart;
  const product_ids = cartHelper.getActiveProductIds(cart);

  const deliveryCheckParams: IDeliveryCheckParams = {
    ...geoData,
    order_sum,
    product_ids,
    city: cityTextId,
    lang,
  };

  if (phone_bonuses.has_card) {
    deliveryCheckParams.enabled_discount = 1;
  }
  if (delivery_date.length) {
    deliveryCheckParams.delivery_date = delivery_date;
  }
  if (promo_code) {
    deliveryCheckParams.promo_code = promo_code;
  }
  if (bonus_phone) {
    deliveryCheckParams.bonus_phone = bonus_phone;
  }
  return deliveryCheckParams;
};

const useAddressDeliveryCheck = ({
  isPickup,
  selectedTime,
  selectedDate,
  cart,
  toTime,
  cityCoords,
  cityTextId,
  isMapDisabled,
}: {
  isPickup: boolean;
  selectedTime: string;
  selectedDate: string;
  cart: ICart;
  cityCoords: ICoordinates;
  cityTextId: string;
  toTime?: boolean;
  isMapDisabled?: boolean;
}) => {
  const {
    i18n: { language: lang },
  } = useTranslation();

  const { fullAddress } = useAppSelector(
    deliverySliceSelectors.selectFullAddress,
  );

  const { deliveryData, deliveryError } = useAppSelector(
    deliverySliceSelectors.selectDeliveryState,
  );

  let deliveryCheckParams: IDeliveryCheckParams | typeof skipToken = skipToken;

  if (
    isPickup ||
    (!fullAddress && !isMapDisabled) ||
    (toTime && !selectedTime)
  ) {
    deliveryCheckParams = skipToken;
  } else if (isMapDisabled) {
    deliveryCheckParams = getDeliveryCheckParams({
      cart,
      geoData: {
        latitude: String(cityCoords.lat),
        longitude: String(cityCoords.lng),
      },
      delivery_date: toTime ? `${selectedDate} ${selectedTime}` : '',
      cityTextId,
      lang,
    });
  } else if (fullAddress) {
    deliveryCheckParams = getDeliveryCheckParams({
      cart,
      geoData: {
        latitude: fullAddress.latitude,
        longitude: fullAddress.longitude,
      },
      delivery_date: toTime ? `${selectedDate} ${selectedTime}` : '',
      cityTextId,
      lang,
    });
  }

  useDeliveryCheckQuery(deliveryCheckParams);

  return { fullAddress, deliveryData, deliveryError };
};

export default useAddressDeliveryCheck;
