import { ReactNode } from 'react';
import { clsx } from 'clsx';
import Counter from 'src/shared/ui/Counter/Counter';
import styles from './complectationItem.module.scss';

export interface ICompItemData {
  product_id: number;
  qty: number;
  title: string;
  weight: number;
  weight_type: string;
}

interface IComplectationItem {
  itemData: ICompItemData;
  isExact: boolean;
  moreAvaliable: boolean;
  summPrice: ReactNode;
  setQty: (id: number, qty: number) => void;
}

function ComplectationItem({
  itemData,
  isExact,
  moreAvaliable,
  summPrice,
  setQty,
}: IComplectationItem) {
  const { product_id, qty, title, weight, weight_type } = itemData;
  return (
    <div className={styles.compRow}>
      <div
        className={clsx({
          [styles.containerWithQty]: isExact,
          [styles.containerInfo]: !isExact,
        })}
      >
        <div className={styles.textContainer}>
          <div className={styles.title}>
            {`${title} ${weight}${weight_type}`}
          </div>
          {summPrice}
        </div>
        {!isExact ? (
          <div className={styles.qtyContainer}>
            <span>x {qty}</span>
          </div>
        ) : (
          <Counter
            qty={qty}
            className={styles.qtyContainer}
            step={1}
            disabled={!moreAvaliable}
            setQty={(count) => setQty(product_id, count)}
          />
        )}
      </div>
    </div>
  );
}

export default ComplectationItem;
