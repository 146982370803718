import './Template.scss';
import '../../Components/Form/Form.scss';
import '@shared/ui/Radio/Radio.scss';
import api from 'modules/helpers/api';
import autobind from 'autobind-decorator';
import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import { stripCity } from 'modules/helpers/url-helper';
import { historyEmitter, seoEmitter } from 'modules/event-emitters';
import findYaCounter from 'modules/yandexCounter/find-ya-counter';
import { ToppingsModal } from '@features/toppings-modal';
import { Component } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SubscribeModal from '../../Components/Modals/SubscribeModal/SubscribeForm';
import MainCartModal from '../../Components/NewCartOrder/Modals/main-card-modal/MainCartModal';
import Emitter from '../../Components/NewCartOrder/Emitters';
import PageScroller from '../../helpers/PageScroller';
import historyHelper from '../../helpers/history-helper';

class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // user: session.get('user'),
      modal: false,
      modalValue: undefined,
      modalLinks: undefined,
      modalInfo: undefined,
    };
  }

  componentDidMount() {
    const { location: { pathname = '' } = {} } = this.props;
    const pageScroller = new PageScroller(pathname);

    const { history } = this.props;
    let lastLocation = history.location;
    this.unlisten = history.listen((location) => {
      if (historyHelper.isSame(lastLocation, location)) {
        return;
      }
      lastLocation = location;

      this.webvisorListener(location.pathname);
      pageScroller.update(location.pathname);

      api('seo.page', {
        url: stripCity(location.pathname, session.get('city')),
      })
        .then(({ seo }) => {
          globalStore.set('seo', seo || {});
          seoEmitter.emit('SEO_UPDATE');
        })
        .catch((error) => {
          console.error(error);
        });
    });

    Emitter.addListener('MODAL_CALL', this.modalCall);
    Emitter.addListener('MODAL_CLOSE', this.modalClose);
    historyEmitter.addListener('HISTORY_PUSH', this.historyPush);
    window.reRender = () => this.forceUpdate();
  }

  componentWillUnmount() {
    Emitter.removeListener('MODAL_CALL', this.modalCall);
    Emitter.removeListener('MODAL_CLOSE', this.modalClose);
    historyEmitter.removeListener('HISTORY_PUSH', this.historyPush);
    if (this.unlisten) {
      this.unlisten();
    }
  }

  historyPush = (push) => {
    const { history } = this.props;
    if (push) {
      history.push(push);
    }
  };

  @autobind
  modalCall({ modal, value, info, buttons = [], links }) {
    this.setState({
      modal,
      modalValue: value,
      modalLinks: links,
      modalInfo: info,
      modalButtons: buttons,
    });
  }

  @autobind
  modalClose() {
    this.setState({ modal: false, modalValue: undefined });
  }

  @autobind
  webvisorListener(pathname) {
    const { counters = [] } = this.state;
    if (!counters.length) {
      const counters = findYaCounter();
      if (counters.length) {
        this.setState({ counters });
        counters.forEach((ya) => {
          ya.hit(pathname);
        });
      }
    } else {
      counters.forEach((ya) => ya.hit(pathname));
    }
  }

  render() {
    const { modal, modalValue, modalLinks, modalInfo, modalButtons } =
      this.state;
    const isModal = !!modal;
    return (
      <>
        <SubscribeModal />
        <ToppingsModal />
        {isModal && (
          <MainCartModal
            {...{
              tag: modal,
              value: modalValue,
              info: modalInfo,
              buttons: modalButtons,
              links: modalLinks,
            }}
          />
        )}
      </>
    );
  }
}

function EnrichedTemplate() {
  const history = useHistory();
  const location = useLocation();

  return <Template history={history} location={location} />;
}

export default EnrichedTemplate;
