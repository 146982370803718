import { useTranslation } from 'react-i18next';
import { SmartLink } from '@shared/lib/routing';
import styles from './lables.module.scss';

export function Familiarization() {
  const { t } = useTranslation();
  return (
    <p className={styles.labelText}>
      {t('BlockTotal.By_making_an_order_for')}
      <SmartLink isDefaultStyled href="/">
        {t('BlockTotal.site')}
      </SmartLink>
      &nbsp;
      {t('BlockTotal.i_confirm')}&nbsp;
      <SmartLink isDefaultStyled href="/page/rules/">
        {t('BlockTotal.address')}
      </SmartLink>
      ,&nbsp;
      {t('BlockTotal.and_confirm')}
    </p>
  );
}

export function PersonalData() {
  const { t } = useTranslation();
  return (
    <p className={styles.labelText}>
      {t('BlockTotal.By_making_an_order_for')}
      <SmartLink isDefaultStyled href="/">
        {t('BlockTotal.site')}
      </SmartLink>
      &nbsp;
      {t('BlockTotal.iAgreeToTheCollection')}
      <SmartLink isDefaultStyled href="/page/terms/">
        {t('BlockTotal.privacy')}
      </SmartLink>
      .
    </p>
  );
}

export function Messages() {
  const { t } = useTranslation();
  return (
    <p className={styles.labelText}>
      {t('BlockTotal.By_making_an_order_for')}
      <SmartLink isDefaultStyled href="/">
        {t('BlockTotal.site')}
      </SmartLink>
      &nbsp;
      {t('BlockTotal.i_give_my_consent')}
    </p>
  );
}

export function AlergicProducts() {
  const { t } = useTranslation();
  return (
    <p className={styles.labelText}>
      {t('BlockTotal.By_making_an_order')}&nbsp;
      {t('BlockTotal.iConfirmBuy')}&nbsp;
      <SmartLink
        isDefaultStyled
        href="/akcii/polnye-usloviya-akcii-dr-sv-sekretnyj-tovar/?utm_source=allergen"
      >
        {t('BlockTotal.alergicProducts')}
      </SmartLink>
      .
    </p>
  );
}
