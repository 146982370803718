import globalStore from 'modules/global-store';
import productHelper from './product-helper';

const get = (textId) => {
  const menu = globalStore.get('menu', []);
  for (let i = 0; i < menu.length; i += 1) {
    if (menu[i].text_id === textId) {
      return menu[i];
    }
  }
  return null;
};
const getById = (id) => {
  const menu = globalStore.get('menu', []);
  for (let i = 0; i < menu.length; i += 1) {
    if (menu[i].id === id) {
      return menu[i];
    }
  }
  return null;
};
const pushProduct = (textId, product) => {
  if (!product || !product.id) {
    return;
  }
  const { hidden = false } = product;
  if (hidden) {
    return;
  }
  const menu = get(textId);
  if (!menu) {
    return;
  }
  if (!menu.products) {
    menu.products = [];
  }
  menu.products.push(product.id);
};
const getProducts = (textId) => {
  const menu = get(textId);
  if (!menu || !menu.products) {
    return [];
  }
  const products = [];
  menu.products.forEach((id) => {
    products.push(productHelper.get(id));
  });
  return products;
};

export default {
  get,
  getById,
  pushProduct,
  getProducts,
};
