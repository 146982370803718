import { Route } from 'react-router-dom';
import { PageContainer } from '@shared/ui/content-containers';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';

interface IErrorPageProps {
  action_link?: string;
  action_text?: string;
}

interface IStatusProps {
  code: number;
  children: JSX.Element;
}

function Status({ code, children }: IStatusProps) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = code;
        return children;
      }}
    />
  );
}
function ErrorPage({ action_link, action_text }: IErrorPageProps) {
  return (
    <Status code={404}>
      <PageContainer>
        <ErrorComponent action_link={action_link} action_text={action_text} />
      </PageContainer>
    </Status>
  );
}

export default ErrorPage;
