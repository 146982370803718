import { SmartLink } from '@shared/lib/routing';
import styles from './footer-links.module.scss';
import { IFooterLink } from '../model/types';

export function FooterLinks({ links }: { links: IFooterLink[] }) {
  return (
    <div className={styles.navigationContainer}>
      {links.map((link) => {
        const { title, url, id } = link;

        return (
          <SmartLink key={id} href={url} className={styles.link}>
            {title}
          </SmartLink>
        );
      })}
    </div>
  );
}
