import { IComplectationItem, IComplectationPaid } from '../types';
import useDebouncer from './useDebouncer';

const isThereFree = (complectation: IComplectationItem[]) =>
  !!complectation.filter(({ max }) => max).length;

const isPaidSelected = (paid: IComplectationPaid[]) =>
  paid.some(({ qty }) => qty);

export { isThereFree, isPaidSelected, useDebouncer };
