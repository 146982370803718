import * as session from 'modules/session';
import Emitter from 'src/frontend/Components/NewCartOrder/Emitters';
import Http from './Http';

/**
 * Set promo code and emit event 'CART_CHANGE' with calculated cart.
 * @param {string} promocode
 * @param {number|null} productId
 * @returns {Promise<cart>} - promise with calculated cart
 * @private
 */
function setPromocode(promocode = '', productId = null) {
  return Http.setPromocode(promocode, productId).then((cart) => {
    session.set('cart', cart);
    Emitter.emit('CART_CHANGE', { notChangeUrl: true });
    return cart;
  });
}

export const Promocode = {
  /**
   * Set promocode.
   * @param {string} promocode
   * @param {number} productId
   * @returns {Promise<cart>}
   */
  setPromocode: (promocode, productId) => {
    if (promocode) {
      return setPromocode(promocode, productId);
    }

    return Promocode.resetPromocode();
  },

  /**
   * Reset promocode.
   * @returns {Promise<cart>}
   */
  resetPromocode: () => setPromocode(),
};

export default Promocode;
