import { Preloader } from '@shared/ui/preloader';
import { useTranslation } from 'react-i18next';
import { BlockContainer } from '@shared/ui/content-containers';
import { Subtitle } from '@shared/ui/titles';
import { useSelectCountryMainDomain } from '@entities/countries';
import useUserIsExist from 'frontend/data/user/useUserExist';
import {
  ActiveOrder,
  getProductsIds,
  IUserOrder,
  OrderList,
  OrderStatus,
  useGetUserOrdersQuery,
} from '@entities/user';
import { getProductMenuUrl, useGetProductQuery } from '@entities/product';
import { useReorderModal } from '@features/modals/reorder-modal';
import { useRouteLoaderData } from '@shared/lib/routing';
import styles from './orders.module.scss';

function Orders() {
  const { t, i18n } = useTranslation();
  const mainDomain = useSelectCountryMainDomain();
  const { isUserExist } = useUserIsExist();
  const { country, city, menu } = useRouteLoaderData('city-layout');
  const { show: showReorderModal } = useReorderModal();
  const showReorder = (order: IUserOrder) =>
    showReorderModal({
      order,
      mainDomain: country.main_domain,
      cityTextId: city.text_id,
      menu,
    });

  const { data, isFetching, isUninitialized } = useGetUserOrdersQuery(
    {
      lang: i18n.language,
    },
    { skip: !isUserExist },
  );

  const orders = data?.data?.orders;
  const isProcessing = isFetching || isUninitialized;

  const activeOrder = orders?.find(
    (order) => order.status_id < OrderStatus.Delivered,
  );
  const items = activeOrder?.items || [];
  const productsIds = getProductsIds(items);

  const { data: productsData, isUninitialized: isProductUninitialized } =
    useGetProductQuery(
      { city: city.text_id, lang: i18n.language, id: productsIds },
      {
        skip: isProcessing,
        selectFromResult: (result) => ({
          data: result.data?.data?.product || [],
          isUninitialized: result.isUninitialized,
        }),
      },
    );

  const itemsWithUrl = items.map((item) => {
    const product = productsData.find(
      (itemProduct) => itemProduct.id === item.product_id,
    );
    const productMenu = menu.find(
      (itemMenu) => itemMenu.id === product?.menu_id,
    );
    const productUrl = getProductMenuUrl(product, productMenu?.text_id);

    return {
      ...item,
      productUrl,
    };
  });

  if (isProductUninitialized) return <Preloader />;

  return (
    <>
      {activeOrder && (
        <div className={styles.active}>
          <p className={styles.activeTitle}>{t('OrderList.active_order')}</p>
          <ActiveOrder
            order={activeOrder}
            items={itemsWithUrl}
            mainDomain={mainDomain}
          />
        </div>
      )}
      <BlockContainer childrenPosition="start">
        <Subtitle>
          {t('Orders.my_orders')} {orders?.length ? `(${orders.length})` : ''}
        </Subtitle>
        <OrderList orders={orders} showReorderModal={showReorder} />
      </BlockContainer>
    </>
  );
}

export default Orders;
