import { mapCloseAttribute } from '@shared/third-party-libs/commonMap';
import { CloseButton } from '@shared/ui/close-button';
import styles from './balloonLayout.module.scss';

export function BallonLayout({
  children,
}: {
  children: JSX.Element | string | null;
}) {
  return (
    <div id="popover" className={styles.balloonLayoutGoogle}>
      {children}
      <CloseButton {...mapCloseAttribute} />
    </div>
  );
}
