import './orderingForm.scss';
import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import removeUnwantedCharacters from 'modules/helpers/remove-unwanted-characters';
import * as session from 'modules/session';
import { userEmitter } from 'modules/event-emitters';
import api from 'modules/helpers/api';
import storeHelper from '@entities/stores/lib/store-helper';
import * as cartHelper from 'modules/helpers/cart-helper';
import { useTranslation } from 'react-i18next';
import { analytics } from 'frontend/analytics/analytics';
import { useGetStoresListQuery } from '@entities/stores';
import { FieldsType } from 'frontend/analytics/analyticTypes';
import { ICart } from 'types/cartTypes';
import {
  parse,
  formaterDate,
  isValid,
  formatHelper,
  format,
} from '@shared/lib/date';
import { ValidationError } from 'yup';
import { getCurrencySymbol } from '@entities/countries';
import { getMapType } from '@shared/third-party-libs/commonMap';
import { useSelectedStore } from '@entities/pickup';
import { skipToken } from '@reduxjs/toolkit/query';
import { useRouteLoaderData } from '@shared/lib/routing';
import { useErrorCartModal } from '@features/modals/cart-error-modal';
import { showDeliveryErrorModal } from './lib';
import Emitter from '../../Emitters';
import BlockTotal from '../BlockTotal/BlockTotal';
import { scrollToExecution } from '../../Helpers';
import { getFields, fillFields } from './model/scheme';
import useAddressDeliveryCheck from './lib/useAddressDeliveryCheck';
import { getValidationScheme } from './lib/getValidationScheme';
import PickupFormTitle from './PickupFormTitle';
import DeliveryFormTitle from './DeliveryFormTitle';

const nowDeliveryType = '1';
const toTimeDeliveryType = '2';

interface ITargetImmitation {
  name: string;
  type?: string;
  value?: string;
  disabled?: boolean;
}
export interface IChangeEventImitation {
  target: ITargetImmitation;
}

type CustomEvent =
  | ChangeEvent<HTMLInputElement | HTMLSelectElement>
  | IChangeEventImitation;

function OrderingForm() {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const history = useHistory();

  const {
    country: { map_type: mapTypeByCountry, currency: countryCurrency },
    city: {
      available_for_order: availableForOrder,
      info: {
        delivery: { avg_delivery_time, order_min },
      },
      settings: { map_disabled },
      city_name: cityName,
      latitude: lat,
      longitude: lng,
      map_type: mapTypeByCity,
      text_id: cityTextId,
    },
  } = useRouteLoaderData('city-layout');

  const isMapDisabled = !!map_disabled;
  const cityCoords = { lat, lng };
  const mapTypes = getMapType({
    mapTypeByCity,
    mapTypeByCountry,
  });
  const currency = getCurrencySymbol(countryCurrency);

  const errorCartModal = useErrorCartModal();

  const onError = (text: string) => {
    errorCartModal.show({ text });
  };

  const { store_coords } = useParams<{ store_coords: string }>();

  const formElement = useRef<HTMLFormElement>(null);
  const thisBlock = useRef<HTMLElement>(null);

  const isPickup = !!store_coords;

  const { data: stores = [] } = useGetStoresListQuery(
    isPickup ? { city: cityTextId, lang } : skipToken,
  );

  const currentStore = useSelectedStore(stores);

  const cart: ICart = session.get('cart');
  const user = session.get('user') || {};
  const {
    allow_for_delivery: allowForDelivery = false,
    order: {
      delivery_type = nowDeliveryType,
      select_date = '',
      select_time = '',
    } = {},
  } = cart;

  const nearestDateIsNotToday =
    currentStore &&
    (storeHelper.isWillCloseSoon(currentStore) ||
      storeHelper.isAlreadyClosed(currentStore));

  const isNotWorkingNow =
    nearestDateIsNotToday ||
    (currentStore && storeHelper.isStillClosed(currentStore));

  const initialDeliveryStateToTime =
    delivery_type === toTimeDeliveryType || !!isNotWorkingNow;

  const fields = fillFields(
    getFields(isPickup, t, mapTypes, isMapDisabled),
    cart,
    user,
  );

  const [toTime, setToTime] = useState(initialDeliveryStateToTime);
  const [form, setForm] = useState({ valid: false, fields });
  const [deliveryTime, setDeliveryTime] = useState(avg_delivery_time);
  const [selectedDate, setSelectedDate] = useState(select_date);
  const [selectedTime, setSelectedTime] = useState(select_time);
  const { fullAddress, deliveryData, deliveryError } = useAddressDeliveryCheck({
    isPickup,
    toTime,
    selectedTime,
    selectedDate,
    cart,
    cityCoords,
    cityTextId,
  });

  const isMinAmount = !isPickup ? cart.total < order_min : false;

  const setInvalidFields = (fieldsArray: any[], invalid: boolean) => {
    const newFields = form.fields.map((field) => {
      const invalidField = fieldsArray.find((el) => el.name === field.name);
      if (!invalidField) return { ...field };
      return { ...field, invalid };
    });
    setForm({ ...form, fields: newFields });
  };

  const sendFieldValue = async (fieldName: string, value: string) => {
    await cartHelper.sendOrderField(fieldName, value);
  };

  const changeAddress = () => {
    Emitter.emit('FORM_IS_VALID', false);
    const field = formElement.current?.address;
    const value = fullAddress
      ? `${fullAddress.city}, ${fullAddress.street}, ${fullAddress.house}`
      : field.value;

    setInvalidFields([field], false);
    sendFieldValue(field.name, value);
  };
  useEffect(() => {
    scrollToExecution(thisBlock.current, 100);
  }, []);

  useEffect(() => {
    if (
      (!isMapDisabled && (!fullAddress || !formElement.current?.address)) ||
      (isMapDisabled && !formElement.current?.address)
    )
      return;

    changeAddress();
  }, [fullAddress, formElement.current?.address]);

  useEffect(() => {
    if (deliveryData) {
      setDeliveryTime(deliveryData.avg_delivery_time);
    }
  }, [deliveryData]);

  const userLoginHandler = () => {
    setForm({
      ...form,
      fields: fillFields(
        getFields(isPickup, t, mapTypes, isMapDisabled),
        cart,
        user,
      ),
    });
  };

  // const setInvalidAddress = () => {
  //   const field = formElement.current?.address;
  //   setInvalidFields([field], true);
  // };

  const updateOrderDate = async (date?: Date | string) => {
    const dateValue = date ? formaterDate(date, formatHelper.fullDate) : '';
    try {
      const data: ICart = await api('cart.order_date', { date: dateValue });
      session.set('cart', data);
      Emitter.emit('CART_CHANGE', { notChangeUrl: true });
    } catch (e) {
      throw Error(String(e));
    }
  };

  const onChangeDate = async (
    name: 'select_time' | 'select_date',
    value: string,
  ) => {
    const newFields = form.fields.map((field) => {
      if (field.name !== name) return { ...field };
      return { ...field, value };
    });
    setForm({ ...form, fields: newFields });

    const fullDate =
      name === 'select_time'
        ? parse(`${selectedDate} ${value}`, formatHelper.fullDate, new Date())
        : value;
    if (isValid(fullDate)) {
      await updateOrderDate(fullDate);
    }
    sendFieldValue(name, value);
    if (name === 'select_time') setSelectedTime(value);
    if (name === 'select_date') {
      setSelectedTime('');
      setSelectedDate(value);
    }
  };

  useEffect(() => {
    if (!stores?.length) return;
    // TODO: следующие кейсы должны обрабатываться на предыдущем шаге
    // (в корзине при выборе типа доставки)
    if (isPickup && !currentStore) {
      // Нет магазин(а/ов)
      history.replace(`/${cityTextId}/cart/delivery`);
      return;
    }
    if (isMinAmount || !availableForOrder) {
      // Сумма в корзине меньше чем минимальная сумма доставки
      history.replace(`/${cityTextId}/cart`);
      return;
    }
    if (!allowForDelivery && !isPickup) {
      // Есть товар который доступен только для самовывоза
      history.replace(`/${cityTextId}/cart`);
      return;
    }

    if (sessionStorage) {
      const sessionDeliveryTime = Number(
        sessionStorage.getItem('deliveryTime'),
      );
      setDeliveryTime(sessionDeliveryTime);
    }

    if (nearestDateIsNotToday) {
      const avaliabaleDate = storeHelper.findNearestPickupDate(
        currentStore,
        true,
      );
      if (!selectedDate && avaliabaleDate)
        onChangeDate('select_date', format(avaliabaleDate, formatHelper.day));
      if (selectedDate && avaliabaleDate) {
        const selected = parse(select_date, formatHelper.day, new Date());
        if (selected < avaliabaleDate)
          onChangeDate('select_date', format(avaliabaleDate, formatHelper.day));
      }
    }

    if (formElement.current)
      formElement.current.delivery_type.value = toTime
        ? toTimeDeliveryType
        : nowDeliveryType;

    userEmitter.addListener('User.Login.Event', userLoginHandler);

    return () => {
      userEmitter.removeListener('User.Login.Event', userLoginHandler);
    };
  }, [stores]);

  const handleChangeForm = async (e: CustomEvent) => {
    const {
      target,
      target: { name },
    } = e;

    Emitter.emit('FORM_IS_VALID', false);

    const changeField = form.fields.find((field) => field.name === name);

    if (changeField && 're' in changeField) {
      removeUnwantedCharacters(target, changeField.re);
    }

    if (name === 'select_time' || name === 'select_date') {
      onChangeDate(name, target.value || '');
      return;
    }

    if (name === 'delivery_type' && target.value) {
      setToTime(target.value === toTimeDeliveryType);
      await sendFieldValue(name, target.value);
      if (target.value !== toTimeDeliveryType) updateOrderDate();
    }
    setInvalidFields([target], false);
  };

  const checkForm = (target: any) => {
    const data: any = {};

    form.fields.forEach((field) => {
      const fieldName = field.name as keyof typeof target;
      if (target[fieldName]) {
        data[fieldName] = target[fieldName].value;
      }
      if (
        target[fieldName] &&
        target[fieldName].name === 'phone' &&
        target[fieldName].value
      ) {
        data[fieldName] = target[fieldName].value;
      }
    });

    // При самовывозе и в случае доставки к определнному времени
    // необходимо ввести дату.
    const isDateRequired =
      formElement.current?.delivery_type.value === toTimeDeliveryType;

    let delivery_date = '';

    if (isDateRequired) {
      const selectDate = formElement.current?.select_date.value;
      const selectTime = formElement.current?.select_time
        ? formElement.current.select_time.value
        : '';

      delivery_date = selectTime.length ? `${selectDate} ${selectTime}` : '';

      data.select_date = selectDate;
      data.select_time = selectTime;
    } else {
      data.select_date = '';
      data.select_time = '';
    }
    return { data, delivery_date };
  };

  const checkAddress = () => {
    if (!fullAddress || !fullAddress?.house) {
      onError(t('BlockForm.specify_address'));
      const addressField = formElement.current?.address;
      setInvalidFields([addressField], true);
      scrollToExecution(addressField);
      throw Error();
    }
  };

  const deliveryCheck = () => {
    if (!isMapDisabled && !fullAddress) throw Error();

    if (deliveryError) {
      showDeliveryErrorModal(deliveryError, t, currency, onError);
      if (deliveryError.type === 'not_delivery') {
        const addressField = formElement.current?.address;
        setInvalidFields([addressField], true);
        scrollToExecution(addressField);
      }

      throw Error();
    }

    if (!deliveryData) throw Error();

    if ('entity_text' in deliveryData && deliveryData.entity_text) {
      cartHelper.sendOrderField(
        'organizationContactInformation',
        deliveryData.entity_text,
      );
    } else {
      cartHelper.sendOrderField('organizationContactInformation', null);
    }

    const cartForChange = session.get('cart');
    if (!isMapDisabled && fullAddress) {
      cartForChange.geodata = {
        latitude: fullAddress.latitude,
        longitude: fullAddress.longitude,
      };
    }

    return deliveryData;
  };

  const handleBlurField = ({
    target,
  }: FocusEvent<HTMLFormElement, Element>) => {
    const re = /[^a-zA-Zа-яА-ЯёЁієїЄІЇ\s-]+/gi;
    let { value } = target;
    switch (target.name) {
      case 'select_time':
      case 'select_date':
        return;
      case 'email':
        if (target.value && target.value[0].match(/ /g)) {
          value = target.value.replaceAll(/ /g, '');
        }
        break;
      case 'entrance':
        value = target.value.replace(/[^0-9]/g, '');
        break;
      case 'house':
        if (target.value && target.value[0].match(re))
          value = target.value.replace(re, '');
        break;
      default:
        break;
    }
    if (value) {
      analytics.deliveryFieldsAction(target.name as keyof typeof FieldsType);
    }
    sendFieldValue(target.name, value);
  };

  const handleInvalidFields = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { target, currentTarget } = event;
    scrollToExecution(currentTarget);
    setInvalidFields([target], true);
  };

  if (isPickup && !currentStore) {
    history.replace(`/${cityTextId}/cart/delivery`);
    return null;
  }

  const onSubmitForm = async (event: any) => {
    if (event.preventDefault) event.preventDefault();

    const { target } = event;
    const { data } = checkForm(target);

    const validationScheme = getValidationScheme(isPickup, isMapDisabled);

    try {
      validationScheme.validateSync(data, { abortEarly: false });
    } catch (e: unknown) {
      if (e instanceof ValidationError) {
        const invalidFields = e.inner.reduce((acc, error) => {
          acc.push(target[error.path as keyof typeof target]);
          return acc;
        }, [] as any[]);
        setInvalidFields(invalidFields, true);
        scrollToExecution(target[e.inner[0].path as keyof typeof target]);
        return;
      }
    }

    if (cartHelper.getCutleryMaxQty(cart)) {
      if (!data.cutlery_qty || +data.cutlery_qty < 1) {
        scrollToExecution(
          target['counter-buttons' as keyof typeof target],
          150,
        );
        setInvalidFields([target['cutlery_qty' as keyof typeof target]], true);
        return;
      }
    }

    if (
      (formElement.current?.select_date && !formElement.current?.select_time) ||
      (formElement.current?.select_time && !formElement.current?.select_date)
    ) {
      const element = target.select_time
        ? target.select_time
        : target.select_date;
      scrollToExecution(element);
      setInvalidFields([element], true);
      return;
    }

    if (isPickup && currentStore) {
      if (!isNotWorkingNow || toTime) {
        Emitter.emit('FORM_IS_VALID', {
          form: { ...data, address: storeHelper.getAddress(currentStore) },
        });
        return;
      }
      const openDateTime = storeHelper.findNearestPickupDate(currentStore);
      if (!openDateTime) {
        onError(t('BlockTotal.store_not_work'));
        Emitter.emit('FORM_IS_VALID', false);
        return;
      }
      onError(
        `${t('BlockForm.store_open_at')} ${formaterDate(
          openDateTime,
          formatHelper.time,
        )}`,
      );
      if (target.delivery_type) scrollToExecution(target.delivery_type);
      Emitter.emit('FORM_IS_VALID', false);
    } else {
      try {
        if (!isMapDisabled) checkAddress();
        deliveryCheck();
        Emitter.emit('FORM_IS_VALID', { form: data });
      } catch (e) {
        Emitter.emit('FORM_IS_VALID', false);
      }
    }
  };

  const choosePayMethod = () => {
    if (!formElement.current) return;
    onSubmitForm({ target: formElement.current });
  };

  return (
    <section className="block-delivery block-form" ref={thisBlock}>
      <div className="block-form__form-block">
        {isPickup && <PickupFormTitle store={currentStore} />}
        {!isPickup && <DeliveryFormTitle />}
        <div
          className={`block-form__form-container ${
            isPickup ? 'pickup-form-container' : ''
          }`}
        >
          <form
            id={isPickup ? 'pickup-form' : 'delivery-form'}
            onSubmit={onSubmitForm}
            onInvalid={handleInvalidFields}
            onBlur={handleBlurField}
            ref={formElement}
          >
            {form.fields.map((field) => {
              if (
                !toTime &&
                (field.name === 'select_date' || field.name === 'select_time')
              )
                return null;
              return (
                'Component' in field && (
                  <field.Component
                    {...{
                      field,
                      isPickup,
                      store: currentStore || null,
                      handleChangeForm,
                      deliveryTime: !isPickup ? deliveryTime : null,
                      date: selectedDate,
                      cityName,
                      cityCoords,
                      mapTypeByCountry,
                      mapTypeByCity,
                    }}
                    key={`field-${
                      field.name !== 'delivery_type' ? field.name : field.label
                    }`}
                  />
                )
              );
            })}
            {isPickup ? (
              <input type="hidden" name="store_id" value={currentStore?.id} />
            ) : undefined}
          </form>
        </div>
      </div>
      <BlockTotal
        {...{
          total: cart.total,
          isPickup,
          stores,
          total_for_delivery: cart.total_for_delivery,
          choosePayMethod,
        }}
      />
    </section>
  );
}

export default OrderingForm;
