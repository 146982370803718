import { memo } from 'react';
import { InternalImage } from '@shared/ui/image';
import iconHot from './assets/hot.svg?url';
import iconVegan from './assets/vegan.svg?url';
import styles from './tags.module.scss';
import { ITag, tagIdValues } from '../../model/types';

function Tags({ tags }: { tags?: ITag[] }) {
  if (!tags?.length) return null;

  return (
    <ul className={styles.list}>
      {tags
        .filter(({ visible }) => visible)
        .sort((a, b) => a.tag_id - b.tag_id)
        .map(({ tag_id, title }) => {
          switch (tag_id) {
            case tagIdValues.spicy:
              return (
                <li key={tag_id}>
                  <InternalImage
                    className={styles.img}
                    alt={title}
                    src={iconHot}
                    width={30}
                    height={30}
                  />
                </li>
              );
            case tagIdValues.vegan:
              return (
                <li key={tag_id}>
                  <InternalImage
                    className={styles.img}
                    alt={title}
                    src={iconVegan}
                    width={30}
                    height={30}
                  />
                </li>
              );
            default:
              return null;
          }
        })}
    </ul>
  );
}

export default memo(Tags);
