import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '@shared/ui/popups';
import { Button } from '@shared/ui/button';
import { ModalParagraph } from '@shared/ui/modal-paragraph';

export function UrlShortener() {
  const { t } = useTranslation();
  const [shortUrl, setShortUrl] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const getUrlShortener = () => {
    const key = 'AIzaSyDzEEhlEJ4oaugZzN1jDkhUd_zfjFrQHds';
    const dynamicLinkUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${key}`;

    fetch(dynamicLinkUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        longDynamicLink: `https://sushiwok.page.link/?link=${encodeURIComponent(
          window.location.href,
        )}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const { shortLink = '' } = data;
        setShortUrl(shortLink);
        openModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Popup isOpen={modalIsOpen} onClose={closeModal}>
        <ModalParagraph>{t('UrlShortener.send')}</ModalParagraph>
        {shortUrl}
      </Popup>
      <Button onClick={getUrlShortener}>{t('UrlShortener.send')}</Button>
    </>
  );
}
