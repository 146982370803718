import { BlockContainer } from '@shared/ui/content-containers';
import { Subtitle } from '@shared/ui/titles';
import { useTranslation } from 'react-i18next';
import { Preloader } from '@shared/ui/preloader';
import useUserIsExist from 'frontend/data/user/useUserExist';
import { OrderList } from '../order-list/OrderList';
import { useGetUserOrdersQuery } from '../../api/userApi';
import { IUserOrder } from '../../model/types';

export function LastOrders({
  numberOfLastOrders,
  showReorderModal,
}: {
  numberOfLastOrders: number;
  showReorderModal: (order: IUserOrder) => void;
}) {
  const { t, i18n } = useTranslation();
  const { isUserExist } = useUserIsExist();
  const { data, isFetching, isUninitialized } = useGetUserOrdersQuery(
    {
      lang: i18n.language,
    },
    { skip: !isUserExist },
  );

  const orders = data?.data?.orders;
  const lastOrders = orders ? orders.slice(0, numberOfLastOrders) : [];
  const isLoading = isFetching || isUninitialized;

  return (
    <BlockContainer childrenPosition="start">
      <Subtitle>
        {t('Info.last order')}
        {lastOrders.length ? ` (${lastOrders.length})` : ''}{' '}
      </Subtitle>{' '}
      {isLoading ? (
        <Preloader />
      ) : (
        <OrderList orders={lastOrders} showReorderModal={showReorderModal} />
      )}
    </BlockContainer>
  );
}
