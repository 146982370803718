import { useMediaQuery } from 'react-responsive';

export const useResizeWindow = () => {
  const initSizes = {
    isDesktop: useMediaQuery({ minWidth: 1025 }),
    isLaptop: useMediaQuery({ minWidth: 769, maxWidth: 1024 }),
    isTablet: useMediaQuery({ minWidth: 641, maxWidth: 768 }),
    isMobile: useMediaQuery({ maxWidth: 640 }),
  };

  return initSizes;
};
