import { useYMaps } from '@pbe/react-yandex-maps';
import { IGeoSuggestItem, suggestHelper, UseSugestType } from '../suggest';

export const useYandexSuggest: UseSugestType = ({
  cityName,
  cityCoords,
  searchConfig,
}) => {
  const ymaps = useYMaps(['SuggestView', 'suggest']);

  const { lat, lng } = cityCoords;
  const boundedBy = [
    [lat - 0.25, lng - 0.25],
    [lat + 0.25, lng + 0.25],
  ];

  const suggestRequest = async (req: string) => {
    if (!ymaps || req.length < searchConfig.reqMinLength) return [];

    const res: IGeoSuggestItem[] = await suggestHelper(
      cityName,
      ymaps.suggest,
      req,
      {
        boundedBy,
        results: searchConfig.resultsNumber,
      },
    );

    return res;
  };

  return {
    suggestRequest,
    debounceDep: ymaps,
  };
};
