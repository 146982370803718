const removeCity = (address: string, cityName: string) =>
  address.replace('г. ', '').replace(`${cityName}, `, '');

const concatCurrentCity = (cityName: string, request: string) => {
  if (request.includes(cityName)) {
    return request;
  }
  const newRequest = removeCity(request, cityName);

  return `${cityName}, ${newRequest}`;
};

const removeCountry = (address: string) => {
  const reg = /Россия, |Украина, |Белоруссия, /gi;
  return address.replace(reg, '');
};

const removeCountryFromResults = (results: ymaps.ISuggestResult[]) =>
  results.map((r) => {
    const newValue = removeCountry(r.value);
    return { ...r, value: newValue };
  });

const uniqueConcat = (
  firstArray: ymaps.ISuggestResult[],
  secondArray: ymaps.ISuggestResult[],
  results: number,
) => {
  const filteredSecond = secondArray.filter(
    (s) => !firstArray.some((f) => f.value === s.value),
  );

  const uniqueArray = firstArray.concat(filteredSecond);

  if (uniqueArray.length > results) {
    uniqueArray.length = results;
  }
  return uniqueArray;
};

export const suggestHelper = async (
  cityName: string,
  suggestFunc: (
    request: string,
    options?: ymaps.ISuggestOptions | undefined,
  ) => Promise<ymaps.ISuggestResult[]>,
  request: string,
  options?: ymaps.ISuggestOptions,
) => {
  const reqWithCity = concatCurrentCity(cityName, request);
  const res = await suggestFunc(reqWithCity, options);
  const noCountryRes = removeCountryFromResults(res);
  const resultsNeeded = options?.results || 5;
  if (noCountryRes.length < resultsNeeded) {
    const resWithoutCity = await suggestFunc(request, options);
    removeCountryFromResults(resWithoutCity);
    return uniqueConcat(
      noCountryRes,
      removeCountryFromResults(resWithoutCity),
      resultsNeeded,
    );
  }
  return noCountryRes;
};
