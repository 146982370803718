import { isRound } from 'modules/common/index';
import * as session from 'modules/session';
import WokToppings from './WokToppings';
import WokRepository from './WokRepository';

export default class Constructor {
  static new(products) {
    return new Constructor(WokRepository.new(products));
  }

  constructor(aRepository) {
    this._repository = aRepository;
    this._selectedWok = this._repository.defaultWok;
    this._selectedBase = this._repository.defaultBase;
    this._selectedSauce = this._repository.defaultSauce;
    this._selectedVegetables = this._repository.defaultVegetable;
    this._toppings = new WokToppings();
    this._toppings.setSelectedVegetables(this._selectedVegetables);
    this.initFromQueryObject(session.get('query', {}));
  }

  initFromQueryObject(aQueryObject) {
    const { base_id, sauce_id, toppings } = aQueryObject;
    this.selectBaseWithId(base_id);
    this.selectSauceWithId(sauce_id);

    this._toppings.addToppingsFromQueryString(toppings || '');
  }

  isNotUsable() {
    return this._repository.hasNotWokForConstructor();
  }

  get title() {
    return `${this.selectedBase.title}${
      this.isVegetables() ? ` + ${this.selectedVegetables.title}` : ''
    } + ${this.selectedSauce.title}`;
  }

  get selected() {
    return this._selectedWok;
  }

  get selectedBase() {
    return this._selectedBase;
  }

  get selectedSauce() {
    return this._selectedSauce;
  }

  get selectedVegetables() {
    return this._selectedVegetables;
  }

  get totalPrice() {
    return isRound(this.basePrice + this._toppings.countTotalPrice());
  }

  get basePrice() {
    return isRound(
      this._selectedWok.price +
        (this._selectedSauce.price || 0) +
        (this.selectedBase.price || 0),
    );
  }

  numberOfTopping(aTopping) {
    return this._toppings.numberOfToppingWithId(aTopping.id);
  }

  maxNumberOfTopping(aTopping) {
    return this._toppings.maxNumberOfToppingWithId(aTopping.id);
  }

  selectBaseWithId(anId) {
    this._selectedBase = this._repository.baseWithId(anId);
  }

  selectSauceWithId(anId) {
    this._selectedSauce = this._repository.sauceWithId(anId);
  }

  selectVegetablesWithId(anId) {
    this._selectedVegetables = this._repository.vegetablesWithId(anId);
    this._toppings.setSelectedVegetables(this._selectedVegetables);
  }

  toggleToppingWithId(anId) {
    this._toppings.toggleToppingWithId(anId);
  }

  addOneToppingWithId(anId) {
    this._toppings.addOneToppingWithId(anId);
  }

  deleteAllToppingsWithId(anId) {
    this._toppings.deleteAllToppingsWithId(anId);
  }

  changeNumberOfToppingsWithId(anId, { to }) {
    this._toppings.changeNumberOfToppingsWithId(anId, { to });
  }

  reset() {
    this._toppings.deleteAllToppings();
    this._selectedWok = this._repository.defaultWok;
    this._selectedBase = this._repository.defaultBase;
    this._selectedSauce = this._repository.defaultSauce;
    this._selectedVegetables = this._repository.defaultVegetable;
  }

  collectBases(aFunction) {
    return this._repository.collectBases(aFunction);
  }

  collectSauces(aFunction) {
    return this._repository.collectSauces(aFunction);
  }

  collectVegetables(aFunction) {
    return this._repository.collectVegetables(aFunction);
  }

  collectToppings(aFunction) {
    return this._toppings.collect(aFunction);
  }

  collectToppingsDividedByGroups(aFunction) {
    return Object.entries(this._repository.toppingsDividedByGroups).map(
      aFunction,
    );
  }

  isBaseSelected(base) {
    return this.selectedBase.id === base.id;
  }

  isSauceSelected(sauce) {
    return this.selectedSauce.id === sauce.id;
  }

  isVegSelected(vegetables) {
    return this.selectedVegetables.id === vegetables.id;
  }

  isToppingSelected(topping) {
    return this._toppings.hasToppingWithId(topping.id);
  }

  isVegetables() {
    return this._repository.isVegetables(this.selectedVegetables.id);
  }

  canNotAddNewToppingsFor(aGroupName) {
    return this._toppings.canNotAddNewToppingsFor(aGroupName);
  }

  asQueryObject() {
    return {
      base_id: this.selectedBase.id,
      sauce_id: this.selectedSauce.id,
      ...this._toppings.asQueryObject(),
    };
  }

  asEventPayloadWithQty(aQty) {
    const vegetablesArray = this.isVegetables()
      ? [{ product_id: this.selectedVegetables.id, qty: 1 }]
      : [];
    return {
      id: this.selected.id,
      qty: aQty,
      child: [
        { product_id: this.selectedBase.id, qty: 1 },
        { product_id: this.selectedSauce.id, qty: 1 },
        ...vegetablesArray,
        ...this._toppings.asEventPayload(),
      ],
    };
  }
}
