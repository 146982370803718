import loadable from '@loadable/component';
import { holidayCheck } from '@entities/holyday-decorations';
import { useCheckCountries } from '@entities/countries';
import type { IMenuElement } from '@entities/menu';
import type { IHeaderMenu } from '@entities/header-menu';
import { Desktop } from './desktop/Desktop';
import { Mobile } from './mobile/Mobile';
import MobileNavigation from './mobile/mobileNavigation/MobileNavigation';
import { Logo } from './logo/Logo';
import { MobileSidebarOpener } from './mobile-sidebar-opener/MobileSidebarOpener';

const DecorationSpan = loadable(
  () =>
    import('@entities/holyday-decorations').then(
      ({ DecorationSpan: Decoration }) => Decoration,
    ),
  { ssr: false },
);

interface IHeaderProps {
  menuList: IMenuElement[];
  countryId: number;
  isCheckCountries: ReturnType<typeof useCheckCountries>;
  cityName: string;
  cityPhone: string;
  mainDomain: string;
  showSelectCity: () => void;
  headerMenu: IHeaderMenu[];
  cityAvailableLangs?: string[];
}

export function Header({
  menuList,
  countryId,
  isCheckCountries,
  cityName,
  cityPhone,
  mainDomain,
  showSelectCity,
  headerMenu,
  cityAvailableLangs,
}: IHeaderProps) {
  const holydayNameForLogo = holidayCheck({
    countryId,
    type: 'logo',
  });
  const logo = (
    <Logo
      holydayName={holydayNameForLogo}
      isCheckCountries={isCheckCountries}
    />
  );

  const mobileHeaderMenu = headerMenu.filter(({ mobile }) => mobile);
  const desktopHeaderMenu = headerMenu.filter(({ site }) => site);

  const mobileSidebarOpener = (
    <MobileSidebarOpener
      mainDomain={mainDomain}
      menuList={menuList}
      cityName={cityName}
      cityPhone={cityPhone}
      isCheckCountries={isCheckCountries}
      showSelectCity={showSelectCity}
      headerMenu={mobileHeaderMenu}
      cityAvailableLangs={cityAvailableLangs}
    />
  );
  const holydayNameForHeader = holidayCheck({
    countryId,
    type: 'header',
  });
  const decoration = {
    left: holydayNameForHeader && (
      <DecorationSpan position="left" holidayName={holydayNameForHeader} />
    ),
    right: holydayNameForHeader && (
      <DecorationSpan position="right" holidayName={holydayNameForHeader} />
    ),
  };
  return (
    <header id="main-header" className="sticky top-0 z-10 bg-white-translucent">
      <div className="lg:hidden">
        <Mobile
          mobileSidebarOpener={mobileSidebarOpener}
          decoration={decoration}
          logo={logo}
        />
        <MobileNavigation menuList={menuList} />
      </div>

      <div className="hidden h-(--header-height-mobile) justify-between gap-1 lg:flex xl:h-(--header-height-desktop)">
        <Desktop
          showSelectCity={showSelectCity}
          decoration={decoration}
          logo={logo}
          cityPhone={cityPhone}
          headerMenu={desktopHeaderMenu}
          cityAvailableLangs={cityAvailableLangs}
        />
      </div>
    </header>
  );
}
