import { useModal } from '@ebay/nice-modal-react';
import { useCheckCountries } from '@entities/countries';
import { IHeaderMenu } from '@entities/header-menu';
import { IMenuElement } from '@entities/menu';
import { modalIds } from '@features/modals';

export const useMobileSidebarModal = () => {
  const modal = useModal(modalIds.mobileSidebarModalId);

  return {
    ...modal,
    show: (args: {
      mainDomain: string;
      cityName: string;
      cityPhone: string;
      isCheckCountries: ReturnType<typeof useCheckCountries>;
      menuList: IMenuElement[];
      showSelectCity: () => void;
      headerMenu: IHeaderMenu[];
      cityAvailableLangs?: string[];
    }) => modal.show(args),
  };
};
