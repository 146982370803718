export interface IBlockContainerProps {
  children: React.ReactNode;
  childrenPosition?: 'start' | 'end' | 'stretch';
}
export function BlockContainer({
  children,
  childrenPosition = 'stretch',
}: IBlockContainerProps) {
  const itemsPositionVariants = {
    start: 'items-start',
    end: 'items-end',
    stretch: 'items-stretch',
  };
  const rootStyles = `flex flex-col ${itemsPositionVariants[childrenPosition]} gap-5 lg:gap-7`;

  return <article className={rootStyles}>{children}</article>;
}
