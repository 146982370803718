const getOptions = (max_count) => {
  const options = [];
  for (let i = 1; i <= max_count; i += 1) {
    options.push(i);
  }
  return options;
};

const getDisabledStatus = (type, total, typesOfToppings, product) => {
  let disabled;
  const matchingType = typesOfToppings.find((item) => item.name === type);

  if (matchingType && matchingType.remainingTypeTopping <= 0) {
    disabled = true;
  } else {
    disabled = false;
  }
  if (+total === +product.max_toppings) {
    disabled = true;
  }
  return disabled;
};

const getUniqueTypeToppings = (product) => {
  const typesOfToppings = {};

  product.product_toppings.forEach((topping) => {
    const { name, max_toppings } = topping.type;

    if (
      !typesOfToppings[name] ||
      max_toppings > typesOfToppings[name].maxTypeTopping
    ) {
      typesOfToppings[name] = {
        name,
        maxTypeTopping: max_toppings,
        totalTypeTopping: 0,
        remainingTypeTopping: max_toppings,
      };
    }
  });

  return Object.values(typesOfToppings);
};

const getChild = (toppings) =>
  toppings
    .filter(({ cart_count }) => !!cart_count)
    .map(({ product, cart_count }) => ({
      product_id: product.id,
      qty: +cart_count,
    }));

const disableOptions = (
  options,
  remaining,
  selectedType,
  typesOfToppings,
  currentCount,
) => {
  const matchingType = typesOfToppings.find(
    (type) => type.name === selectedType,
  );
  if (!matchingType) return;

  const remainder = Math.min(
    matchingType.remainingTypeTopping + currentCount,
    remaining + currentCount,
  );

  Array.prototype.forEach.call(options, (option) => {
    option.disabled = true;
  });
  for (let i = 0; i < remainder; i += 1) {
    if (options[i]) options[i].disabled = false;
  }
};

const getTotalToppings = (selectedType, toppings, typesOfToppings, product) => {
  let total = 0;

  const updatedTypesOfToppings = typesOfToppings.map((type) => ({
    ...type,
    totalTypeTopping: 0,
  }));

  const matchingType = updatedTypesOfToppings.find(
    (type) => type.name === selectedType,
  );

  toppings.forEach((topping) => {
    total += +topping.cart_count;

    if (matchingType && selectedType === topping.type) {
      matchingType.totalTypeTopping += +topping.cart_count;
      matchingType.remainingTypeTopping =
        matchingType.maxTypeTopping - matchingType.totalTypeTopping;
    }
  });

  const remaining = product.max_toppings - total;

  return {
    total,
    remaining,
    updatedTypesOfToppings,
  };
};

export {
  getOptions,
  getDisabledStatus,
  getUniqueTypeToppings,
  getChild,
  getTotalToppings,
  disableOptions,
};
