import { IArticle } from '@entities/publication';
import { FetchLoaderType } from '@shared/lib/routing';
import { ISeoPage } from '@widgets/seoPage';

type Params = {
  city: string;
  article: string;
};
export const oneArticlePageLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string },
) => {
  const { city, article } = params;
  const [seoPageResponse, oneArticlePageResponse] = await Promise.all([
    fetch<ISeoPage>({
      url: '/seo/page',
      params: { url: `/articles/${article}`, city, lang },
    }),
    fetch<{ article: IArticle[] }>({
      url: '/articles/get',
      params: { text_id: article, city, lang },
    }),
  ]);
  return {
    seoPageData: seoPageResponse.data,
    oneArticlePageData: oneArticlePageResponse.data,
  };
};
