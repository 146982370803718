const config = {
  defaultCookingTime: 15,
  defaultPickupBeforeClose: 0,
};

/**
 * Convert time string in format 'HH:MM' to minutes amount.
 *
 * @param {string} timeString - Time string in format 'HH:MM'.
 */
export const getMinutesAmount = (
  timeString: string,
  defaultType: keyof typeof config,
) => {
  const [hours, minutes] = timeString.split(':');
  const minutesAmount = +hours * 60 + +minutes;
  return minutesAmount || config[defaultType];
};
