import './CardWokMobile.scss';
import WokRepository from 'frontend/Components/Products/CardWok/wok-constructor/WokRepository';
import bind from 'autobind-decorator';
import { Component } from 'react';
import * as session from 'modules/session';
import { WokConstructorCard } from '@features/wok-constructor';
import WokConstructor from './wok-constructor/WokConstructor';
import Emitter from '../../NewCartOrder/Emitters';
import Card from '../Card/Card';
import addStocks from '../CardStock/addStocks';

class CardWokMobile extends Component {
  constructor(props) {
    super(props);
    this.repository = WokRepository.new(props.products, props.group);
    this.state = { constructorIsOpen: false };
  }

  componentDidMount() {
    Emitter.addListener('CART_CHANGE', this.reRender);
  }

  componentWillUnmount() {
    Emitter.removeListener('CART_CHANGE', this.reRender);
  }

  @bind reRender(callback) {
    this.forceUpdate(callback);
  }

  @bind openConstructor(event) {
    event.preventDefault();
    this.setState({ constructorIsOpen: true });
  }

  render() {
    const { constructorIsOpen } = this.state;
    const { products, group } = this.props;
    const cartItems = session.cartItems();

    const renderedWoks = this.repository.collectWoks((wok) => (
      <Card
        key={wok.id}
        product={wok}
        view_type="grid"
        position="catalog"
        cart_item={cartItems.find((item) => item.id === wok.id)}
      />
    ));
    const woksWithStocks = addStocks({
      CardComponents: renderedWoks,
      itemsGrid: 1,
      menuId: 709,
    });
    return constructorIsOpen ? (
      <WokConstructor products={products} group={group} mobile />
    ) : (
      <div className="card-wok__products">
        {woksWithStocks}
        {this.repository.hasWokForConstructor() && (
          <div className="card-wrapper--grid">
            <WokConstructorCard action={this.openConstructor} />
          </div>
        )}
        <div className="card-wrapper--grid card-wrapper--grid--empty" />
        <div className="card-wrapper--grid card-wrapper--grid--empty" />
        <div className="card-wrapper--grid card-wrapper--grid--empty" />
        <div className="card-wrapper--grid card-wrapper--grid--empty" />
      </div>
    );
  }
}

export default CardWokMobile;
