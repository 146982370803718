import { SmartLink } from '@shared/lib/routing';
import styles from './promotionsLink.module.scss';
import Discount from './assets/discount.svg';

function PromotionsLink() {
  return (
    <SmartLink className={styles.root} href="/akcii">
      <Discount width={30} height={30} className={styles.svg} />
    </SmartLink>
  );
}

export { PromotionsLink };
