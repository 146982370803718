import { SubscribeModalButton } from './subscribe-modal-button';
import { TimeAndPhoneInfo } from './time-and-phone-info';
import { FooterLinks, IFooterLink } from './footer-links';
import { ISocialLinks, SocialLinks } from './social-links';
import { Copyright } from './copyright';
import styles from './footer.module.scss';
import { UaPayment, ByPayment } from './payment';

interface IFooter {
  cityPhone: string;
  cityTextId: string;
  isCallBack: boolean;
  emailgate: string;
  footerLinks: IFooterLink[];
  socialLinks: ISocialLinks;
  deliveryFrom: string;
  deliveryTo: string;
  worktimeFrom: string;
  worktimeTo: string;
  isUkraine: boolean;
  isBelarus: boolean;
  isArmenia: boolean;
}
export function Footer({
  footerLinks,
  socialLinks,
  isUkraine,
  isBelarus,
  isArmenia,
  cityTextId,
  ...timeAndPhoneInfoProps
}: IFooter) {
  return (
    <footer
      itemScope
      itemType="http://schema.org/WPFooter"
      className={styles.root}
    >
      <FooterLinks links={footerLinks} />
      <div className={styles.socialsAndTimeWrapper}>
        <SocialLinks links={socialLinks} />
        <TimeAndPhoneInfo {...timeAndPhoneInfoProps} />
      </div>
      {isUkraine && <UaPayment cityTextId={cityTextId} />}
      {isBelarus && <ByPayment cityTextId={cityTextId} />}
      <div className={styles.additioinal}>
        {!isUkraine && <SubscribeModalButton />}
        <Copyright isArmenia={isArmenia} isUkraine={isUkraine} />
      </div>
    </footer>
  );
}
