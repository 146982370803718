import { Component } from 'react';
import { useTranslation } from 'react-i18next';
import wokMobile3Image from 'assets/images/wokMobile/3.png';
import wokMobile4Image from 'assets/images/wokMobile/4.png';
import DynamicImageWokBox from 'frontend/Components/DynamicImageWokBox';
import { useCheckCountries } from '@entities/countries';
import { clsx } from 'clsx';
import { InternalImage } from '@shared/ui/image';
import Str from './Str';
import wokConstructorMobileImage from '../assets/wokMobile/wokConstructorMobile.png';
import uaWokConstructorImage from '../assets/wokMobile/uaWokConstructorMobile.png';
import NoImgRadioButton from './NoImgRadioButton';
import WokToppingOption from './ToppingOptionButton';
import Slider from './Slider';
import DynamicImageWokMobile from '../DynamicImageWokMobile';

function Bases({ children, selectedBase, t }) {
  return (
    <div className="card-wok-mobile__item">
      <div className="card-wok-mobile__title">
        <h2>{t('CardWok.choose_base')}</h2>
      </div>
      <DynamicImageWokBox
        urlChunk={selectedBase.text_id}
        alt={t('CardWok.wok_ingredients')}
        style={{ margin: '0 auto' }}
      />
      <div className="card-wok-mobile__list-wrapper">
        <ul className="card-wok-mobile__list">{children[0]}</ul>
      </div>
      <div className="card-wok-mobile__vegetables-container">
        <InternalImage
          src={wokMobile3Image}
          alt={t('CardWok.vegetables')}
          width={321}
          height={130}
          isCenter
        />
        <h4>
          <span>+</span> {t('CardWok.vegetables')}
        </h4>
        <p>{t('CardWok.ingredients')}</p>
        <div className="card-wok-mobile__list-wrapper center">
          <ul className="card-wok-mobile__list">{children[1]}</ul>
        </div>
      </div>
    </div>
  );
}

function BaseRadioButton({ base, selected, onChange, isUkraine }) {
  const id = `${base.id}-mobile`;
  return (
    <li className="card-wok__option" key={id}>
      <div className="card-wok__input-wrapper">
        <input
          id={id}
          name={base.type}
          type="radio"
          checked={selected}
          // value={selected}
          // defaultChecked={selected}
          onChange={() => {
            onChange(base.id);
          }}
          className={clsx('sw-radio', 'card-wok__radio', { ua: isUkraine })}
        />
        <label htmlFor={id} className="card-wok__label">
          {Str.new(base.title)
            .trimLeft('Лапша ')
            .upperCaseFirstLetter()
            .toString()}
        </label>
      </div>
    </li>
  );
}
function Sauces({ children, t }) {
  return (
    <div className="card-wok__item-wrapper">
      <div className="card-wok__item">
        <div className="card-wok-mobile__title">
          <h2>{t('CardWok.chooseSauce')}</h2>
        </div>
        <InternalImage
          src={wokMobile4Image}
          width={274}
          height={111}
          alt={t('CardWok.spice')}
          isCenter
        />
        <ul className="card-wok-mobile__list center">{children}</ul>
      </div>
    </div>
  );
}

function ToppingsAdditionals({ type, children, t }) {
  return (
    <div
      className="card-wok__item-wrapper additional"
      key={`topping-${type}-mobile`}
    >
      <div className="card-wok__item">
        <p className="card-wok__name">{t(`CardWok.${[type]}`)}</p>
        <DynamicImageWokMobile
          urlChunk={type}
          alt={t('CardWok.wok_topping')}
          style={{ margin: '0 auto' }}
        />
        <ul className="card-wok__list">{children}</ul>
      </div>
    </div>
  );
}
class WokConstructorMobile extends Component {
  render() {
    const ToppingsCheckboxes = ({
      constructor,
      onChangeCheckboxHandler,
      onSelectHandler,
      currency,
      t,
    }) =>
      constructor.collectToppingsDividedByGroups(
        ([groupName, toppingsFromGroup]) => (
          <ToppingsAdditionals
            type={groupName}
            key={`topping-${groupName}-mobile`}
            t={t}
          >
            {toppingsFromGroup.map((topping) => (
              <WokToppingOption
                toppingSelected={constructor.isToppingSelected(topping)}
                disabled={constructor.canNotAddNewToppingsFor(groupName)}
                name={`toppings-${groupName}-${topping.id}`}
                onChangeCheckboxHandler={onChangeCheckboxHandler}
                topping={topping}
                maxAvailableCount={constructor.maxNumberOfTopping(topping)}
                onSelectHandler={onSelectHandler}
                currentCount={constructor.numberOfTopping(topping)}
                currency={currency}
                key={`toppings-${groupName}-${topping.id}-mobile`}
                mobile
              />
            ))}
          </ToppingsAdditionals>
        ),
      );

    const {
      currency,
      constructor,
      onSelectBase,
      onSelectSauce,
      onChangeNumberOfToppings,
      onSelectVegetables,
      onToggleTopping,
      onAddToCart,
      onResetWok,
      t,
      isUkraine,
    } = this.props;
    const wokMobileImage = isUkraine
      ? uaWokConstructorImage
      : wokConstructorMobileImage;
    return (
      <div className="constructor-widget__mobile-ver">
        <div className="mobile-header" />
        <Slider
          totalPrice={constructor.totalPrice}
          currency={currency}
          onAddWokToCartClick={onAddToCart}
          onResetWokClick={onResetWok}
        >
          <Bases selectedBase={constructor.selectedBase} t={t}>
            {constructor.collectBases((base) => (
              <BaseRadioButton
                base={base}
                isUkraine={isUkraine}
                onChange={onSelectBase}
                selected={constructor.isBaseSelected(base)}
                key={`${base.id}-mobile`}
              />
            ))}
            {constructor.collectVegetables
              ? constructor.collectVegetables((vegetables, index, arr) => {
                  if (arr.length < 2) return;
                  return (
                    <NoImgRadioButton
                      item={vegetables}
                      selected={constructor.isVegSelected(vegetables)}
                      onChange={onSelectVegetables}
                      key={`${vegetables.id}-mobile`}
                      mobile
                    />
                  );
                })
              : undefined}
          </Bases>
          <Sauces t={t}>
            {constructor.collectSauces((sauce) => (
              <NoImgRadioButton
                item={sauce}
                selected={constructor.isSauceSelected(sauce)}
                onChange={onSelectSauce}
                key={`${sauce.id}-mobile`}
                mobile
              />
            ))}
          </Sauces>
          {ToppingsCheckboxes({
            constructor,
            currency,
            onSelectHandler: onChangeNumberOfToppings,
            onChangeCheckboxHandler: onToggleTopping,
            t,
          })}
          <div className="card-wok__item-wrapper">
            <div className="card-wok__item">
              <p className="card-wok__name">{t('CardWok.ready_wok')}</p>
              <InternalImage
                src={wokMobileImage}
                alt={t('CardWok.ready_wok')}
                width={208}
                height={181}
                isCenter
              />
              <ul className="card-wok-mobile__list done">
                <li className="card-wok-mobile__list__option">
                  <span>{constructor.title}</span>
                  <span>
                    {constructor.basePrice} {currency}
                  </span>
                </li>
                {constructor.collectToppings((topping) => (
                  <li
                    className="card-wok-mobile__list__option"
                    key={`list-${topping.id}-mobile`}
                  >
                    <span>{topping.title}</span>
                    <span>
                      {constructor.numberOfTopping(topping)} x {topping.price}{' '}
                      {currency}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

function EnrichedWokConstructorMobile(props) {
  const { t } = useTranslation();
  const { isUkraine } = useCheckCountries();
  return <WokConstructorMobile t={t} isUkraine={isUkraine} {...props} />;
}

export default EnrichedWokConstructorMobile;
