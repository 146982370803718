import { Component } from 'react';
import bind from 'autobind-decorator';
import { pushQuery } from '@shared/lib/routing';
import animateProduct from 'frontend/helpers/product-flight';
import Constructor from 'frontend/Components/Products/CardWok/wok-constructor/Constructor';
// import { eventBus } from 'modules/event-emitters';
import { withCurrency } from '@entities/countries';
import WokConstructorDesktop from './WokConstructorDesktop';
import WokConstructorMobile from './WokConstructorMobile';
import Emitter from '../../../NewCartOrder/Emitters/index';

class WokConstructor extends Component {
  constructor(props) {
    super(props);
    const { products, group } = props;
    this.constructor = Constructor.new(products, group);
  }

  @bind selectBase(baseId) {
    this.constructor.selectBaseWithId(baseId);
    this.reRender();
  }

  @bind selectSauce(souceId) {
    this.constructor.selectSauceWithId(souceId);
    this.reRender();
  }

  @bind selectVegetables(vegId) {
    this.constructor.selectVegetablesWithId(vegId);
    this.reRender();
  }

  @bind changeNumberOfToppings({ toppingId, value }) {
    this.constructor.changeNumberOfToppingsWithId(toppingId, {
      to: Number(value),
    });
    this.reRender();
  }

  @bind toggleTopping(toppingId) {
    this.constructor.toggleToppingWithId(toppingId);
    this.reRender();
  }

  @bind addToCart(event) {
    // eventBus.emitEvent('product_to_cart', [
    //   {
    //     from: 'wok_constructor',
    //   },
    // ]);

    event.stopPropagation();
    event.preventDefault();
    animateProduct(event);

    Emitter.emit(
      'CART_CHANGE_QUANTITY',
      this.constructor.asEventPayloadWithQty(1),
    );
    this.resetWok();
  }

  @bind resetWok() {
    this.constructor.reset();
    this.reRender();
  }

  @bind reRender() {
    this.forceUpdate();
    pushQuery(this.constructor.asQueryObject());
  }

  render() {
    if (this.constructor.isNotUsable()) {
      return null;
    }
    const { mobile, currency } = this.props;
    return mobile ? (
      <WokConstructorMobile
        currency={currency}
        constructor={this.constructor}
        onSelectBase={this.selectBase}
        onSelectSauce={this.selectSauce}
        onSelectVegetables={this.selectVegetables}
        onChangeNumberOfToppings={this.changeNumberOfToppings}
        onToggleTopping={this.toggleTopping}
        onAddToCart={this.addToCart}
        onResetWok={this.resetWok}
      />
    ) : (
      <WokConstructorDesktop
        currency={currency}
        constructor={this.constructor}
        onSelectBase={this.selectBase}
        onSelectSauce={this.selectSauce}
        onSelectVegetables={this.selectVegetables}
        onChangeNumberOfToppings={this.changeNumberOfToppings}
        onToggleTopping={this.toggleTopping}
        onAddToCart={this.addToCart}
      />
    );
  }
}

export default withCurrency(WokConstructor);
