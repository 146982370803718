import './SubscribeForm.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import Modal from 'react-modal';
import validators from 'modules/form-validators';
import ResponseError from 'modules/response/error';
import api from 'modules/helpers/api';
import { Button } from '@shared/ui/button';
import { withTranslation } from 'react-i18next';
import { analytics } from 'frontend/analytics/analytics';
import { withBreakpoints } from 'frontend/hocs';

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '500px',
    top: '10%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '30px 60px 36px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
  },
};

const mobileStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    top: '15%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: '0 auto 20px',
    maxWidth: '520px',
    overflow: 'visible',
    padding: '40px',
    minWidth: '320px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
  },
};

class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sended: false,
      animated: false,
      modalIsOpen: props.opened,
      blockView: props.blockView,
      form: { email: '' },
      formSubmitted: false,
      formValidators: {
        email: 'email',
      },
      invalidFields: {},
    };
  }

  componentDidMount() {
    const { blockView } = this.state;
    if (!blockView) Modal.setAppElement('body');
  }

  componentWillReceiveProps(props) {
    const { blockView } = this.state;
    if (!blockView) {
      this.setState({
        modalIsOpen: props.opened,
      });
    }
  }

  @autobind
  formChangeHandler({ target }) {
    const { form } = this.state;

    this.setState({
      form: Object.assign(form, { [target.name]: target.value }),
    });
  }

  @autobind
  submitForm(e) {
    e.preventDefault();

    const {
      form: { email },
      invalidFields,
      blockView,
    } = this.state;

    if (Object.keys(invalidFields).length > 0) {
      this.setState({
        formSubmitted: true,
      });
    } else {
      api('subscribe.email', { email })
        .then(() => {
          analytics.mailingAction();
        })
        .catch((response) => {
          const error = new ResponseError(response);
          console.log(error.getMessage());
          if (!blockView) this.closeModal();
        });
    }
  }

  @autobind
  animate({ target: { type, value } = {} }) {
    if ((type === 'email' && !value) || type !== 'email') {
      this.setState({
        animated: true,
      });
    }
    setTimeout(() => this.setState({ animated: false }), 600);
  }

  @autobind
  closeModal() {
    this.setState({
      modalIsOpen: false,
      sended: false,
      formSubmitted: false,
      invalidFields: {},
    });
    this.props.onClose();
  }

  validMessage(name) {
    const { formSubmitted, invalidFields } = this.state;

    return formSubmitted && invalidFields[name];
  }

  @autobind
  renderSended() {
    const { t } = this.props;
    return (
      <div className="form__success">
        <div className="form__icon form__icon--success">
          {t('SubscribeModal.data_sent')}
        </div>
        <div className="form__subtitle form__subtitle--success">
          {t('SubscribeModal.thanks')}!
        </div>
        <p className="form__text form__text--success">
          {t('SubscribeModal.subscription_success')}
        </p>
      </div>
    );
  }

  @autobind
  renderModal() {
    const { form, formValidators, invalidFields } = this.state;
    const { t } = this.props;

    validators.validate(form, formValidators, invalidFields);

    return (
      <div className="subscribe-modal modal">
        <form className="form" onSubmit={(e) => this.submitForm(e)}>
          <div className="form__title">{t('SubscribeModal.Subscribe')}</div>
          <div
            className={`input-container ${
              this.validMessage('email') ? 'error' : undefined
            }`}
          >
            {this.validMessage('email') ? (
              <label>{t('SubscribeModal.Invalid email')}</label>
            ) : (
              <label htmlFor="changeFrom">
                {t('SubscribeModal.Input your Email')}
              </label>
            )}

            <input
              type="email"
              name="email"
              defaultValue={form.email}
              onChange={this.formChangeHandler}
            />
          </div>

          <div className="subscribe-modal__actions">
            <Button className="sw-button" onClick={this.submitForm}>
              {t('SubscribeModal.Send')}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  @autobind
  renderSubscribeBlock() {
    const { form, formValidators, invalidFields, animated, sended } =
      this.state;
    const { t } = this.props;
    validators.validate(form, formValidators, invalidFields);

    return (
      <div className="subscribe-block">
        <form
          className={sended ? 'form animated' : 'form'}
          onSubmit={(e) => this.submitForm(e)}
        >
          <div className="form__title success">
            <p>{t('SubscribeModal.thanks')}!</p>
            <p>{t('SubscribeModal.send_letter')}.</p>
            <p>{t('SubscribeModal.check_email')}!</p>
          </div>
          <div className="form__title">
            {t('SubscribeModal.Subscribe_text')}
          </div>
          <div
            className={`input-container ${
              this.validMessage('email') ? 'error' : ''
            }`}
          >
            <div
              className={
                animated ? 'subscribe-envelope animated' : 'subscribe-envelope'
              }
            />
            <input
              type="email"
              name="email"
              placeholder={t('SubscribeModal.Input your Email')}
              defaultValue={form.email}
              onChange={this.formChangeHandler}
              onFocus={(e) => this.animate(e)}
            />
            <div className="subscribe-block__actions">
              <button
                type="button"
                className="sw-button"
                onClick={(e) => {
                  this.animate({});
                  this.submitForm(e);
                }}
              >
                {t('SubscribeModal.Do_subscribe')}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { isMobile } = this.props;
    const { blockView } = this.state;
    return !blockView ? (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        style={isMobile ? mobileStyles : customStyles}
        contentLabel="subscribe-modal"
      >
        <button
          onClick={this.closeModal}
          className="close-modal-btn"
          type="button"
        />
        {this.state.sended ? this.renderSended() : this.renderModal()}
      </Modal>
    ) : (
      this.renderSubscribeBlock()
    );
  }
}

export default withTranslation()(withBreakpoints(SubscribeForm));
