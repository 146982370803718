import { mapCloseAttribute } from '@shared/third-party-libs/commonMap';
import { CloseButton } from '@shared/ui/close-button';

import styles from './balloonLayout.module.scss';

export function BallonLayout({ children }: { children: JSX.Element | string }) {
  return (
    <div id="popover" className={styles.balloonLayout}>
      {children}
      <CloseButton {...mapCloseAttribute} />
      <div className={styles.balloonTail} />
    </div>
  );
}
