import { Fragment, Component } from 'react';
import { analytics } from 'frontend/analytics/analytics';
import productHelper from 'src/modules/helpers/product-helper';
import * as session from 'src/modules/session';
import Emitter from 'src/frontend/Components/NewCartOrder/Emitters';

function sortItems(items) {
  return items.sort((a, b) => Number(a.product_id) - Number(b.product_id));
}
function generateId(item) {
  let id = item.product_id;
  const toppings = [];
  if (item.child && item.child.length) {
    item.child = sortItems(item.child);
    item.child.forEach((_item) => {
      toppings.push(`${_item.product_id}:${_item.qty}`);
    });
    id += `#${toppings.join('#')}`;
  }
  return id;
}

class ChangeQty extends Component {
  constructor(props) {
    super(props);
    this.buyAvailable = true;
    this.qty = this.getQty();
  }

  componentDidMount() {
    Emitter.addListener('MODAL_CLOSE', this.onCloseModal);
    Emitter.addListener('CART_CHANGE', this.onCartChange);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.product.id !== nextProps.product.id) {
      this.qty = 0;
      this.update = true;
    } else {
      const topping = productHelper.getCartProduct(+nextProps.product.id);
      if (topping) {
        this.update = true;
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      product: { id },
      child,
    } = this.props;
    const {
      product: { id: nextId },
      child: nextChild,
    } = nextProps;
    return id !== nextId || child !== nextChild;
  }

  componentWillUnmount() {
    Emitter.removeListener('MODAL_CLOSE', this.onCloseModal);
    Emitter.removeListener('CART_CHANGE', this.onCartChange);
  }

  onCloseModal = (params = {}) => {
    if (params.modal === 'NeedPromocodeModal') {
      this.buyAvailable = true;
    }
  };

  onCartChange = () => {
    this.buyAvailable = true;
    const newQty = this.getQty();
    if (this.qty !== newQty || (newQty === 0 && this.update)) {
      this.qty = newQty;
      this.forceUpdate();
    }
  };

  getQty() {
    const { qty } = this.getCartItem();
    const {
      product: { cart_count, gift_cart_min_price },
    } = this.props;

    if (gift_cart_min_price) {
      return cart_count;
    }
    return qty || cart_count || 0;
  }

  getCartItem() {
    const { items = [] } = session.get('cart');
    const { product, child = [] } = this.props;
    const compositeId = generateId({ product_id: product.id, child });
    return items.find((item) => item.id === compositeId) || {};
  }

  onClickMinus = (e, qty = 1) => {
    e.stopPropagation();
    e.preventDefault();
    this.changeQty(-qty);
  };

  onClickPlus = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { position = '' } = this.props;
    if (position) {
      analytics.addProductAction(position);
    }
    this.changeQty(1);
  };

  changeQty(qtyDelta) {
    if (this.buyAvailable) {
      const {
        product,
        product: { id },
        changeQtyCallback,
      } = this.props;
      const child = this.props.child || this.getCartItem().child || [];

      productHelper.storeCartProduct(product);
      Emitter.emit('CART_CHANGE_QUANTITY', { id, qty: qtyDelta, child });
      if (typeof changeQtyCallback === 'function')
        changeQtyCallback({ id, qty: qtyDelta, child });
      this.buyAvailable = false;
    }
  }

  render() {
    const qty = this.getQty();
    return (
      <>
        {this.props.render({
          qty,
          add: this.onClickPlus,
          subtract: this.onClickMinus,
          remove: (e) => {
            this.onClickMinus(e, qty);
          },
        })}
      </>
    );
  }
}

export default ChangeQty;
