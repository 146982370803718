import { useCurrency } from 'src/entities/countries';
import styles from './complectationPaid.module.scss';
import useDebouncer from '../../lib/useDebouncer';
import ComplectationItem, {
  ICompItemData,
} from '../complectation-item/ComplectationItem';

export interface ICompPaidItem extends ICompItemData {
  price: number;
}

interface IComplectationPaid {
  items: ICompPaidItem[];
  isExact: boolean;
  onError: (text: string) => void;
}

function SummPrice({ qty, price }: { qty: number; price: number }) {
  const currency = useCurrency();
  const multiplier = qty || 1;
  return (
    <div className={styles.summContainer}>
      {price * multiplier}&nbsp;{currency}
    </div>
  );
}

function ComplectationPaid({ items, isExact, onError }: IComplectationPaid) {
  const { toSend, addToSend } = useDebouncer(onError);

  const onClick = async (id: number, qty: number) => {
    addToSend(id, qty);
  };

  return (
    <section className={styles.container}>
      {items.map(({ product_id, qty, price, ...rest }) => {
        const currentToSend =
          toSend.find(({ product_id: id }) => id === product_id)?.qty ?? 0;
        const totalQty = currentToSend + qty;
        return (
          <ComplectationItem
            key={product_id}
            isExact={isExact}
            itemData={{ product_id, qty: totalQty, ...rest }}
            summPrice={<SummPrice price={price} qty={totalQty} />}
            moreAvaliable
            setQty={onClick}
          />
        );
      })}
    </section>
  );
}

export default ComplectationPaid;
