import { useEffect, useCallback, memo } from 'react';
import { useGetCitiesQuery } from '@entities/cities';
import { useTranslation } from 'react-i18next';
import { useMemorizer } from '@shared/lib/memorizer';
import { useConfirmCityModal } from './handler';
import type { ConfirmCityModalArgs } from './types';

export const ConfirmCity = memo(
  ({
    showSelectCity,
    countryId,
    countryName,
    cityName,
    cityTextId,
  }: ConfirmCityModalArgs & {
    showSelectCity: () => void;
    countryId: number;
  }) => {
    const { i18n } = useTranslation();
    const { data: citiesData, isSuccess } = useGetCitiesQuery({
      lang: i18n.language,
      country_id: countryId,
    });
    const defaultCity = useMemorizer('default-city');
    const isDefaultCity = defaultCity.get();
    const { show } = useConfirmCityModal();

    const showConfirmModal = useCallback(() => {
      show({ countryName, cityName, cityTextId }).then(showSelectCity);
    }, [cityName, cityTextId, countryName, show, showSelectCity]);

    useEffect(() => {
      if (!isDefaultCity) return;
      if (!isSuccess) return;
      const isSingleCity = citiesData.data.city.length === 1;
      if (isSingleCity) return;

      showConfirmModal();
    }, [
      citiesData?.data.city.length,
      isDefaultCity,
      isSuccess,
      showConfirmModal,
    ]);

    return null;
  },
);
