import { useEffect, useState } from 'react';
import { useAppSelector } from '@shared/lib/store';
import { complectationSliceSelectors } from '@entities/complectation/model/slice';
import { isPaidSelected } from '@entities/complectation/lib';

const complectationConfirm = 'complectationConfirm';

export const useComplectationConfirm = () => {
  const paid = useAppSelector(complectationSliceSelectors.selectPaid);
  const freeState = useAppSelector(complectationSliceSelectors.selectFree);

  const [isAsked, setIsAsked] = useState(false);

  const setConfirmed = () => {
    sessionStorage.setItem(complectationConfirm, 'true');
    setIsAsked(true);
  };

  useEffect(() => {
    if (sessionStorage.getItem(complectationConfirm)) {
      setIsAsked(true);
    }
  }, []);

  return {
    isConfirmed:
      !!freeState.length || !paid.length || isPaidSelected(paid) || isAsked,
    setConfirmed,
  };
};
