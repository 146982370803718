import globalStore from 'modules/global-store';
import api from 'modules/helpers/api';
import { useEffect, useState } from 'react';
import { IProduct } from '../model/types';

export type IdsType = (number | string)[];

export const fetchProducts = async (ids: IdsType) => {
  const fetchedProducts: IProduct[] = await api('product.get', {
    id: ids,
  });
  return fetchedProducts;
};

export const getProducts = async (product_ids: IdsType) => {
  const products: IProduct[] = globalStore.get('cart_products') || [];

  const currentProducts = product_ids.reduce(
    (accumulator, id) => {
      const inCartProducts = products.find((product) => +product.id === id);
      if (inCartProducts) {
        accumulator.inCartProducts.push(inCartProducts);
        return accumulator;
      }
      accumulator.missing.push(id);
      return accumulator;
    },
    { missing: [] as IdsType, inCartProducts: [] as IProduct[] },
  );

  if (!currentProducts.missing.length) return currentProducts.inCartProducts;

  const missingFetched = await fetchProducts(currentProducts.missing);

  const missing = missingFetched.filter((product: IProduct) =>
    product_ids.includes(product.id),
  );

  return [...currentProducts.inCartProducts, ...missing];
};

const useGetProducts = (product_ids: IdsType) => {
  const [products, setProducts] = useState([] as IProduct[]);

  useEffect(() => {
    const getAllProducts = async () => {
      const currentProducts = await getProducts(product_ids);
      setProducts(currentProducts);
    };
    getAllProducts();
  }, [product_ids]);

  return products;
};

export default useGetProducts;
