import './VacancyCard.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import Scroll from 'react-scroll';
import { modalEmitter, vacanciesEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';
import { ExternalImage } from '@shared/ui/image';
import { clsx } from 'clsx';
import RMCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { connect } from 'react-redux';
import { SmartLink } from '@shared/lib/routing';

class VacancyCard extends Component {
  static createMarkup(html) {
    return { __html: html };
  }

  constructor(props) {
    super(props);

    this.state = {
      rotated: false,
    };
  }

  componentDidMount() {
    vacanciesEmitter.addListener('Vacancies.Card.Unrotate', this.toUnrotate);
  }

  componentWillUnmount() {
    vacanciesEmitter.removeListener('Vacancies.Card.Unrotate', this.toUnrotate);
  }

  @autobind
  toRotateVacancy(event, index) {
    event.preventDefault();

    const { currentTarget } = event;
    const { rotated } = this.state;
    const boundTop = currentTarget.getBoundingClientRect().top;
    const scrollTop = window.pageYOffset;
    const offsetTop = 200;

    if (!rotated) {
      if (scrollTop > 200) {
        Scroll.animateScroll.scrollTo(boundTop + scrollTop - offsetTop);
      }

      this.setState({
        rotated: true,
      });
      vacanciesEmitter.emit('Vacancies.Card.showOverlay', index);
    }
  }

  @autobind
  toUnrotate() {
    this.setState({
      rotated: false,
    });
  }

  @autobind
  toCloseVacancy(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      rotated: false,
    });
    vacanciesEmitter.emit('Vacancies.Card.hideOverlay');
  }

  @autobind
  showVacanciesFormModal(event) {
    event.stopPropagation();
    event.preventDefault();
    const {
      vacancy: { id },
    } = this.props;

    modalEmitter.emit('Vacancies.Modal.Open', id);
  }

  sortCollectionWithIndex = (collection, index) => {
    if (index >= collection.length || index < 0) {
      return collection;
    }

    const element = collection[index];
    const remainingElements = [
      ...collection.slice(index + 1),
      ...collection.slice(0, index),
    ];

    return [element, ...remainingElements];
  };

  renderDescription(description, title, id) {
    const { vacancyEmail, t } = this.props;

    const defaultDescription = `<div style="text-align: center; margin-top: 5vw;">${t(
      'VacancyCard.Default-vacancy-description',
    )} <a className="vacancies__link" href={mailto:${vacancyEmail}}>${vacancyEmail}</a></div>`;
    let outputDescription = description || defaultDescription;
    outputDescription =
      outputDescription.length > 15 ? outputDescription : defaultDescription;
    outputDescription = outputDescription.split('<hr>');

    if (outputDescription.length >= 2) {
      return (
        <div key={id}>
          <h2 className="vacancy__card__title">{title}</h2>
          <div className="vacancy__card__description">
            {outputDescription.map((descr, index) => (
              <div
                className="vacancy__card__description__item"
                key={`descr-${index}`}
                dangerouslySetInnerHTML={VacancyCard.createMarkup(descr)}
              />
            ))}
          </div>
        </div>
      );
    }
    return (
      <div key={id}>
        <h2 className="vacancy__card__title">{title}</h2>
        <div className="vacancy__card__description">
          <div
            className="vacancy__card__description__single"
            dangerouslySetInnerHTML={VacancyCard.createMarkup(
              outputDescription,
            )}
          />
        </div>
      </div>
    );
  }

  render() {
    const { rotated } = this.state;
    const { vacancy, vacancies, index, t, mainDomain } = this.props;
    const { text_id, title, city_name, image, salary, schedule, experience } =
      vacancy;

    return (
      <SmartLink
        href={`/${'vakansii/'}${text_id}`}
        className={clsx('vacancy__card', { 'vacancy__card--rotated': rotated })}
        onClick={(event) => {
          this.toRotateVacancy(event, index);
        }}
      >
        <div className="vacancy__card__front">
          <div className="vacancy__card__head">
            <h2 className="vacancy__card__title">{title}</h2>
            <div className="vacancy__card__city"> {city_name}</div>
          </div>
          <ExternalImage
            mainDomain={mainDomain}
            className="vacancy__card__image"
            src={image}
            alt={title}
            width={450}
            height={300}
          />
          <div className="vacancy__card__info">
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Salary')}:
              </div>
              <div className="vacancy__card__info__row__value">{salary}</div>
            </div>
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Schedule')}:
              </div>
              <div className="vacancy__card__info__row__value">{schedule}</div>
            </div>
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Experience')}:
              </div>
              <div className="vacancy__card__info__row__value">
                {experience}
              </div>
            </div>
          </div>

          <Button
            className="vacancy__card__button vacancy__card__button--face"
            onClick={this.showVacanciesFormModal}
          >
            {t('localization.sendResume')}
          </Button>
        </div>
        <div className="vacancy__card__back">
          <div className="vacancy__card__close" onClick={this.toCloseVacancy}>
            {t('localization.closeAction')}
          </div>
          {rotated && (
            <RMCarousel
              infinite
              arrows={false}
              containerClass="containerClass"
              sliderClass="sliderClass"
              itemClass="vacancy__card__carousel__item"
              responsive={{
                allDesktops: {
                  breakpoint: { max: 3000, min: 100 },
                  items: 1,
                },
              }}
              ref={(c) => {
                this.track = c;
              }}
              deviceType="allDesktops"
              ssr
            >
              {this.sortCollectionWithIndex(vacancies, index).map(
                ({ description, title, id }) => (
                  <div key={id}>
                    {this.renderDescription(description, title, id)}
                  </div>
                ),
              )}
            </RMCarousel>
          )}
          <Button
            className="vacancy__card__button"
            onClick={this.showVacanciesFormModal}
          >
            {t('localization.sendResume')}
          </Button>
          <div className="vacancy__card__navigation">
            <div
              className="vacancy__card__navigation__left"
              onClick={() => this.track.previous()}
            />
            {t('VacancyCard.Look-other-vacancies')}
            <div
              className="vacancy__card__navigation__right"
              onClick={() => this.track.next()}
            />
          </div>
        </div>
      </SmartLink>
    );
  }
}
const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(withTranslation()(VacancyCard));
