import { clsx } from 'clsx';
import styles from './arrow.module.scss';

/*
 By default, the Arrow is pointing to the left
*/

interface IArrow {
  width: number;
  height: number;
  toRight?: boolean;
}
export function Arrow({ width, height, toRight }: IArrow) {
  return (
    <span
      className={clsx(styles.root, { [styles.toRight]: toRight })}
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  );
}
