import { baseApi } from '@shared/api';
import { IModalPageProps } from '../model/types';

export const modalPageApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getModalPageData: build.query<
      { data: { modal: IModalPageProps } },
      { url: string; city: string; lang: string }
    >({
      query: (params) => ({
        url: `/endpoint/modal/page`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetModalPageDataQuery } = modalPageApi;
