import './CartProducts.scss';
import * as session from 'modules/session';
import { ICartItem } from 'types/cartTypes';
import { IComplectationItem } from '@entities/complectation';
import { useCurrency } from '@entities/countries';
import CartProduct from '../CartProduct/CartProduct';
import styles from './CartProducts.module.scss';

interface ICratProducts {
  isExact: boolean;
}

const filterCartItems = ({
  items,
  complectations,
}: {
  items: ICartItem[];
  complectations: IComplectationItem[];
}) => {
  if (!complectations || !complectations.length) {
    return items;
  }
  const cartItems = items.filter(
    (item) => !complectations.find(({ id }) => item.id === id),
  );
  return cartItems;
};

function CartProducts({ isExact }: ICratProducts) {
  const {
    items,
    complectations,
  }: { items: ICartItem[]; complectations: IComplectationItem[] } =
    session.get('cart');

  const cartItems = filterCartItems({
    items,
    complectations,
  });
  const currency = useCurrency();

  return (
    <section className={styles.root}>
      {cartItems.map((item) => {
        if (!item) return null;
        return (
          <CartProduct
            key={`card-row-${item.product_id}-${item.id}`}
            {...{
              cartItem: item,
              isExact,
              currency,
            }}
          />
        );
      })}
    </section>
  );
}

export default CartProducts;
