import { IPromotionSingle } from '@entities/promotion';
import { FetchLoaderType } from '@shared/lib/routing';
import { ISeoPage } from '@widgets/seoPage';

type Params = {
  city: string;
  stock: string;
};

export const onePromotionPageLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string },
) => {
  const { city, stock } = params;
  const [seoPageResponse, onePromotionPageResponse] = await Promise.all([
    fetch<ISeoPage>({
      url: '/seo/page',
      params: { url: `/akcii/${stock}`, city, lang },
    }),
    fetch<{ stock: IPromotionSingle[] }>({
      url: '/stock/get',
      params: { text_id: stock, city, lang },
    }),
  ]);
  return {
    seoPageData: seoPageResponse.data,
    onePromotionPageData: onePromotionPageResponse.data,
  };
};
