import { baseApi } from '@shared/api';
import { IProduct } from '../model/types';

const productApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProduct: build.query<
      { data: { product: IProduct[] } },
      { lang: string; city: string; id: number[] }
    >({
      query: (params) => ({
        url: '/endpoint/product/index',
        method: 'GET',
        params,
      }),
    }),
    getProductAdditional: build.query<
      { data: { products: IProduct[] } },
      { lang: string; city: string }
    >({
      query: (params) => ({
        url: '/endpoint/product/get_additionally',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetProductQuery, useGetProductAdditionalQuery } = productApi;
