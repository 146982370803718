export const themeCssVariables = {
  /* palette */
  '--primary-main': '#399',
  '--primary-1': '#399',
  '--primary-2': '#399',
  '--secondary-main': '#f63',
  '--secondary-1': '#f63',
  '--secondary-2': '#f63',
  '--common-1': '#f63',
  '--common-2': '#f63',
  '--common-3': '#f63',
  '--common-4': '#399',
  '--common-5': '#f63',
  '--common-6': '#f63',
  '--common-7': '#399',
  '--common-8': '#033',
  '--brightyellow': '#f6d330',
  '--palma': '#2ca215',
  '--green': '#63ab10',
  '--verydarkgray': '#033',
  '--mine-shaft': '#033',
  '--tundora': '#33999980',
  '--grayishblue': '#6265',
  '--gray': '#898989',
  '--silver-chalice': '#afafaf',
  '--grayishorange': '#b4b3b2',
  '--mercury': '#efefef',
  '--lightgray': '#dbdbdb',
  '--verylightgray': '#d4d4d4',
  '--silver': '#cbcbcb',
  '--alabaster': '#f7f7f7',
  '--white': '#fff',
  '--black': '#033',
  '--footer-icon': '#399',
  '--basket-qty': '#399',
  '--basket-qty-mobile': '#399',
  '--basket-qty-mobile-bg': '#fff',
  '--basket-icon-bg': '#f63',
  '--basket-icon': '#fff',
  '--burger-color': '#033',
  '--hover-menu': '#033',
  '--text': '#333',

  /* variables */
  '--counter-border-radius-desktop': '12px',
  '--counter-border-radius-mobile': '6px',
  '--filter-tags-border-radius': '6px',
  '--tabs-border-radius': '12px',
  '--sw-button-border-radius': '12px',
  '--desktop-menu-description-border-radius': '0 32px 32px 0',
};
