import { useCheckCountries } from '@entities/countries';
import { clsx } from 'clsx';

function NoImgRadioButton({ item, selected, onChange, mobile = false }) {
  const { isUkraine } = useCheckCountries();
  const id = mobile ? `${item.id}-mobile` : item.id;
  return (
    <li className="card-wok__option no-img">
      <input
        id={id}
        name={item.type}
        type="radio"
        checked={selected}
        // value={selected}
        // defaultChecked={selected}
        onChange={() => onChange(item.id)}
        className={clsx('sw-radio', 'card-wok__radio', { ua: isUkraine })}
      />
      <label htmlFor={id} className="card-wok__label">
        {item.title}
      </label>
    </li>
  );
}

export default NoImgRadioButton;
