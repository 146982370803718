import { BlockContainer } from '@shared/ui/content-containers';
import { Subtitle } from '@shared/ui/titles';
import { useTranslation } from 'react-i18next';
import { useGetProductAdditionalQuery } from '../../api/productApi';
import Card from './Card/Card';

interface ICartItems {
  price: number;
  productId: number;
}

interface IProductsAdditionalProps {
  cartItems: ICartItems[];
  cityTextId: string;
  currency: string;
  mainDomain: string;
}

export function ProductsAdditional({
  cartItems,
  cityTextId,
  currency,
  mainDomain,
}: IProductsAdditionalProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { data } = useGetProductAdditionalQuery({
    lang: language,
    city: cityTextId,
  });

  const additionalProducts = data?.data?.products || [];

  if (!additionalProducts?.length) return null;

  return (
    <BlockContainer>
      <Subtitle>{t('BlockProducts.your_additional')}</Subtitle>
      {additionalProducts.map((product) => {
        const item = cartItems.find(
          ({ productId }) => productId === product.id,
        );
        return (
          <Card
            key={product.id}
            product={product}
            price={item?.price || product.price}
            currency={currency}
            mainDomain={mainDomain}
          />
        );
      })}
    </BlockContainer>
  );
}
