import { useTranslation } from 'react-i18next';
import { formatDayOfWeekToString } from '@shared/lib/date';
import { Button } from '@shared/ui/button';
import { mapActionAttribute } from '@shared/third-party-libs/commonMap';
import styles from './storeCard.module.scss';
import StoreTimeTable from '../store-time-table/StoreTimeTable';
import { IStore } from '../../model/types';
import storeHelper from '../../lib/store-helper';

const getPhoneLink = (phone = '') => {
  if (typeof phone !== 'string') {
    return '';
  }
  return phone.replace(/[^\d]/gim, '');
};

export function StoreCard({
  store,
  mainDomain,
  defaultStoreImage,
  defaultPhoneNumber,
  btnText,
  additional,
}: {
  store: IStore | null;
  mainDomain: string;
  defaultStoreImage: string;
  defaultPhoneNumber: string;
  additional?: JSX.Element | null;
  btnText?: string;
}) {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();

  if (!store) return null;

  const phoneNumber = store.phone || defaultPhoneNumber;
  const phone_link = getPhoneLink(phoneNumber);

  let workWeekDays;
  if (store.work_week_days) {
    workWeekDays = store.work_week_days
      .map((d: number) => formatDayOfWeekToString(d, lang))
      .join(', ');
  }

  const isEverydaySameTime = storeHelper.isEverydaySameTime(store);
  const todayWorktime = storeHelper.getWorkTime(store);

  const storeImageSrc = store.image
    ? `https://${mainDomain}/img/${store.image}`
    : defaultStoreImage;

  return (
    <div className={styles.popoverWrapper}>
      <img
        src={storeImageSrc}
        className={styles.storeImage}
        width={212}
        height={212}
        alt="store"
      />
      <div className={styles.popoverContent}>
        <div>
          <div className={styles.popoverAddress}>
            {storeHelper.getAddress(store)}
          </div>
          {!!store.subway && (
            <div className={styles.popoverMetro}>
              {t('Map.subway')}. {store.subway}
            </div>
          )}
        </div>
        {workWeekDays && (
          <div>
            {t('Map.work_days')}: {workWeekDays}
          </div>
        )}
        <div className={styles.popoverTime}>
          <div className={styles.popoverToday}>
            <div className={styles.popverTodayDescription}>
              {isEverydaySameTime ? t('Map.every_day') : t('Map.today')}
            </div>
            <div className={styles.hours}>
              {todayWorktime ? (
                <>
                  &nbsp;
                  <span> {t('Map.from')} </span>
                  &nbsp;
                  {todayWorktime.worktime_from}
                  &nbsp;
                  <span>{t('Map.to')}</span>
                  &nbsp;
                  {todayWorktime.worktime_to}
                </>
              ) : (
                <span> {t('Map.not_work')} </span>
              )}
            </div>
          </div>
          {!isEverydaySameTime && (
            <div className={styles.timetable}>
              <StoreTimeTable store={store} />
            </div>
          )}
        </div>
        {phoneNumber && (
          <div className={styles.popoverPhone}>
            <a href={`tel:${phone_link}`}>
              <span>{phoneNumber}</span>
            </a>
          </div>
        )}
        {additional}
        {btnText && (
          // На кнопку вешается data-action атрибут, т.к. события react не поддерживаются в картах
          <Button {...mapActionAttribute} isFullWidth>
            {btnText}
          </Button>
        )}
      </div>
    </div>
  );
}
