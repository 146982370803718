import {
  ICoordinates,
  IFullAddress,
  PickupMap,
  getMapHook,
} from '@shared/third-party-libs/commonMap';
import {
  StoreCard,
  IStore,
  defaultStoreImage,
  uaDefaultStoreImage,
  uaMapStoreMarker,
  mapStoreMarker,
} from '@entities/stores';
import { useStoresYandex } from '@shared/third-party-libs/yandex';
import { useStoresGoogle } from '@shared/third-party-libs/google';

export interface IPickupMapProps {
  stores: IStore[];
  fullAddress: IFullAddress | null;
  setCurrentCenter: (value: number[] | null) => void;
  cityCoords: ICoordinates;
  currentStoreId: number | null;
  setCurrentStoreId: (store: number | null) => void;
  additional?: JSX.Element | null;
  btnText?: string;
  defaultPhone?: string;
  actionFn?: (store: IStore) => void;
  mapTypeByCountry: number | null;
  mapTypeByCity: number | null;
  isUkraine: boolean;
  mainDomain: string;
}

export function PickupMapWrapper({
  fullAddress,
  currentStoreId,
  stores,
  defaultPhone = '',
  btnText,
  additional,
  actionFn,
  isUkraine,
  mainDomain,
  mapTypeByCountry,
  mapTypeByCity,
  cityCoords,
  setCurrentCenter,
  setCurrentStoreId,
}: IPickupMapProps) {
  const useStores = getMapHook({
    mapTypeByCity,
    mapTypeByCountry,
    yandexHook: useStoresYandex,
    googleHook: useStoresGoogle,
  });

  if (!useStores) return null;

  const mapPointer = isUkraine ? uaMapStoreMarker : mapStoreMarker;
  const defaultImage = isUkraine ? uaDefaultStoreImage : defaultStoreImage;

  const currentStore = currentStoreId
    ? stores.find((s) => s.id === currentStoreId) || null
    : null;

  return (
    <PickupMap
      stores={stores}
      cityCoords={cityCoords}
      fullAddress={fullAddress}
      setCurrentCenter={setCurrentCenter}
      currentStoreId={currentStoreId}
      setCurrentStoreId={setCurrentStoreId}
      mapPointer={mapPointer}
      balloonMarkup={
        <StoreCard
          store={currentStore}
          mainDomain={mainDomain}
          defaultStoreImage={defaultImage}
          defaultPhoneNumber={defaultPhone}
          btnText={btnText}
          additional={additional}
        />
      }
      useStores={useStores}
      actionFn={actionFn}
    />
  );
}
