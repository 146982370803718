import { useRef } from 'react';
import * as React from 'react';
import Pin from './Pin';
import styles from './deliveryMap.module.scss';
import { AddressError, ICoordinates, IFullAddress } from '../types';

type UseMapType = ({
  cityCoords,
  mapRef,
}: {
  cityCoords: ICoordinates;
  mapRef: React.RefObject<HTMLDivElement>;
}) => {
  map: ymaps.Map | google.maps.Map | null;
  onChangeAddress: (coords: ICoordinates) => void;
  getMapCenter: () => number[] | null;
};

export interface IDeliveryMap {
  fullAddress: IFullAddress | null;
  addressError: AddressError | null;
  isNowFetching: boolean;
  cityCoords: ICoordinates;
  useMap: UseMapType;
  setCurrentCenter: (center: number[] | null) => void;
}

export function DeliveryMap({
  cityCoords,
  useMap,
  fullAddress,
  addressError,
  isNowFetching,
  setCurrentCenter,
}: IDeliveryMap) {
  const mapRef = useRef<HTMLDivElement>(null);

  const { map, onChangeAddress, getMapCenter } = useMap({
    cityCoords,
    mapRef,
  });

  return (
    <>
      <div ref={mapRef} role="application" className={styles.deliveryMap} />
      {map && (
        <Pin
          {...{
            mapRef,
            fullAddress,
            addressError,
            isNowFetching,
            setCurrentCenter,
            onChangeAddress,
            getMapCenter,
          }}
        />
      )}
    </>
  );
}
