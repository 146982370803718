import useSWR from 'swr';
import api from 'modules/helpers/api';
import { IProduct, ISuperProduct } from 'types/product';
import { FavoritesState, IToggleResponse } from './types';
import useUserIsExist from '../useUserExist';

const useFavorites = () => {
  const { isUserExist } = useUserIsExist();

  const { data, mutate, error } = useSWR<(IProduct | ISuperProduct)[]>(
    isUserExist ? 'favorites.list' : null,
    api,
  );

  const toggle = async (currentProduct: IProduct) => {
    if (!data) return;

    const { state, product }: IToggleResponse = await api('favorites.toggle', {
      product_id: currentProduct.id,
    });

    switch (state) {
      case FavoritesState.removed:
        return mutate(data?.filter((el) => el.id !== product.id));
      case FavoritesState.inserted:
        return mutate([...(data || []), product]);
      default:
        throw new Error(`unknown state ${state}`);
    }
  };

  return {
    toggle,
    loading: !data && !error,
    data,
    error,
  };
};
export default useFavorites;
