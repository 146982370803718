import { Link, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import { isAbsoluteUrl } from '@shared/lib';
import { useCityTextIdParam } from './city-text-id-param';

export interface ISmartLink
  extends Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'ref' // for Link
  > {
  className?: string;
  href?: string;
  activeClassName?: string;
  isDefaultStyled?: boolean;
}
export function SmartLink({
  children,
  className,
  href,
  activeClassName = '',
  isDefaultStyled = false,
  ...props
}: ISmartLink) {
  const { pathname } = useLocation();
  const normalizedPathname = pathname
    .replace(/\/{2,}/g, '/')
    .replace(/\/?$/, '/');
  const cityTextId = useCityTextIdParam();
  if (!href) {
    return (
      <a href={href} className={className} {...props}>
        {children}
      </a>
    );
  }
  const cleanHref = href.replace(/\\/g, '');
  const normalizedHrefWithCity = `/${cityTextId}/${cleanHref}`
    .replace(/\/{2,}/g, '/')
    .replace(/\/?$/, '/');

  return isAbsoluteUrl(cleanHref) ? (
    <a className={className} href={cleanHref} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link
      to={normalizedHrefWithCity}
      className={clsx(className, isDefaultStyled && 'default-styled-link', {
        [activeClassName]: normalizedPathname === normalizedHrefWithCity,
      })}
      {...props}
    >
      {children}
    </Link>
  );
}
