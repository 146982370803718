import { RoutesWithSubRoutes } from '@shared/lib/routing';
import routes from './routes';
import { ErrorCatcher } from './error-boundary';

function App() {
  return (
    <ErrorCatcher>
      <RoutesWithSubRoutes routes={routes} />
    </ErrorCatcher>
  );
}

export default App;
