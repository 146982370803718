const cities = [
  'ode',
  'ben',
  'czen',
  'kho',
  'dnepr',
  'vin',
  'berdichev',
  'kiev',
  'lvov',
  'var',
];

const isNeedUAEcom = (city: string) => cities.some((el) => el === city);

export default isNeedUAEcom;
