import { combineReducers } from '@reduxjs/toolkit';
import { countriesSlice } from '@entities/countries';
import { complectationSlice } from '@entities/complectation';
import { deliverySlice } from '@entities/delivery';
import { baseApi } from '@shared/api';
import { pickupSlice } from '@entities/pickup';

export const rootReducer = combineReducers({
  [complectationSlice.name]: complectationSlice.reducer,
  [deliverySlice.name]: deliverySlice.reducer,
  [countriesSlice.name]: countriesSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [pickupSlice.name]: pickupSlice.reducer,
});
