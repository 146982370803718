import { Carousel } from '@shared/ui/carousel';
import { useState } from 'react';
import { clsx } from 'clsx';
import { IMainSlider } from '../../model/types';
import { ControlButton } from './ControlButton';
import { DURATION_TIME } from '../../model/constants';
import style from './desktopSlider.module.scss';
import { SliderItem } from '../SliderItem';

interface IDesktopSliderProps {
  banners: IMainSlider[];
  className: string;
}

export function DesktopSlider({ banners, className }: IDesktopSliderProps) {
  const [viewedSlideIndexes, setViewedSlideIndexes] = useState([0, 1, 2]);

  // для корректной работы десктопного слайдера нужно, чтобы входящее количество слайдов было 4 или более
  const slides =
    banners.length > 1 || banners.length < 4
      ? banners.concat(banners)
      : banners;

  const onClickPrev = () => {
    const firstIndex = viewedSlideIndexes[0];
    const newIndex = firstIndex > 0 ? firstIndex - 1 : slides.length - 1;
    setViewedSlideIndexes([newIndex, ...viewedSlideIndexes.slice(0, -1)]);
  };

  const onClickNext = () => {
    const lastIndex = viewedSlideIndexes[viewedSlideIndexes.length - 1];
    const newIndex = lastIndex < slides.length - 1 ? lastIndex + 1 : 0;
    setViewedSlideIndexes([...viewedSlideIndexes.slice(1), newIndex]);
  };

  const getSlidePosition = (slideIndex: number) => {
    const findedIndex = viewedSlideIndexes.findIndex(
      (viewedIndex) => viewedIndex === slideIndex,
    );
    const positions = ['left', 'center', 'right'];
    return positions[findedIndex] || 'top';
  };

  return (
    <Carousel
      responsive={{
        desktop: { breakpoint: { max: 3840, min: 1024 }, items: 3 },
      }}
      containerClass={className}
      customRightArrow={
        <ControlButton direction="right" updateSlideIndex={onClickNext} />
      }
      customLeftArrow={
        <ControlButton direction="left" updateSlideIndex={onClickPrev} />
      }
      customTransition={`transform ${DURATION_TIME}ms ease`}
      draggable={false}
      infinite
      ssr
    >
      {slides.map((slide, index) => {
        const position = getSlidePosition(index);
        return (
          <div
            className={clsx(style.sliderItem, {
              [style.left]: position === 'left',
              [style.right]: position === 'right',
              [style.top]: position === 'top',
            })}
            key={slide.id}
          >
            <SliderItem slide={slide} />
          </div>
        );
      })}
    </Carousel>
  );
}
