import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CloseButton } from '@shared/ui/close-button/CloseButton';
import { useAppDispatch } from '@shared/lib/store';
import { useCityTextIdParam } from '@shared/lib/routing';
import styles from './wantMoreButton.module.scss';
import { deleteAllPaid } from '../../model/slice';

interface IWantMoreButton {
  isExact: boolean;
  show: boolean;
  setShow: (show: boolean) => void;
  onError: (text: string) => void;
}

export function WantMoreButton({
  isExact,
  show,
  setShow,
  onError,
}: IWantMoreButton) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const cityTextId = useCityTextIdParam();

  return show ? (
    <div className={styles.colseButtonContainer}>
      <CloseButton
        onClick={() => {
          setShow(false);
          dispatch(deleteAllPaid(onError));
        }}
      />
    </div>
  ) : (
    <button
      type="button"
      className={styles.moreButton}
      onClick={() => {
        setShow(true);
        if (!isExact) {
          history.replace(`/${cityTextId}/cart`);
        }
      }}
    >
      {t('Complectation.needMore')}
    </button>
  );
}
