import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import { useEffect } from 'react';
import { useSelectAddressModal } from './select-address-modal-handler';
import { ISelectAddressModal } from '../ui/SelectAddressModal';

const sessionStorageKey = 'address-asked';

interface ISelectAddressFirst extends ISelectAddressModal {
  needToShowInCity: boolean;
}

export const useSelectAddressFirst = ({
  needToShowInCity,
  ...rest
}: ISelectAddressFirst) => {
  const modal = useSelectAddressModal();

  useEffect(() => {
    const onInCartClick = () => {
      const isShown = sessionStorage.getItem(sessionStorageKey) === 'true';
      if (isShown || !needToShowInCity) return;
      sessionStorage.setItem(sessionStorageKey, 'true');
      modal.show(rest);
    };

    Emitter.addListener('CART_CHANGE_QUANTITY', onInCartClick);
    return () => {
      Emitter.removeListener('CART_CHANGE_QUANTITY', onInCartClick);
    };
  }, [modal, needToShowInCity]);
};
