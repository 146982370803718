import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

const i18nextInstance = i18next.createInstance();
i18nextInstance.use(
  resourcesToBackend(
    (language: string, namespace: string) =>
      import(`./locales/${language}/${namespace}.json`),
  ),
);
export { i18nextInstance };
