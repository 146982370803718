import { dayOfWeek, formatDayOfWeekToString } from '@shared/lib/date';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import storeHelper from '../../lib/store-helper';
import { IStore } from '../../model/types';
import styles from './storeTimeTable.module.scss';

function isToday(weekDay: number) {
  return weekDay === dayOfWeek(new Date());
}

function StoreTimeTable({ store }: { store?: IStore }) {
  const {
    i18n: { language: lang },
  } = useTranslation();

  if (!store) return null;

  const worktime = storeHelper.getWorktimeShedule(store);

  return (
    <table className={styles.storeTimetable}>
      <thead>
        <tr className={styles.row}>
          {worktime.map((current) => (
            <th
              key={current.day}
              className={clsx(styles.col, styles.header, {
                [styles.selected]: isToday(current.day),
              })}
            >
              {formatDayOfWeekToString(current.day, lang)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className={styles.row}>
          {worktime.map((current) => (
            <td
              key={current.day}
              className={clsx(styles.col, {
                [styles.selected]: isToday(current.day),
              })}
            >
              {current.worktime_from}
            </td>
          ))}
        </tr>
        <tr className={styles.row}>
          {worktime.map((current) => (
            <td
              key={current.day}
              className={clsx(styles.col, {
                [styles.selected]: isToday(current.day),
              })}
            >
              {current.worktime_to}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}

export default StoreTimeTable;
