import { addFree } from 'src/entities/complectation/model/slice';
import { useAppDispatch } from '@shared/lib/store';
import { useTranslation } from 'react-i18next';
import styles from './complectationFree.module.scss';
import ComplectationItem, {
  ICompItemData,
} from '../complectation-item/ComplectationItem';

export interface ICompFreeItem extends ICompItemData {
  max: number;
}

interface IComplectationFreeView {
  items: ICompFreeItem[];
  isExact: boolean;
}

function DontWantButton({ id, qty }: { id: number; qty: number }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  if (!qty) return null;
  return (
    <button
      type="button"
      onClick={() => dispatch(addFree({ id, qty }))}
      className={styles.moreButton}
    >
      {t('Complectation.dontWant')}
    </button>
  );
}

function ComplectationFree({ items, isExact }: IComplectationFreeView) {
  const dispatch = useAppDispatch();

  if (!items.length) return null;

  const setQty = (id: number, qty: number) => {
    dispatch(addFree({ id, qty }));
  };

  return (
    <section>
      {items.map(({ product_id, qty, max, ...rest }) => (
        <ComplectationItem
          key={product_id}
          itemData={{ product_id, qty, ...rest }}
          isExact={isExact}
          summPrice={<DontWantButton id={product_id} qty={-qty} />}
          moreAvaliable={qty >= 0 ? qty < max : false}
          setQty={setQty}
        />
      ))}
    </section>
  );
}

export default ComplectationFree;
