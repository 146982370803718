const yandexMapCode = 1;
const googleMapCode = 2;

export const getMapType = ({
  mapTypeByCity,
  mapTypeByCountry,
}: {
  mapTypeByCity: number | null;
  mapTypeByCountry: number | null;
}) => {
  const mapType = mapTypeByCity || mapTypeByCountry;

  return {
    isYandexMap: mapType === yandexMapCode,
    isGoogleMap: mapType === googleMapCode,
  };
};

export const getMapHook = <T, K>({
  mapTypeByCity,
  mapTypeByCountry,
  yandexHook,
  googleHook,
}: {
  mapTypeByCity: number | null;
  mapTypeByCountry: number | null;
  yandexHook: T;
  googleHook: K;
}) => {
  const { isYandexMap, isGoogleMap } = getMapType({
    mapTypeByCity,
    mapTypeByCountry,
  });
  if (isYandexMap) return yandexHook;
  if (isGoogleMap) return googleHook;
  return null;
};

export type MapTypes = ReturnType<typeof getMapType>;
