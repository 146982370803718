import { InternalImage } from '@shared/ui/image';
import byPaymentSetImg from './assert/fullSet.png';
import byMobilePaymentSetImg from './assert/mobileFull.png';
import {
  byRequisites,
  requiredCities,
} from '../../config/by-requisites-scheme';

export function ByPayment({ cityTextId }: { cityTextId: string }) {
  const isRequiredCities = requiredCities.includes(cityTextId);
  if (!isRequiredCities) return null;
  return (
    <>
      <InternalImage
        src={byMobilePaymentSetImg}
        alt="Payment Set"
        className="block! md:hidden!"
        width={360}
        height={640}
      />
      <InternalImage
        src={byPaymentSetImg}
        alt="Payment Set"
        className="mx-auto hidden! max-w-[60vw] md:block!"
        width={1839}
        height={180}
      />
      <p className="text-sm text-balance text-gray">
        {byRequisites[cityTextId as keyof typeof byRequisites]}
      </p>
    </>
  );
}
