import clsx from 'clsx';
import { Arrow } from '@shared/ui/arrow';
import styles from './arrowButton.module.scss';

interface ArrowButtonProps {
  direction: 'left' | 'right';
  onClick?: () => void;
}
export function ArrowButton({ direction, onClick }: ArrowButtonProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-label={direction === 'left' ? 'предыдущий' : 'следующий'}
      className={clsx(styles.arrow, {
        [styles.left]: direction === 'left',
        [styles.right]: direction === 'right',
      })}
    >
      <Arrow width={14} height={30} toRight={direction === 'right'} />
    </button>
  );
}
