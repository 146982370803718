import styles from './headerPhone.module.scss';

function HeaderPhone({ cityPhone }: { cityPhone: string }) {
  if (!cityPhone) return null;

  return (
    <a className={styles.phone} href={`tel:${cityPhone}`}>
      {cityPhone}
    </a>
  );
}

export { HeaderPhone };
