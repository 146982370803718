import { memo } from 'react';
import { ArrowButton, Carousel } from '@shared/ui/carousel';
import { clsx } from 'clsx';
import styles from './trioOnMain.module.scss';
import { ITrioItem } from '../model/types';
import TrioItem from './trio-item/TrioItem';

function TrioOnMainComponent({
  stocks,
  mainDomain,
}: {
  stocks?: ITrioItem[];
  mainDomain: string;
}) {
  if (!stocks) return null;

  const responsive = {
    desktop: {
      breakpoint: { max: 4096, min: 769 },
      items: stocks.length >= 3 ? 3 : stocks.length,
    },
    tablet: {
      breakpoint: { max: 768, min: 431 },
      items: stocks.length >= 2 ? 2 : stocks.length,
    },
    mobile: { breakpoint: { max: 430, min: 1 }, items: 1 },
  };
  return (
    <div className={styles.root}>
      <Carousel
        customRightArrow={<ArrowButton direction="right" />}
        customLeftArrow={<ArrowButton direction="left" />}
        responsive={responsive}
        deviceType="mobile"
        containerClass={clsx(styles.container, {
          [styles.sliderWithOnlyTwoItems]: stocks.length === 2,
        })}
        sliderClass={clsx({
          [styles.sliderWithOnlyTwoItems]: stocks.length === 2,
        })}
        ssr
      >
        {stocks.map((item) => (
          <TrioItem key={item.id} item={item} mainDomain={mainDomain} />
        ))}
      </Carousel>
    </div>
  );
}

export const TrioOnMain = memo(TrioOnMainComponent);
