import { TFunction } from 'i18next';

export const menuIds = [708, 713, 1, 716];

/// @todo make list possible a handler
export function getConfig(t: TFunction) {
  return {
    items: [
      {
        number: 'one',
        active: false,
        timer: 16900,
        title: t('Arguments.vkusno_i_raznoobrazno'),
        text: null,
        list: [
          t('Arguments.more_350_positions_in_menu'),
          t('Arguments.only_froesh_ingridients'),
          t('Arguments.reload_menu_4_times_of_year'),
        ],
        link: {
          href: 'menu',
          text: t('Arguments.get_it'),
        },
      },
      {
        number: 'two',
        active: false,
        timer: 12700,
        title: t('Arguments.our_prices'),
        text: null,
        list: [
          t('Arguments.sushi_price_from'),
          t('Arguments.soups_price_from'),
          t('Arguments.rolls_price_from'),
          t('Arguments.busines_price_from'),
        ],
        link: {
          href: 'menu',
          text: t('Arguments.check'),
        },
      },
      {
        number: 'three',
        active: false,
        timer: 8200,
        title: t('Arguments.actions'),
        text: [t('Arguments.actions_text_1'), t('Arguments.actions_text_2')],
        list: null,
        link: {
          href: 'akcii',
          text: t('Arguments.find_sale'),
        },
      },
      {
        number: 'four',
        active: false,
        timer: 4700,
        title: t('Arguments.standarts'),
        text: null,
        list: [
          t('Arguments.standarts_text_1'),
          t('Arguments.standarts_text_2'),
        ],
        link: {
          href: 'menu',
          text: t('Arguments.see'),
        },
      },
      {
        number: 'five',
        active: false,
        timer: 2100,
        title: t('Arguments.shops_title'),
        text: [t('Arguments.shops_text')],
        list: null,
        link: {
          href: 'addresses',
          text: t('Arguments.shops'),
        },
      },
      {
        number: 'six',
        active: false,
        timer: 500,
        title: t('Arguments.delivery_title'),
        text: null,
        list: [t('Arguments.delivery_text_1'), t('Arguments.delivery_text_2')],
        link: {
          href: 'delivery',
          text: t('Arguments.delivery'),
        },
      },
    ],
  };
}
