import { Fragment, useCallback, useEffect, useState } from 'react';
import api from 'modules/helpers/api';
import { useCityAndLang } from '@shared/lib/use-city-and-lang';
import CustomText from 'frontend/Components/CustomText/CustomText';
import { Banner } from '@widgets/seoPage';
import { useTranslation } from 'react-i18next';
import { TrioOnMain } from 'frontend/Pages/main/trio-on-main';
import { INews, Publication } from '@entities/publication';
import { useSelectCountry } from '@entities/countries';
import { MainSlider } from '../main-slider';
import { PromoBlocks } from '../promo';
import styles from './main.module.scss';
import { IMainPageItem } from '../model/types';

export function Main() {
  const { t } = useTranslation();
  const { lang, city } = useCityAndLang();
  const country = useSelectCountry();
  const [promoLinks, setPromoLinks] = useState();
  const [activeBlocks, setActiveBlocks] = useState<IMainPageItem[]>([]);
  const [banner, setBanner] = useState();
  const [stocks, setStocks] = useState();
  const [news, setNews] = useState<INews[]>();
  const [articles, setArticles] = useState();

  const fetchInitialData = useCallback(() => {
    api('settings.mainpage').then(async (items: IMainPageItem[]) => {
      const params = { lang, city };

      if (!items.length) return;

      const sortedActiveBlocks = items
        .filter((block) => block.active)
        .sort((a, b) => a.order - b.order);

      const isActiveBlock = (name: string) =>
        sortedActiveBlocks.find((item) => item.name === name);

      const [promoLinksRes, bannerRes, stocksRes, newsRes, articlesRes] =
        await Promise.all([
          isActiveBlock('promos') &&
            api('/endpoint/setting/promo_links', {}, params),
          isActiveBlock('slider') && api('/endpoint/banner/slider', {}, params),
          isActiveBlock('stocks') && api('stock.main'),
          isActiveBlock('news') && api('news.main'),
          isActiveBlock('articles') && api('articles.main'),
        ]);

      setActiveBlocks(sortedActiveBlocks);
      if (promoLinksRes?.data) setPromoLinks(promoLinksRes.data.promo_links);
      if (bannerRes?.data) setBanner(bannerRes.data.slider);
      if (stocksRes) setStocks(stocksRes);
      if (newsRes) setNews(newsRes);
      if (articlesRes) setArticles(articlesRes);
    });
  }, [city, lang]);

  useEffect(() => fetchInitialData(), [fetchInitialData]);

  const contentBlocks = {
    slider: <MainSlider banner={banner} />,
    stocks: <TrioOnMain stocks={stocks} mainDomain={country.main_domain} />,
    promos: <PromoBlocks promoLinks={promoLinks} />,
    news: (
      <Publication
        list={news?.map((item) => ({
          ...item,
          publication_date: item.publication_from,
        }))}
        titleText={t('Main.news')}
        bottomLinkText={t('Main.allNews')}
        href="news"
      />
    ),
    articles: (
      <Publication
        href="articles"
        titleText={t('Main.articles')}
        bottomLinkText={t('Articles.allArticles')}
        list={articles}
      />
    ),
  };

  return (
    <>
      <Banner pathname="/" />
      <div className={styles.main}>
        {activeBlocks.map(({ name }) => (
          <Fragment key={name}>
            {contentBlocks[name as keyof typeof contentBlocks]}
          </Fragment>
        ))}
        <CustomText />
      </div>
    </>
  );
}
