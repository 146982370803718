import { useTranslation } from 'react-i18next';
import styles from './time-and-phone-Info.module.scss';

export function TimeAndPhoneInfo({
  emailgate,
  cityPhone,
  isCallBack,
  deliveryTo,
  deliveryFrom,
  worktimeFrom,
  worktimeTo,
}: {
  emailgate: string;
  cityPhone: string;
  isCallBack: boolean;
  deliveryTo: string;
  deliveryFrom: string;
  worktimeFrom: string;
  worktimeTo: string;
}) {
  const { t } = useTranslation();
  const isAllDayDelivery = deliveryFrom === deliveryTo;
  const isAllDayWorktime = worktimeFrom === worktimeTo;
  if (isCallBack) {
    return (
      <div className={styles.timeAndPhoneInfoContainer}>
        <a
          className={styles.phone}
          href={`tel:${cityPhone.replace(/[^0-9]/g, '')}`}
        >
          {cityPhone}
        </a>
        <div className={styles.shopTime}>
          {t('Footer.ShopHours')}:{' '}
          {isAllDayWorktime
            ? t('Footer.24 hours')
            : `${worktimeFrom} – ${worktimeTo}`}
        </div>
        {!emailgate && (
          <div className={styles.deliveryTime}>
            {t('Footer.Delivery Hours')}:{' '}
            {isAllDayDelivery
              ? t('Footer.24 hours')
              : `${deliveryFrom} – ${deliveryTo}`}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.timeAndPhoneInfoContainer}>
      <div className={styles.shopTime}>
        {t('Footer.ShopHours')}:{' '}
        {isAllDayWorktime
          ? `${t('Footer.24 hours')}`
          : `${worktimeFrom} – ${worktimeTo}`}
      </div>
      {emailgate && (
        <div className={styles.deliveryTime}>
          {t('Footer.Delivery Hours')}:{' '}
          {isAllDayDelivery
            ? t('Footer.24 hours')
            : `${deliveryFrom} – ${deliveryTo}`}
        </div>
      )}
    </div>
  );
}
