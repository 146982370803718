import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@features/modals';
import { IReorderModalProps } from './types';

export const useReorderModal = () => {
  const modal = useModal(modalIds.reorderModalId);

  return {
    ...modal,
    show: (args: IReorderModalProps) => modal.show(args),
  };
};
