import {
  Familiarization,
  PersonalData,
  Messages,
  AlergicProducts,
} from '../ui/lables/Lables';

export const scheme = [
  {
    id: 'familiarization',
    label: <Familiarization />,
    isNecessary: true,
  },
  {
    id: 'personalData',
    label: <PersonalData />,
    isNecessary: true,
  },
  {
    id: 'messages',
    label: <Messages />,
    isNecessary: false,
  },
  {
    id: 'alergicProducts',
    label: <AlergicProducts />,
    isNecessary: true,
  },
];
