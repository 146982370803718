import { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import style from './dropdownMenu.module.scss';
import type { IDropdownDataItem } from '../Dropdown';

type KeyboardKeys = 'ArrowDown' | 'ArrowUp' | 'Enter' | 'Esc' | string | null;
const listeningKeys: KeyboardKeys[] = ['ArrowDown', 'ArrowUp', 'Enter', 'Esc'];

function DropdownMenu({
  menuRef,
  data,
  visible,
  onSelect,
  dataChange,
  setActiveInSuggest,
  closeDropdown,
}: {
  data: IDropdownDataItem[];
  visible: boolean;
  menuRef: React.RefObject<HTMLUListElement>;
  onSelect: (value: unknown) => void;
  dataChange: boolean;
  setActiveInSuggest?: (value: string | null) => void;
  closeDropdown?: () => void;
}) {
  const [keyToHandle, setKeyToHandle] = useState<KeyboardKeys>(null);
  const [dataToRender, setDataToRender] = useState<IDropdownDataItem[]>(data);

  const [active, setActive] = useState<number | null>(null);
  const onclick = (value: unknown) => {
    onSelect(value);
  };

  const handleKeydown = (e: KeyboardEvent) => {
    if (!visible) return;
    const { key } = e;
    if (listeningKeys.includes(key)) setKeyToHandle(key);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => document.addEventListener('keydown', handleKeydown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef]);

  useEffect(() => {
    setActive(null);
  }, [dataToRender]);

  useEffect(() => {
    if (dataChange) setDataToRender(data);
  }, [dataChange]);

  useEffect(() => {
    if (setActiveInSuggest)
      setActiveInSuggest(
        active !== null ? (data[active].value as string) : null,
      );
  }, [active]);

  useEffect(() => {
    if (!keyToHandle) return;
    setKeyToHandle(null);
    if (!visible) return;
    if (keyToHandle === listeningKeys[0]) {
      const nextActive = active !== null ? active + 1 : 0;
      setActive(nextActive > data.length - 1 ? null : nextActive);
    } else if (keyToHandle === 'ArrowUp') {
      const nextActive = active !== null ? active - 1 : data.length - 1;
      setActive(nextActive >= 0 ? nextActive : null);
    } else if (keyToHandle === 'Enter' && active !== null) {
      // Обрабатывается в саджесте
      onclick(data[active].value);
    } else if (keyToHandle === 'Enter' && closeDropdown) closeDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyToHandle, active]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <ul
      ref={menuRef}
      className={style.suggestMenu}
      onMouseLeave={() => {
        setActive(null);
      }}
    >
      {data.map((item, index) => {
        const { key, label } = item;
        return (
          <li
            key={key}
            role="presentation"
            // onClick={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            //   onclick(value);
            // }}
            onFocus={() => {
              setActive(index);
            }}
            onMouseOver={() => {
              setActive(index);
            }}
            className={clsx(style.suggestItem, {
              [style.active]: index === active,
            })}
          >
            <span className={style.itemText}>{label}</span>
          </li>
        );
      })}
    </ul>
  );
}
export default DropdownMenu;
