import './List.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import { modalEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';
import ReviewsListItem from '../ListItem/ListItem';

class ReviewsList extends Component {
  constructor(props) {
    super(props);
    const { reviews, store } = props;
    this.state = {
      reviews,
      store,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { reviews, store } = nextProps;
    this.setState({ reviews, store });
  }

  @autobind
  showAddFormModal(e) {
    const { onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
    const { store } = this.state;
    modalEmitter.emit('Review.Modal.Open', store);
  }

  render() {
    const { reviews } = this.state;
    const { t } = this.props;
    if (reviews && reviews.length === 0) {
      return (
        <div className="reviews-list--empty">
          <div className="title-container">
            <div className="title">{t('List.Reviews is empty left')}</div>
            <div className="title">{t('List.Reviews is empty right')}</div>
          </div>
          <Button className="sw-button" onClick={this.showAddFormModal}>
            {t('List.add_review')}
          </Button>
        </div>
      );
    }

    return (
      <div className="reviews-list">
        {reviews && reviews.length
          ? reviews
              .filter(({ city_id }) => +city_id !== 0)
              .map((review) => (
                <ReviewsListItem key={`review-${review.id}`} review={review} />
              ))
          : undefined}
      </div>
    );
  }
}

export default withTranslation()(ReviewsList);
