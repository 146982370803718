import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@shared/lib/store';
import { IComplectationPaid } from '../types';
import { addPaid, complectationSliceSelectors } from '../model/slice';

const debounceDelay = 500; // Задержка для отправки запроса cart/items

const useDebouncer = (onError: (text: string) => void) => {
  const [toSend, setToSend] = useState<IComplectationPaid[]>([]);
  const [toShow, setToShow] = useState<IComplectationPaid[]>([]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const isLoading = useAppSelector(complectationSliceSelectors.selectIsLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isSending) {
      setToShow(toSend);
    }
  }, [toSend, isSending]);

  useEffect(() => {
    if (isSending && !isLoading) {
      setIsSending(false);
    }
  }, [isLoading]);

  const send = async (toSendItems: IComplectationPaid[]) => {
    setIsSending(true);
    setToSend([]);
    dispatch(addPaid({ toSendItems, onError }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSend = useCallback(debounce(send, debounceDelay), []);

  useEffect(() => {
    if (!toSend.length) return;
    debouncedSend(toSend);
  }, [debouncedSend, toSend]);

  const addToSend = (id: number, qty: number) => {
    const item = toSend.find(({ product_id }) => id === product_id);
    if (item) {
      setToSend(
        toSend.map((it) => {
          if (id === it.product_id) return { ...it, qty: it.qty + qty };
          return it;
        }),
      );
      return;
    }
    setToSend([...toSend, { product_id: id, qty }]);
  };

  return { toSend: toShow, addToSend };
};

export default useDebouncer;
