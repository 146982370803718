import { IImage } from 'types/index';

export interface AdditionalPrice {
  title: string;
  price: number;
}

export const enum tagIdValues {
  spicy = 1,
  vegan = 6,
  allergic = 200,
}

export interface ITag {
  tag_id: tagIdValues;
  title: string;
  visible: boolean;
}

interface Type {
  name: string;
  min_toppings: number;
  max_toppings: number;
  is_free: boolean;
}

export interface Param {
  name: string;
  value: string;
}

export interface IProductTopping {
  product_id: number;
  position: number;
  important: boolean;
  type: Type;
  params: Param[];
}

export interface IProductType {
  name: string;
  min_toppings: number;
  max_toppings: number;
  visible: boolean;
}

export interface IProductDecoration {
  add_button_color: string;
  additional_text_color: string;
  border_color: string;
  card_color: string;
  color_border_cart: string;
  color_border_cart_hover: string;
  color_text_cart: string;
  color_text_cart_hover: string;
  image: string;
  ingredients_color: string;
  name_color: string;
  price_bg_color: string;
  price_color: string;
  show_android: boolean;
  show_ios: boolean;
  show_web: boolean;
}

export interface IBadge {
  action_from: string;
  action_to: string;
  show_android: boolean;
  show_ios: boolean;
  show_web: boolean;
  type: BadgeType;
}

export enum BadgeType {
  Period = 1,
  Timer = 2,
}

export interface IComposition {
  composition_id: number;
  title: string;
  position: number;
}

export interface IProduct {
  id: number;
  menu_id: number;
  title: string;
  weight: number;
  weight_type: string;
  type: string;
  text_id: string;
  is_new: boolean;
  position: number;
  max_cutlery: number;
  popular: number;
  images: IImage[];
  menus: number[];
  product_month: boolean;
  promocodes: unknown[];
  price: number;
  price_old: number;
  is_delivery: boolean;
  is_pickup: boolean;
  enabled: boolean;
  is_action: boolean;
  available: boolean;
  rule_id?: unknown;
  type_id?: number | null;
  anchored_html?: string | null;
  price_orig: number | null;
  tags?: ITag[];
  composition?: IComposition[];
  toppings: number[];
  badge?: IBadge;
  available_from?: string;
  available_to?: string;
  product_type?: IProductType;
  product_toppings?: IProductTopping[];
  additional_price?: AdditionalPrice[];
  hide?: boolean;
  decoration: IProductDecoration;
}
