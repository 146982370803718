import { PreloadedState, configureStore } from '@reduxjs/toolkit';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import { baseApi } from '@shared/api';
import { PersistConfig, persistReducer } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rootReducer } from './rootReducer';

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: unknown) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage =
  typeof window === 'undefined'
    ? createNoopStorage()
    : createWebStorage('local');

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  version: 1,
  // !!! ACHTUNG
  whitelist: ['complectation', 'pickupStore'],
  // When changing the structure of whitelisted slices,
  // you need to CHANGE THE VERSION NUMBER
  migrate: (state, version) => {
    // eslint-disable-next-line no-underscore-dangle
    if (version !== state?._persist.version) return Promise.resolve(undefined);
    return Promise.resolve(state);
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = (
  initialState?: PreloadedState<ReturnType<typeof rootReducer>>,
) => {
  const store = configureStore({
    preloadedState: initialState || {},
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(baseApi.middleware),
  });
  setupListeners(store.dispatch);
  return store;
};

// for types
export const initStore = configureStore({
  reducer: rootReducer,
});
