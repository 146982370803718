import { Provider } from 'react-redux';
import { makeStore } from '@app/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactNode } from 'react';

export function StoreProvider({ children }: { children: ReactNode }) {
  // eslint-disable-next-line no-underscore-dangle
  const initialState = window.__PRELOADED_STATE__;
  // eslint-disable-next-line no-underscore-dangle
  delete window.__PRELOADED_STATE__;

  const store = makeStore(initialState);
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={children} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
