import { PropsWithChildren } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { useDialog } from '@shared/lib/use-dialog';
import styles from './dialog.module.scss';

interface IDialog {
  onClose: () => void;
  isOpen: boolean;
}
export function Dialog({
  children,
  isOpen,
  onClose,
}: PropsWithChildren<IDialog>) {
  const dialogRef = useDialog({ isOpen, onClose });

  return (
    <RemoveScroll enabled={isOpen} forwardProps ref={dialogRef}>
      <dialog className={styles.root}>{children}</dialog>
    </RemoveScroll>
  );
}
