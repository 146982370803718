import { Suggest } from '@shared/third-party-libs/suggest';
import { useYandexSuggest } from '@shared/third-party-libs/yandex';
import { useGoogleSuggest } from '@shared/third-party-libs/google';
import {
  ICoordinates,
  IFullAddress,
  getMapHook,
} from '@shared/third-party-libs/commonMap';

const searchConfig = {
  resultsNumber: 7,
  reqMinLength: 3,
  debounceDelay: 500,
};

export interface ISuggestWrapperProps {
  placeholder?: string;
  fullAddress: IFullAddress | null;
  setAddress: (value: string) => void;
  cityCoords: ICoordinates;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  required?: string;
  initialValue?: string;
  cityName: string;
  lang: string;
  mapTypeByCountry: number | null;
  mapTypeByCity: number | null;
}

export function SuggestWrapper({
  placeholder,
  fullAddress,
  setAddress,
  cityCoords,
  name,
  onBlur,
  required,
  initialValue,
  cityName,
  lang,
  mapTypeByCountry,
  mapTypeByCity,
}: ISuggestWrapperProps) {
  const useSuggest = getMapHook({
    mapTypeByCity,
    mapTypeByCountry,
    yandexHook: useYandexSuggest,
    googleHook: useGoogleSuggest,
  });

  if (!useSuggest) return null;

  return (
    <Suggest
      {...{
        placeholder,
        fullAddress,
        setAddress,
        cityCoords,
        name,
        onBlur,
        required,
        initialValue,
        cityName,
        lang,
      }}
      searchConfig={searchConfig}
      useSuggest={useSuggest}
    />
  );
}
