import { ICity } from '@entities/cities';
import type { IMenuElement } from '@entities/menu';
import { ICountry } from '@entities/countries';
import { createContext, useContext } from 'react';

export const CityLayoutContext = createContext({});

const contexts = {
  'city-layout': CityLayoutContext,
};
export const useRouteLoaderData = (name: keyof typeof contexts) => {
  const context = useContext(contexts[name]);
  return context as {
    country: ICountry;
    city: ICity;
    menu: IMenuElement[];
  };
};
