import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import style from './pin.module.scss';
import { AddressError, ICoordinates, IFullAddress } from '../types';
import { useTouchControl } from '../lib/use-touch-control';

type PinState = 'button' | 'touching' | 'isFetching' | 'success' | 'error';

export interface IPin {
  mapRef: RefObject<HTMLDivElement>;
  fullAddress: IFullAddress | null;
  addressError: AddressError | null;
  isNowFetching: boolean;
  onChangeAddress: (coords: ICoordinates) => void;
  getMapCenter: () => number[] | null;
  setCurrentCenter: (center: number[] | null) => void;
}

function Pin({
  mapRef,
  fullAddress,
  addressError,
  isNowFetching,
  onChangeAddress,
  getMapCenter,
  setCurrentCenter,
}: IPin) {
  const [pinState, setPinState] = useState<PinState>(
    fullAddress || addressError ? 'button' : 'touching',
  );
  const { t } = useTranslation();

  const touching = useTouchControl(mapRef);

  useEffect(() => {
    setCurrentCenter(null);
    if (touching) {
      setPinState('touching');
    } else {
      setPinState('button');
    }
  }, [touching]);

  useEffect(() => {
    if (fullAddress) {
      setPinState('success');
      const { latitude, longitude } = fullAddress;
      onChangeAddress({ lat: Number(latitude), lng: Number(longitude) });
      return;
    }
    setPinState('button');
  }, [fullAddress]);

  useEffect(() => {
    if (isNowFetching) {
      setPinState('isFetching');
      return;
    }
    if (addressError) {
      setPinState('error');
    }
  }, [addressError, isNowFetching]);

  const onButtonClick = () => {
    const newCenter = getMapCenter();
    if (newCenter) setCurrentCenter(newCenter);
  };

  const ButtonStyle = clsx({
    [style.pinButton]: pinState === 'button',
    [style.pinButtonFetching]: pinState === 'isFetching',
    [style.pinButtonSuccess]: pinState === 'success',
    [style.pinButtonError]: pinState === 'error',
  });

  return (
    <div className={style.mobilePin}>
      {touching ? (
        <div className={style.pinBody} />
      ) : (
        <button type="button" className={ButtonStyle} onClick={onButtonClick}>
          {pinState === 'success' && <div className={style.check} />}
          {pinState === 'button' && (
            <span className={style.buttonText}>{t('Map.confirmAddress')}</span>
          )}
        </button>
      )}
      <div className={style.pinTail} />
    </div>
  );
}
export default Pin;
