import { useYMaps } from '@pbe/react-yandex-maps';
import { RefObject, useEffect, useState } from 'react';
import { ICoordinates } from '@shared/third-party-libs/commonMap/types';

const options = {
  suppressMapOpenBlock: true,
  maxZoom: 18,
  yandexMapDisablePoiInteractivity: true,
};

const libs = ['Map', 'control.ZoomControl'];
const behaviors = ['drag', 'multiTouch', 'dblClickZoom', 'scrollZoom'];
const zoom = 10;
const controls = ['zoomControl'];

export const setMapCenter = async ({
  map,
  coords,
}: {
  map: ymaps.Map;
  coords: ICoordinates;
}) => {
  const yaCoords = [Number(coords.lat), Number(coords.lng)];
  map.setCenter(yaCoords);
};

export const useYandexMap = ({
  cityCoords,
  mapRef,
}: {
  cityCoords: ICoordinates;
  mapRef: RefObject<HTMLDivElement>;
}) => {
  const ymaps = useYMaps(libs);
  const [map, setMap] = useState<ymaps.Map | null>(null);

  useEffect(() => {
    if (!ymaps || !mapRef.current) {
      return;
    }
    const center = [cityCoords.lat, cityCoords.lng];
    setMap(
      new ymaps.Map(
        mapRef.current,
        { behaviors, zoom, controls, center },
        options,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ymaps]);

  const onChangeAddress = (coords: ICoordinates) => {
    if (!map) return;
    setMapCenter({ map, coords });
    map.setZoom(17);
  };

  const getMapCenter = () => {
    if (!map) return null;
    return map.getCenter().reverse();
  };

  return { map, onChangeAddress, getMapCenter };
};
