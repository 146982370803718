import Cross from '@shared/ui/cross/Cross';
import { useTranslation } from 'react-i18next';
import { Burger } from '@shared/ui/burger/Burger';
import { useSidebarModal } from '../model/useSidebarModal';
import styles from './sideBarOpener.module.scss';

export function SideBarOpener() {
  const modal = useSidebarModal();
  const { t } = useTranslation();

  const toggleVisible = () => {
    if (modal.visible) {
      modal.hide();
    } else {
      modal.show();
    }
  };

  return (
    <button
      className={`h-full ${styles.root}`}
      onClick={toggleVisible}
      type="button"
    >
      {modal.visible ? (
        <Cross className={styles.cross} />
      ) : (
        <div className={styles.closedBlock}>
          <Burger isDesktop />
          <div className={styles.closedBlock__descr}>
            {t('SideBarOpener.title')}
          </div>
        </div>
      )}
    </button>
  );
}
