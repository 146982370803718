import { PureComponent } from 'react';
import { Helmet } from 'react-helmet-async';
import { seoEmitter } from 'modules/event-emitters';
import globalStore from 'modules/global-store';

class TemplateHelmet extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cnt: 0,
    };
  }

  componentDidMount() {
    seoEmitter.addListener('SEO_UPDATE', this.updateSeo);
    seoEmitter.addListener('UPDATE', this.reRender);
  }

  componentWillUnmount() {
    seoEmitter.removeListener('SEO_UPDATE', this.updateSeo);
    seoEmitter.removeListener('UPDATE', this.reRender);
  }

  updateSeo = () => {
    let { cnt } = this.state;
    cnt++;
    this.setState({ cnt });
  };

  reRender = () => {
    this.forceUpdate();
  };

  parser(str, patterns = {}) {
    for (const pattern in patterns) {
      const reg = new RegExp(`{${pattern}}`, 'gi');
      str = str.replace(reg, patterns[pattern]);
    }

    return str;
  }

  render() {
    const seo = { ...globalStore.get('seo', {}) };
    const request = globalStore.get('request', {});
    const { city_name } = globalStore.get('current_city', {});
    const patterns = {
      city: city_name,
    };

    const params = {
      meta: [],
      link: [],
    };
    const title = seo.title ? this.parser(seo.title, patterns) : '';
    if (seo.description) {
      params.meta.push({
        name: 'description',
        content: this.parser(seo.description, patterns),
      });
      params.meta.push({
        property: 'og:description',
        content: this.parser(seo.description, patterns),
      });
    }
    if (seo.title) {
      params.meta.push({
        property: 'og:title',
        content: title,
      });
    }

    if (seo.keywords) {
      params.meta.push({
        name: 'keywords',
        content: this.parser(seo.keywords, patterns),
      });
    }
    if (seo.canonical) {
      params.link.push({
        rel: 'canonical',
        href: `https://${request.hostname}${seo.canonical}`,
      });
    }

    return (
      <Helmet>
        {params.meta.map((attrs, i) => (
          <meta key={i} {...attrs} />
        ))}
        {params.link.map((attrs, i) => (
          <link key={i} {...attrs} />
        ))}
        <title>{title}</title>
      </Helmet>
    );
  }
}
export default TemplateHelmet;
