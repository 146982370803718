import useSWR from 'swr';
import api from 'modules/helpers/api';
import globalStore from 'modules/global-store';
import { IServerTime } from 'frontend/data/time/types';

export default function useServerTime() {
  const { locale: timezone } = globalStore.get('current_city');
  const { data, error } = useSWR<IServerTime>(
    ['cart.get_server_date', { timezone }],
    api,
  );

  return {
    data,
    error,
  };
}
