import {
  ActionsType,
  FbType,
  FieldsType,
  GAtargetsType,
  IActionsAnaliytic,
  IFbOptions,
  IGAEcom,
  IOrderingActions,
  YMtargetsType,
} from './analyticTypes';
import { paymentTypes } from '../constants/paymentTypes';
import { getLayers, getYandexIds, isNeedECom } from './analyticsHelper';

const ym = (target: keyof typeof YMtargetsType) => {
  if (window) {
    try {
      const analyticsIds = getYandexIds();

      analyticsIds.forEach((id) => {
        window.ym(id, 'reachGoal', target);
      });
    } catch (error) {
      console.log(`analytics error. ${error}`);
    }
  }
};

const gtag = (
  target: keyof typeof GAtargetsType,
  category:
    | { event_category: 'field' | 'click' | 'form' | 'form_field' }
    | IGAEcom,
) => {
  if (window) {
    try {
      return window.gtag('event', target, category);
    } catch (error) {
      console.log(`g analytics error ${error}`);
    }
  }
};

const fbq = (target: keyof typeof FbType, options: IFbOptions) => {
  if (typeof window === 'undefined') {
    return;
  }
  if (window?.fbq) {
    try {
      return options
        ? window.fbq('track', target, options)
        : window.fbq('track', target);
    } catch (error) {
      console.log(`fb analitycs error ${error}`);
    }
  }
};

export const analytics = {
  mailingAction: () => {
    ym('form_mailing_action');
    gtag('mailing_action', { event_category: 'form' });
  }, // После отправки формы подписки на рассылку

  appStoreAttention: () => {
    ym('click_appstore_attention');
    gtag('appstore_attention', { event_category: 'click' });
  }, // При клике на иконку приложения и переходе в app store

  googlePlayAttention: () => {
    ym('click_googleplay_attention');
    gtag('googleplay_attention', { event_category: 'click' });
  }, // При клике на иконку приложения и переходе в google play

  akciiAttention: () => {
    ym('click_akcii_attention');
    gtag('akcii_attention', { event_category: 'click' });
  }, // При посещении раздела "акции"

  clearCartDesire: () => {
    ym('click_clear_cart_desire');
    gtag('clear_cart_desire', { event_category: 'click' });
  }, // Пользователь кликнул "Очистить корзину"

  productCartDesire: () => {
    ym('click_product_cart_desire');
    gtag('product_cart_desire', { event_category: 'click' });
  }, // Добавление ЛЮБОГО товара в корзину откуда угодно (срабатывает 1 раз за сеанс)

  addProductAction: (from: 'catalog' | 'modal' | 'productPage') => {
    switch (from) {
      case 'catalog':
        ym('click_product_catalog_cart_desire'); // Добавление ЛЮБОГО товара в корзину из каталога
        gtag('product_catalog_cart_desire', { event_category: 'click' });
        break;
      case 'modal':
        ym('click_product_popup_cart_desire'); // Добавление ЛЮБОГО товара в корзину с попапа
        gtag('product_popup_cart_desire', { event_category: 'click' });
        break;
      case 'productPage':
        ym('click_product_card_cart_desire'); // Добавление ЛЮБОГО товара в корзину с карточки товара
        gtag('product_card_cart_desire', { event_category: 'click' });
        break;
      default:
        break;
    }
  },

  discountDesire: () => {
    ym('click_discount_desire');
    gtag('discount_desire', { event_category: 'click' });
  }, // Пользователь нажал кнопку "Отлично" после ввода проверочного кода

  promocodeYesDesire: () => {
    ym('click_promocode_yes_desire');
    gtag('promocode_yes_desire', { event_category: 'click' });
  }, // Промокод удачно активирован

  promocodeNoDesire: () => {
    ym('click_promocode_no_desire');
    gtag('promocode_no_desire', { event_category: 'click' });
  }, // После применения промокода пользователь получил сообщение "УПС! ПОХОЖЕ, ЭТОТ ПРОМОКОД НЕДОСТУПЕН.*"

  deliveryFieldsAction: (field: keyof typeof FieldsType) => {
    switch (field) {
      case 'address':
        ym('field_adress_action'); // Пользователь ввел данные в поле "Адрес"
        gtag('adress_action', { event_category: 'field' });
        break;
      case 'name':
        ym('field_name_action'); // Пользователь ввел данные в поле "Имя"
        gtag('name_action', { event_category: 'field' });
        break;
      case 'email':
        ym('field_email_action'); // Пользователь ввел данные в поле "email"
        gtag('email_action', { event_category: 'field' });
        break;
      case 'phone':
        ym('field_phone_action'); // Пользователь ввел данные в поле "Телефона"
        gtag('phone_action', { event_category: 'field' });
        break;
      case 'entrance':
        ym('field_front_action'); // Пользователь ввел данные в поле "Парадная"
        gtag('front_action', { event_category: 'field' });
        break;
      case 'comment':
        ym('field_comment_action'); // Пользователь ввел данные в поле "комментарий к заказу"
        gtag('comment_action', { event_category: 'field' });
        break;
      case 'apartment':
        ym('field_apartment_action'); // Пользователь ввел данные в поле "Квартира"
        gtag('apartment_action', { event_category: 'field' });
        break;
      case 'floor':
        ym('field_floor_action'); // Пользователь ввел данные в поле "Этаж"
        gtag('floor_action', { event_category: 'field' });
        break;
      default:
        break;
    }
  },

  addressAction: () => {
    ym('field_adress_action'); // Пользователь ввел данные в поле "Адрес"
    gtag('adress_action', { event_category: 'field' });

    ym('field_front_action'); // Пользователь ввел данные в поле "Парадная"
    gtag('front_action', { event_category: 'field' });

    ym('field_apartment_action'); // Пользователь ввел данные в поле "Квартира"
    gtag('apartment_action', { event_category: 'field' });

    ym('field_floor_action'); // Пользователь ввел данные в поле "Этаж"
    gtag('floor_action', { event_category: 'field' });

    ym('field_name_action'); // Пользователь ввел данные в поле "Имя"
    gtag('name_action', { event_category: 'field' });
  },

  errorPhoneAction: () => {
    ym('field_phone_error'); // Ошибка валидации поля "Телефона"
    gtag('phone_error', { event_category: 'field' });
  },

  fasterAction: () => {
    ym('form_field_faster_action');
    gtag('faster_action', { event_category: 'form_field' });
  }, // В отправленной форме заказа, пользователь выбрал "Как Можно Скорее"

  slowerAction: () => {
    ym('form_field_slower_action');
    gtag('slower_action', { event_category: 'form_field' });
  }, // В отправленной форме заказа, пользователь выбрал "Ко Времени"

  paymentTypeAction: (paymentType: string) => {
    switch (paymentType) {
      case paymentTypes.CASH:
        ym('form_field_cash_action'); // В отправленной форме заказа, пользователь выбрал "Картой Курьеру"
        gtag('cash_action', { event_category: 'form_field' });
        break;
      case paymentTypes.CARD_TO_COURIER:
        ym('form_field_card_action'); // В отправленной форме заказа, пользователь выбрал "Картой Курьеру"
        gtag('card_action', { event_category: 'form_field' });
        break;
      case paymentTypes.CARD_ONLINE:
        ym('form_field_online_action'); // В отправленной форме заказа, пользователь выбрал "Картой Онлайн"
        gtag('online_action', { event_category: 'form_field' });
        break;
      default:
        break;
    }
  },

  errorFieldAction: (name: keyof typeof FieldsType) => {
    switch (name) {
      case 'name':
        ym('field_name_error'); // Ошибка валидации поля "Имя"
        gtag('name_error', { event_category: 'field' });
        break;
      case 'phone':
        ym('field_phone_error'); // Ошибка валидации поля "Телефона"
        gtag('phone_error', { event_category: 'field' });
        break;
      case 'email':
        ym('field_email_error'); // Ошибка валидации поля "email"
        gtag('email_error', { event_category: 'field' });
        break;
      case 'address':
        ym('field_adress_error'); // Ошибка валидации поля "Адрес"
        gtag('adress_error', { event_category: 'field' });
        break;
      case 'apartment':
        ym('field_apartment_error'); // Ошибка валидации поля "Квартира"
        gtag('apartment_error', { event_category: 'field' });
        break;
      case 'entrance':
        ym('field_front_error'); // Ошибка валидации поля "Парадная"
        gtag('front_error', { event_category: 'field' });
        break;
      case 'floor':
        ym('field_floor_error'); // Ошибка валидации поля "Этаж".
        gtag('floor_error', { event_category: 'field' });
        break;
      default:
        break;
    }
  },
  orderingActions: ({
    paymentType,
    deliveryType,
    deliveryDate,
  }: IOrderingActions) => {
    // custom script
    // sendActionToSWCounter(id);
    try {
      // paymentTypeAction
      switch (paymentType) {
        case paymentTypes.CASH:
          ym('form_field_cash_action'); // В отправленной форме заказа, пользователь выбрал "Картой Курьеру"
          gtag('cash_action', { event_category: 'form_field' });
          break;
        case paymentTypes.CARD_TO_COURIER:
          ym('form_field_card_action'); // В отправленной форме заказа, пользователь выбрал "Картой Курьеру"
          gtag('card_action', { event_category: 'form_field' });
          break;
        case paymentTypes.CARD_ONLINE:
          ym('form_field_online_action'); // В отправленной форме заказа, пользователь выбрал "Картой Онлайн"
          gtag('online_action', { event_category: 'form_field' });
          break;
        default:
          break;
      }
      // orderDeliveryTypeAction
      switch (deliveryType) {
        case 'delivery':
          ym('form_order_delivery_action');
          gtag('order_delivery_action', { event_category: 'form' }); // После отправки формы, в которой была выбрана "доставка"
          break;
        case 'pickup':
          ym('form_order_pickup_action');
          gtag('order_pickup_action', { event_category: 'form' }); // После отправки формы, в которой был выбран "самовывоз"
          break;
        default:
          break;
      }
      // orderAllAction
      ym('form_order_all_action');
      gtag('order_all_action', { event_category: 'form' });

      if (deliveryType === 'delivery') {
        if (deliveryDate) {
          // В отправленной форме заказа, пользователь выбрал "Ко Времени"
          ym('form_field_slower_action');
          gtag('slower_action', { event_category: 'form_field' });
        } else {
          // В отправленной форме заказа, пользователь выбрал "Как Можно Скорее"
          ym('form_field_faster_action');
          gtag('faster_action', { event_category: 'form_field' });
        }
      }
    } catch {
      console.log('analitics error');
    }
  },

  // Ecommerce google UA
  gUAEcom: (data: IGAEcom) => {
    const isNeedPush = isNeedECom();
    if (!isNeedPush) return;

    try {
      gtag('purchase', data);
    } catch (error) {
      console.error(`UAEcom error => ${error}`);
    }
  },
  // Ecommerce Yandex
  ecom: (
    actionType: ActionsType,
    data: IActionsAnaliytic,
    countryCurrency: string,
  ) => {
    if (typeof window === 'undefined') return;

    const dataLayers = getLayers();

    try {
      dataLayers.forEach((layer) => {
        layer.push({
          ecommerce: {
            currencyCode: countryCurrency,
            [actionType]: data,
          },
        });
      });
    } catch (error) {
      console.error(error);
    }
  },
  // facebook pixel
  fbPixel: (target: keyof typeof FbType, options: IFbOptions = {}) => {
    fbq(target, options);
  },
};
