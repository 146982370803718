/**
 * Created by rebu on 22.02.17.
 *
 * Package Components.Phone.GetCode.GetCode
 *
 * Usage: <GetCode {...params} />
 *     params:
 *          phone: phone to send code (default NULL)
 *          [tag: tag html element (default "button")]
 *          [className: class of html element (default "phone-send-code-button")]
 *          [value: text of element (default "Request code")]
 *          [repeatValue: text for repeat message, reqular pattern %time% will be replaced on time to repeat (default "Retry after %time%")]
 *
 */
import autobind from 'autobind-decorator';
import { useTranslation } from 'react-i18next';
import { userEmitter } from 'modules/event-emitters';
import api from 'modules/helpers/api';
import globalStore from 'modules/global-store';
import { useErrorCartModal } from '@features/modals/cart-error-modal';
import AbstractClass from '../Abstract/index';

const CODES = {
  STOP_FLOOD: 403,
  CODE_ALREADY_SENT: 5,
};
class GetCode extends AbstractClass {
  className = 'phone-send-code-button';

  value = 'Request code';

  @autobind
  validateCode() {
    const {
      data: { phone },
    } = this.state;
    const { t, lang, showErrorModal } = this.props;
    const city = globalStore.get('current_city').text_id;
    api('/endpoint/phone/validate', {}, { city, phone, lang })
      .then((response) => {
        if (response.code === CODES.CODE_ALREADY_SENT) {
          userEmitter.emit('User.CodeAlreadySent', response);
        }
        localStorage.setItem('processPhone', phone);
        userEmitter.emit('User.GetCode.Response', response);
      })
      .catch(({ data }) => {
        if (data.message && data.code !== CODES.STOP_FLOOD) {
          showErrorModal({ text: t(`BlockTotal.${data.message}`) });
        } else {
          showErrorModal({
            text: `${t('localization.Error!')} ${data?.code ?? ''}`,
          });
        }
      });

    return true;
  }

  @autobind
  sendHandler() {
    const { checkedInfo, handleButtonPress } = this.props;
    handleButtonPress();
    if (checkedInfo !== undefined) {
      if (checkedInfo) {
        this.validateCode();
      }
    } else {
      this.validateCode();
    }
  }
}

function EnrichedGetCode(props) {
  const errorModal = useErrorCartModal();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <GetCode
      {...props}
      showErrorModal={errorModal.show}
      t={t}
      lang={language}
    />
  );
}

export default EnrichedGetCode;
