import { clsx } from 'clsx';
import { ISmartLink, SmartLink } from '@shared/lib/routing';
import styles from './button.module.scss';

interface ILinkButton extends ISmartLink {
  isFullWidth?: boolean;
  variant?: 'contained' | 'outlined';
}

export function LinkButton({
  className,
  children,
  variant = 'outlined',
  isFullWidth = false,
  ...props
}: ILinkButton) {
  return (
    <SmartLink
      style={{ textDecoration: 'none' }}
      className={clsx(styles.swButton, className, {
        [styles.fullWidth]: isFullWidth,
        [styles.contained]: variant === 'contained',
        [styles.outlined]: variant === 'outlined',
      })}
      {...props}
    >
      {children}
    </SmartLink>
  );
}
