import { useTranslation } from 'react-i18next';
import { useCheckCountries } from '@entities/countries';
import { SmartLink } from '@shared/lib/routing';
import { holidayCheck } from '@entities/holyday-decorations';
import RuLogoIcon from './assets/logo.svg';
import styles from './logo.module.scss';
import UaLogoIcon from './assets/uaLogo.svg';
import EnLogoIcon from './assets/enLogo.svg';
import HyLogoIcon from './assets/hyLogo.svg';
import HalloweenLogo from './assets/holiday/halloweenLogo.svg';
import NY2025Logo from './assets/holiday/ny2025Logo.svg';

interface ILogo {
  holydayName: ReturnType<typeof holidayCheck>; // used indirectly in useLogoIcon hook
  isCheckCountries: ReturnType<typeof useCheckCountries>;
}
const getLogoIcon = ({
  holydayName,
  isCheckCountries,
  language,
}: ILogo & { language: string }) => {
  const { isMontenegro, isUkraine, isArmenia } = isCheckCountries;

  if (isUkraine) return UaLogoIcon;
  if (isMontenegro) return EnLogoIcon;
  if (isArmenia) return HyLogoIcon;
  if (language !== 'ru') return EnLogoIcon;

  if (holydayName === 'halloween') return HalloweenLogo;
  if (holydayName === 'newYear2025') return NY2025Logo;

  return RuLogoIcon;
};

function Logo({ holydayName, isCheckCountries }: ILogo) {
  const { t, i18n } = useTranslation();

  const LogoIcon = getLogoIcon({
    holydayName,
    isCheckCountries,
    language: i18n.language,
  });

  return (
    <SmartLink href="/">
      <LogoIcon
        role="img"
        aria-label={t('Logo.sushiwok_logo')}
        width={200}
        height={50}
        className={styles.img}
      />
    </SmartLink>
  );
}

export { Logo };
