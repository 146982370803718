import { useTranslation } from 'react-i18next';
import { Suggest } from '@features/address-suggest';
import { DeliveryMap } from '@features/delivery-map';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@shared/lib/store';
import {
  deliverySliceSelectors,
  setDeliveryAddress,
  useGetAddressQuery,
} from '@entities/delivery';
import { skipToken } from '@reduxjs/toolkit/query';
import { ICoordinates } from '@shared/third-party-libs/commonMap';
import styles from './suggestionField.module.scss';

function SuggestionField({
  field,
  cityName,
  cityCoords,
  mapTypeByCountry,
  mapTypeByCity,
  cityTextId,
}: {
  field: any;
  cityName: string;
  cityCoords: ICoordinates;
  cityTextId: string;
  mapTypeByCountry: number | null;
  mapTypeByCity: number | null;
}) {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();

  const dispatch = useAppDispatch();

  const { fullAddress, addressError } = useAppSelector(
    deliverySliceSelectors.selectFullAddress,
  );

  const [newAddress, setNewAddress] = useState<string | number[] | null>(null);

  const params = newAddress
    ? { coordsOrString: newAddress, cityName, cityTextId, lang }
    : skipToken;
  const {
    data: newFullAddress,
    isLoading,
    isFetching,
  } = useGetAddressQuery(params);

  const setAddress = (address: string) => {
    setNewAddress(address);
  };

  const setCurrentCenter = (coors: number[] | null) => {
    setNewAddress(coors);
  };

  useEffect(() => {
    if (newFullAddress) {
      dispatch(setDeliveryAddress(newFullAddress));
    }
  }, [newFullAddress]);

  const { name, required, fieldsetClassName, invalid, value = '' } = field;

  return (
    <div
      className={`input-row ${fieldsetClassName || ''} ${
        invalid ? 'invalid' : ''
      }`}
    >
      <label htmlFor={`field-${name}`} className={required ? 'required' : ''}>
        {t('locale.address')}
      </label>

      <Suggest
        fullAddress={fullAddress}
        setAddress={setAddress}
        cityCoords={cityCoords}
        cityName={cityName}
        mapTypeByCity={mapTypeByCity}
        mapTypeByCountry={mapTypeByCountry}
        lang={lang}
        name={name}
        required={required}
        placeholder={t(`locale.Enter_address`)}
        initialValue={value}
      />
      <label htmlFor={`field-${name}`} className="bottom-label desktop">
        {t('locale.If_you_do_not_found_address')}
      </label>
      <div className={styles.mapContainer}>
        <DeliveryMap
          fullAddress={fullAddress}
          addressError={addressError}
          setCurrentCenter={setCurrentCenter}
          cityCoords={cityCoords}
          mapTypeByCity={mapTypeByCity}
          mapTypeByCountry={mapTypeByCountry}
          isNowFetching={isLoading || isFetching}
        />
      </div>
    </div>
  );
}

export default SuggestionField;
