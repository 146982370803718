import { RefObject, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useTranslation } from 'react-i18next';
import type { ICoordinates } from '@shared/third-party-libs/commonMap';
import { googleMapsConfig } from './model/googleMapsConfig';

export const setMapCenter = async ({
  map,
  coords,
}: {
  map: google.maps.Map;
  coords: { lat: number; lng: number };
}) => {
  map.setCenter(coords);
};

export const useGoogleMap = ({
  cityCoords,
  mapRef,
}: {
  cityCoords: ICoordinates;
  mapRef: RefObject<HTMLDivElement>;
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { i18n } = useTranslation();

  const { language } = i18n;
  const loader = new Loader({
    apiKey: googleMapsConfig.apiKey,
    language: language === 'ua' ? 'uk' : language,
  });

  const setGoogleMap = async () => {
    if (!mapRef.current) return;
    const { Map } = await loader.importLibrary('maps');
    const mapObject = new Map(mapRef.current, {
      center: cityCoords,
      zoom: 10,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      gestureHandling: 'greedy',
      maxZoom: 18,
      mapId: googleMapsConfig.mapId,
    });
    setMap(mapObject);
  };

  useEffect(() => {
    setGoogleMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef]);

  const onChangeAddress = (coords: ICoordinates) => {
    if (!map) return;
    setMapCenter({ map, coords });
    map.setZoom(17);
  };

  const getMapCenter = () => {
    if (!map) return null;
    const center = map.getCenter();
    if (!center) return null;
    return [center.lng(), center.lat()];
  };

  return { map, onChangeAddress, getMapCenter };
};
