const balloonDataActionID = 'buttonAction';
const balloonCloseActionID = 'balloonClose';

export const mapActionAttribute = {
  'data-action': balloonDataActionID,
};

export const mapCloseAttribute = {
  'data-action': balloonCloseActionID,
};

export const onBalloonClick = (
  target: HTMLElement,
  action: () => void,
  onClose: () => void,
) => {
  const clickType = target.getAttribute('data-action');
  switch (clickType) {
    case balloonCloseActionID:
      onClose();
      break;
    case balloonDataActionID:
      action();
      break;
    default:
  }
};
