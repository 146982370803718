const enum Country {
  Russia = 1,
  Belarus = 3,
  Kyrgyzstan = 6,
}

// Applied decorative elements. Write only what is necessary!
export const enum Type {
  header = 'header',
  logo = 'logo',
}

export const scheme = [
  // end date is not include
  {
    name: 'feb14',
    start: '12.02.2025',
    end: '19.02.2025',
    countries: [Country.Russia, Country.Belarus, Country.Kyrgyzstan],
    types: [Type.header],
  },
  {
    name: 'feb23',
    start: '19.02.2025',
    end: '27.02.2025',
    countries: [Country.Russia, Country.Belarus],
    types: [Type.header],
  },
  {
    name: 'march8',
    start: '04.03.2025',
    end: '11.03.2025',
    countries: [Country.Russia, Country.Belarus, Country.Kyrgyzstan],
    types: [Type.header],
  },
  {
    name: 'birthday',
    start: '18.04.2023',
    end: '01.06.2023',
    countries: [Country.Russia],
    types: [Type.header, Type.logo],
  },

  {
    name: 'newYear2025',
    start: '09.12.2024',
    end: '15.01.2025',
    countries: [Country.Russia],
    types: [Type.logo],
  },
  {
    name: 'halloween',
    start: '25.10.2024',
    end: '04.11.2024',
    countries: [Country.Russia],
    types: [Type.logo],
  },
] as const;
