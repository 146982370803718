import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@features/modals/modal-ids';
import { useCallback } from 'react';
import { IModalPageProps } from './types';

export const useModalPageModal = () => {
  const modal = useModal(modalIds.ModalPageModalId);
  const show = useCallback(
    ({ description, no_close, title }: IModalPageProps) =>
      modal.show({ description, no_close, title }),
    [],
  );

  return {
    ...modal,
    show,
  };
};
