import { useTranslation } from 'react-i18next';
import { OrderListItem } from './OrderListItem';
import { IUserOrder } from '../../model/types';
import styles from './orderList.module.scss';

export function OrderList({
  orders,
  showReorderModal,
}: {
  orders?: IUserOrder[];
  showReorderModal: (order: IUserOrder) => void;
}) {
  const { t } = useTranslation();

  if (!orders?.length)
    return (
      <p className={styles.defaultContent}>
        {t('Orders.when_you_make')},<br /> {t('Orders.order_will_shown')}
      </p>
    );

  return (
    <ul className={styles.root}>
      {orders.map((order) => (
        <OrderListItem
          key={order.hash}
          order={order}
          showReorderModal={() => showReorderModal(order)}
        />
      ))}
    </ul>
  );
}
