import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import client from 'modules/config/client';
import { langStorageName } from '../lib/storage-id';

export const useLanguage = (cityAvailableLangs?: string[]) => {
  const [, setCookie] = useCookies([langStorageName]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const availableLangs = cityAvailableLangs?.length
    ? cityAvailableLangs
    : [currentLanguage];

  const changeLanguage = async (lang: string) => {
    setCookie(langStorageName, lang, {
      path: '/',
      maxAge: client('client').cookieMaxAge,
    });
    // i18n.changeLanguage(lang);
    const url = new URL(window.location.href);
    url.searchParams.delete('lang');
    window.location.replace(url.toString());
  };
  return { availableLangs, currentLanguage, changeLanguage };
};
