import './PickupMap.scss';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BallonImage from 'assets/images/icon/ballon-icon.svg';
import UaLocationIcon from 'assets/images/icon/uaLocationIcon.svg';
import { checkCountries } from '@entities/countries';
import { Suggest } from '@features/address-suggest';
import { PickupMap as PickupMapWrapper } from '@features/stores-map';
import { IStore, storeHelper, useGetStoresListQuery } from '@entities/stores';
import { useSelectedStoreId } from '@entities/pickup';
import { isAddressError, useGetAddressQuery } from '@entities/delivery';
import { skipToken } from '@reduxjs/toolkit/query';
import { scrollToExecution } from 'frontend/Components/NewCartOrder/Helpers';
import { useRouteLoaderData } from '@shared/lib/routing';
import styles from './balloonContent.module.scss';

function PickupMap({
  onSelectStore,
}: {
  onSelectStore: (store: IStore) => void;
}) {
  const {
    country: { main_domain, map_type: mapTypeByCountry, alpha2 },
    city: {
      city_name: cityName,
      latitude: lat,
      longitude: lng,
      text_id: cityTextId,
      map_type: mapTypeByCity,
    },
  } = useRouteLoaderData('city-layout');
  const { isUkraine } = checkCountries(alpha2);
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const selectedStoreId = useSelectedStoreId();

  const [currentStoreId, setCurrentStoreId] = useState<number | null>(
    selectedStoreId,
  );

  const mapContaonerRef = useRef(null);
  useEffect(() => {
    if (currentStoreId && mapContaonerRef) {
      scrollToExecution(mapContaonerRef.current, 100);
    }
  }, [mapContaonerRef, currentStoreId]);

  const [newAddress, setNewAddress] = useState<string | number[] | null>(null);

  const params = newAddress
    ? { coordsOrString: newAddress, cityName, cityTextId, lang }
    : skipToken;
  const { data: fullAddress = null } = useGetAddressQuery(params);

  const setAddress = (address: string) => {
    setNewAddress(address);
  };

  const setCurrentCenter = (coors: number[] | null) => {
    setNewAddress(coors);
  };

  const { data: stores } = useGetStoresListQuery({ city: cityTextId, lang });

  const LocatioIcon = isUkraine ? UaLocationIcon : BallonImage;

  const currentStore = stores?.find((store) => store.id === currentStoreId);
  const { infoText, buttonText } = storeHelper.getPickupButtonTexts({
    t,
    store: currentStore,
  });

  const additional = infoText ? (
    <p className={styles.infoWork}>{infoText}</p>
  ) : null;

  const newFullAddress = isAddressError(fullAddress) ? null : fullAddress;
  return (
    <div className="pickup-map">
      <div className="pickup-map__toolbar">
        <div className="pickup-map__toolbar__left">
          <div
            className="geolocation-wrap"
            role="button"
            tabIndex={0}
            // onClick={getPosition}
            style={{ display: 'inline-block' }}
          >
            <LocatioIcon />
          </div>
          <div className="pickup-map__suggest">
            <div className="pickup-map__label">
              {t('PickupMap.your_address')}
            </div>
            <Suggest
              placeholder={t('List.Enter street or subway')}
              fullAddress={newFullAddress}
              setAddress={setAddress}
              cityCoords={{ lat, lng }}
              cityName={cityName}
              lang={lang}
              mapTypeByCity={mapTypeByCity}
              mapTypeByCountry={mapTypeByCountry}
            />
          </div>
        </div>
      </div>
      <div ref={mapContaonerRef} className="pickup-map__content">
        <PickupMapWrapper
          stores={stores || []}
          actionFn={onSelectStore}
          fullAddress={newFullAddress}
          setCurrentCenter={setCurrentCenter}
          cityCoords={{ lat, lng }}
          currentStoreId={currentStoreId}
          setCurrentStoreId={setCurrentStoreId}
          btnText={buttonText}
          additional={additional}
          mapTypeByCity={mapTypeByCity}
          mapTypeByCountry={mapTypeByCountry}
          isUkraine={isUkraine}
          mainDomain={main_domain}
        />
      </div>
    </div>
  );
}

export default PickupMap;
