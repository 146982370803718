import { IProduct } from '../model/types';

export const getProductMenuUrl = (
  product?: IProduct,
  productMenuTextId?: string,
) => {
  if (!product || !productMenuTextId) return '/menu';

  if (!product.text_id) return `/menu/${productMenuTextId}`;

  return `/menu/${productMenuTextId}/${product.text_id}`;
};
