import { productIds } from '@shared/config';
import { IPreparetedOrderItem } from './types';

export const addToppingsToOrderItems = (items: IPreparetedOrderItem[]) => {
  const itemsWithToppings = items.filter((item) => item.parent_id);
  const itemsWithProducts = items.filter(
    (item) => !item.parent_id && item.product_id !== productIds.sticks,
  );

  return itemsWithProducts.map((item) => ({
    ...item,
    toppings: itemsWithToppings.filter(
      (topping) => topping.parent_id === item.id,
    ),
  }));
};
