const getActions = (ids, cart) => {
  const { actions } = cart;
  const actionsArr = [];

  ids.forEach((id) => {
    actions.forEach(({ action: { gift_select = [] } = {}, id: actionId }) => {
      const finded = gift_select.find((gift) =>
        gift.some(({ product_id }) => +product_id === +id),
      );

      if (finded) {
        actionsArr.push(actionId);
      }
    });
  });
  return actionsArr;
};

const getUserGifts = (ids, userGifts) =>
  userGifts.reduce((accum, { product_id, maxQty, qty }) => {
    if (ids.find((id) => id === product_id))
      return [...accum, { product_id, maxQty, qty: 0 }];
    return [...accum, { product_id, maxQty, qty }];
  }, []);

const rounding = (number, param) => Math.ceil(number / param) * param;

const getBanknotes = (number, country_id) => {
  if (+country_id !== 1) return ['no_change'];

  let arr = [];
  const banknotes = [100, 500, 1000, 2000, 5000];

  if (!Number(number)) return [];
  number = Number(number);
  const roundingValues = [100, 500, 1000];

  roundingValues.forEach((value) => {
    const rounded = rounding(number, value);
    if (!arr.includes(rounded) && rounded !== number) {
      arr.push(rounded);
    }
  });

  arr = arr.concat(
    banknotes.filter(
      (banknote) => banknote > Math.max(...arr) && banknote > number,
    ),
  );

  return ['no_change', ...arr];
};

export { getActions, getBanknotes, getUserGifts };
