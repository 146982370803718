import { isProduct } from './isProduct';

export function getToppingsIdFromProducts(products: unknown[] = []) {
  if (!isProduct(products)) return [];

  const productWithToppings = products.filter((item) => item.toppings.length);

  return productWithToppings.reduce<number[]>((acc, item) => {
    item.toppings.forEach((id) => {
      if (!acc.includes(id)) {
        acc.push(id);
      }
    });
    return acc;
  }, []);
}
