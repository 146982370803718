import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@shared/lib/store';
import { EParams, ENotifyId } from '../../model/types';
import styles from './deliveryCheckNotify.module.scss';
import { deliverySliceSelectors } from '../../model/slice';

interface IDeliveryCheckNotifyProps {
  hasCard: boolean;
}

function DeliveryCheckNotify({ hasCard }: IDeliveryCheckNotifyProps) {
  const { t } = useTranslation();

  const { deliveryData } = useAppSelector(
    deliverySliceSelectors.selectDeliveryState,
  );

  if (!deliveryData || !deliveryData.notify || !deliveryData.notify.length)
    return null;

  const { notify } = deliveryData;

  /* в массиве params перечисляются параметры оповещения:
  cost_with_card - пользователь авторизован + есть карта друга, cost - пользователь не авторизован или авторизован, но нет карты друга */
  const notifyWithCard = notify.find((el) =>
    el.params.includes(EParams.cost_with_card),
  )?.notify_id;

  const notifyWithoutCard = notify.find((el) =>
    el.params.includes(EParams.cost),
  )?.notify_id;

  // проверка на увеличение стоимости
  const isUpperCost =
    (!hasCard && notifyWithoutCard === ENotifyId.upperCostId) ||
    (hasCard && notifyWithCard === ENotifyId.upperCostId);

  // проверка на уменьшение стоимости
  const isLowerCost =
    (!hasCard && notifyWithoutCard === ENotifyId.lowerCostId) ||
    (hasCard && notifyWithCard === ENotifyId.lowerCostId);

  return (
    <>
      {isUpperCost && (
        <span className={styles.upperCostNotify}>
          {t('BlockTotal.delivery_cost_increased')}
        </span>
      )}
      {isLowerCost && (
        <span className={styles.lowerCostNotify}>
          {t('BlockTotal.delivery_cost_reduced')}
        </span>
      )}
    </>
  );
}

export default DeliveryCheckNotify;
