import { useEffect, useRef } from 'react';

interface IUseDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function useDialog({ isOpen, onClose }: IUseDialogProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const currentDialogRef = dialogRef.current;
    if (!currentDialogRef) return;

    const handleClose = () => onClose();

    currentDialogRef.addEventListener('close', handleClose);

    if (isOpen) {
      currentDialogRef.showModal();
    } else {
      currentDialogRef.close();
    }

    return () => {
      currentDialogRef.removeEventListener('close', handleClose);
    };
  }, [isOpen, onClose]);

  return dialogRef;
}
