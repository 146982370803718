import { FetchLoaderType } from '@shared/lib/routing';
import { ISeoPage } from '@widgets/seoPage';

type Params = {
  city: string;
};

export const orderFeedbackLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string },
) => {
  const { city } = params;
  const seoPageResponse = await fetch<ISeoPage>({
    url: '/seo/page',
    params: {
      url: `/order/feedback`,
      city,
      lang,
    },
  });

  return {
    seoPageData: seoPageResponse.data,
  };
};
