import { Button } from '@shared/ui/button';
import {
  useForgetComplectationModal,
  useComplectationConfirm,
} from '@features/modals/comlectation-forget';

function ButtonPlaceOrder({
  disabled,
  onClick,
  children,
}: {
  disabled: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) {
  const modal = useForgetComplectationModal();
  const { isConfirmed } = useComplectationConfirm();

  return (
    <Button
      disabled={disabled}
      onClick={() =>
        isConfirmed ? onClick() : modal.show({ nextHandler: onClick })
      }
    >
      {children}
    </Button>
  );
}

export default ButtonPlaceOrder;
