/**
 * Created by rebu on 28.06.17.
 */
import './AddressDelete.scss';
import { Button } from '@shared/ui/button';
import './AddressEdit.json';
import { Component } from 'react';
import Modal from 'react-modal';
import autobind from 'autobind-decorator';
import api from 'modules/helpers/api';
// import * as session from 'modules/session';
import { userProfileEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { withBreakpoints } from 'frontend/hocs';

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '520px',
    top: '20%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '18px 60px 36px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
    marginBottom: '50px',
    borderRadius: '0',
  },
};

const mobileStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '790px',
    top: '100px',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '30px 10px',
    boxSizing: 'border-box',
    marginBottom: '0',
    borderLeft: '0',
    borderRight: '0',
  },
};

class AddressDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      address: {},
      updateAddress: () => {},
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    userProfileEmitter.addListener('User.Address.Delete', this.modalShow);
  }

  componentWillUnmount() {
    userProfileEmitter.removeListener('User.Address.Delete', this.modalShow);
  }

  @autobind
  onDeleteHandler() {
    const { address, updateAddress } = this.state;

    api('user.remove_address', { id: address.id })
      .then(() => {
        updateAddress();
        this.modalClose();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  @autobind
  modalClose() {
    this.setState({
      modalIsOpen: false,
    });
  }

  @autobind
  modalShow({ address, updateAddress }) {
    this.setState({
      modalIsOpen: true,
      address,
      updateAddress,
    });
  }

  render() {
    const { address } = this.state;
    const { t, isMobile } = this.props;
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.modalClose}
        style={isMobile ? mobileStyles : customStyles}
        contentLabel="modal-address-edit"
      >
        <div className="address-edit-modal">
          <div className="address-edit-modal__content">
            <p className="address-edit-modal__content__head">
              {t('AddressEdit.delete address')}
            </p>
            <p>{address.address}</p>
          </div>
          <Button onClick={this.onDeleteHandler}>
            {t('AddressEdit.delete')}
          </Button>
        </div>
        <div
          className="close-modal-btn close-modal-btn--address-edit"
          data-action="close"
          onClick={this.modalClose}
        />
      </Modal>
    );
  }
}

export default withTranslation()(withBreakpoints(AddressDelete));
