import { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { UseSugestType } from '@shared/third-party-libs/suggest';
import { googleMapsConfig } from './model/googleMapsConfig';

export const useGoogleSuggest: UseSugestType = ({
  cityCoords,
  lang,
  searchConfig,
}) => {
  const [suggestService, setSuggestService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  const { lat, lng } = cityCoords;
  const boundedBy = {
    east: lng + 0.25,
    north: lat + 0.25,
    south: lat - 0.25,
    west: lng - 0.25,
  };

  const loader = new Loader({
    apiKey: googleMapsConfig.apiKey,
    language: lang === 'ua' ? 'uk' : lang,
  });

  const setGoogleSuggestService = async () => {
    const { AutocompleteService } = await loader.importLibrary('places');
    setSuggestService(new AutocompleteService());
  };

  const suggestRequest = async (req: string) => {
    if (!suggestService || req.length < searchConfig.reqMinLength) return [];
    const res = await suggestService.getPlacePredictions({
      input: req,
      locationRestriction: boundedBy,
      types: ['address'],
    });

    const normalizedRes = res.predictions.map(
      ({ description, structured_formatting }) => ({
        displayName: description,
        value: `${structured_formatting.secondary_text}, ${structured_formatting.main_text}`,
      }),
    );
    return normalizedRes;
  };

  useEffect(() => {
    setGoogleSuggestService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    suggestRequest,
    debounceDep: suggestService,
  };
};
