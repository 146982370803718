import { useTranslation } from 'react-i18next';

export function Copyright({
  isArmenia,
  isUkraine,
}: {
  isUkraine: boolean;
  isArmenia: boolean;
}) {
  const { t } = useTranslation();
  const now = new Date();
  const year = now.getFullYear();
  return (
    <div
      itemScope
      itemType="https://schema.org/Organization"
      className="text-sm text-gray"
    >
      {t('Footer.Chain stores')} &laquo;
      {t('localization.companyName')}&raquo;
      {isUkraine || isArmenia ? (
        <span> &#169; {year}</span>
      ) : (
        <span>
          {' '}
          &#169; 2011 - {year}. Правообладатель товарного знака ООО
          &quot;ТЗНЗ&quot;
        </span>
      )}
    </div>
  );
}
