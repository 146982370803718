import { clsx } from 'clsx';
import { useSidebarModal } from '../model/useSidebarModal';
import { useStickySidebarEffect } from '../model/useStickySidebar';
import styles from './sidebar-desktop.module.scss';

export function SidebarDesktop({ children }: { children: JSX.Element }) {
  const sidebarRef = useStickySidebarEffect();
  const modal = useSidebarModal();

  return (
    <aside
      itemScope
      itemType="http://schema.org/WPSideBar"
      className={clsx(
        'hidden pt-(--header-height-mobile) lg:flex xl:pt-(--header-height-desktop)',
        styles.root,
        {
          [styles.rootActive]: modal.visible,
        },
      )}
      ref={sidebarRef}
    >
      {children}
    </aside>
  );
}
