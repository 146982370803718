import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import globalStore from 'modules/global-store';
import { useSelectCity } from '@entities/cities';
import styles from './placeOrder.module.scss';
import ButtonPlaceOrder from './ButtonPlaceOrder';
import { useButtonsConfig } from '../lib/useButtonsConfig';

interface IPlaceOrder {
  isPickupProducts: boolean;
  isDeliveryProducts: boolean;
}

const getTextPlaceOrder = ({
  t,
  isPickupProducts,
  isDeliveryProducts,
}: {
  t: TFunction;
  isPickupProducts: boolean;
  isDeliveryProducts: boolean;
}): string | undefined => {
  if (isPickupProducts && isDeliveryProducts)
    return t('NewCart.bothRestriction');
  if (isPickupProducts) return t('NewCart.pickupRestriction');
  if (isDeliveryProducts) return t('NewCart.delivery_restriction');
  return undefined;
};

export function PlaceOrder({
  isPickupProducts,
  isDeliveryProducts,
}: IPlaceOrder) {
  const { t } = useTranslation();

  const {
    user,
    cart: { total = 0 },
  } = globalStore.get('session') || {};

  const {
    available_for_order: availableForOrder,
    auth_before_ordering: needToAuth,
    is_delivery: isDeliveryInCity,
    is_pickup: isPickupInCity,
    info: {
      delivery: { order_min: orderMin },
    },
  } = useSelectCity();

  const isUser = user && Object.keys(user).length;

  const isMinAmount = total < orderMin;

  const isDisableBtnPickup = !availableForOrder || !isPickupInCity;
  const isDisableBtnDelivery =
    isMinAmount || !isDeliveryInCity || !availableForOrder;

  const { onDeliveryClick, onPickupClick } = useButtonsConfig({
    isLoginNeed: !isUser && needToAuth,
    isPickupProducts,
    isDeliveryProducts,
  });

  const textPlaceOrder = getTextPlaceOrder({
    t,
    isPickupProducts,
    isDeliveryProducts,
  });

  return (
    <div className={styles.placeOrder}>
      <h3 className={styles.title}>{t('localization.placeYourOrder')}</h3>
      {textPlaceOrder && <p className={styles.warning}>{textPlaceOrder}</p>}

      <div className={styles.buttons}>
        <ButtonPlaceOrder
          disabled={isDisableBtnDelivery}
          onClick={onDeliveryClick}
        >
          {t('NewCart.delivery')}
        </ButtonPlaceOrder>
        <ButtonPlaceOrder disabled={isDisableBtnPickup} onClick={onPickupClick}>
          {t('NewCart.pickup')}
        </ButtonPlaceOrder>
      </div>
    </div>
  );
}
