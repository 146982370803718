import queryString from 'query-string';
import { RequestError } from './request-error';

interface IObjectType {
  [key: string]: ObjectValue;
}

type ObjectValue =
  | number
  | string
  | number[]
  | string[]
  | undefined
  | IObjectType;

interface IMakeRequest extends Omit<RequestInit, 'body'> {
  url: string;
  baseURL: string;
  params: IObjectType;
  body?: IObjectType | FormData;
}

interface IResponse<R> {
  result: string;
  code: number;
  data: R; // there are responses without a data
}

export const makeRequest = async <R>({
  url: path,
  baseURL,
  params,
  body,
  headers,
  method = 'GET',
}: IMakeRequest): Promise<IResponse<R>> => {
  const queryParams = queryString.stringify(params, {
    arrayFormat: 'index',
    sort: false,
  });
  const url = new URL(path, baseURL);
  url.search = queryParams;
  const options: RequestInit = {
    method,
    headers,
  };

  if (method !== 'GET' && body) {
    options.body = body instanceof FormData ? body : JSON.stringify(body);
  }
  const response = await fetch(url.href, options);
  const responseData = await response.json();

  if (response.ok) {
    return responseData;
  }
  throw new RequestError(response, responseData);
};
