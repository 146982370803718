import { useTranslation } from 'react-i18next';
import { ExternalImage } from '@shared/ui/image';
import SocialElements from 'frontend/Components/SocialElements/SocialElements';
import { SmartLink } from '@shared/lib/routing';
import { CardWrapper } from '@shared/ui/content-containers';
import { useSelectCountryMainDomain } from '@entities/countries';
import { formatMonthWithLocale } from '@shared/lib/date';
import styles from './item.module.scss';
import { IPublicationOnMain } from '../../model/types';

function PublicationItem({
  item,
  rootHref,
}: {
  item: IPublicationOnMain;
  rootHref: string;
}) {
  const mainDomain = useSelectCountryMainDomain();
  const {
    i18n: { language },
  } = useTranslation();
  const {
    announcement_text,
    publication_date,
    title,
    text_id,
    id,
    homepage_images,
  } = item;
  const imageFile = homepage_images[0]?.filename;
  const no_photo = '@shared/ui/image/assets/no_photo.svg?url';
  const original = imageFile ? `/img/${imageFile}/1024x1024` : no_photo;
  const href = `/${rootHref}/${text_id}`;

  const dateString = formatMonthWithLocale(publication_date, language);

  return (
    <SmartLink href={href} className={styles.link}>
      <CardWrapper className={styles.item}>
        <ExternalImage
          mainDomain={mainDomain}
          src={imageFile}
          alt={title}
          width={389}
          height={450}
          sizes={{
            mobile: {
              width: 389,
              height: 450,
            },
            tablet: {
              width: 400,
              height: 450,
            },
            desktop: {
              width: 400,
              height: 450,
            },
          }}
        />
        <div className={styles.descr}>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{announcement_text}</div>
          <div className={styles.bottom}>
            <div className={styles.date}>{dateString}</div>
            <SocialElements
              link={href}
              title={title}
              description={announcement_text}
              image={original}
              id={id}
            />
          </div>
        </div>
      </CardWrapper>
    </SmartLink>
  );
}

export default PublicationItem;
