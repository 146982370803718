import { Component } from 'react';
import { pushQuery } from '@shared/lib/routing';
import { cardsEmitter } from 'modules/event-emitters';
import * as session from 'modules/session';
import sortBy from 'lodash/sortBy';
import SearchImage from 'assets/images/search.png';
import { withTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import Emitter from '../../NewCartOrder/Emitters';
import SortCheckbox from '../../FilterPanel/Components/Checkbox/SortCheckbox/SortCheckbox';
import FilterPanel from '../../FilterPanel/FilterPanel';
import Card from '../Card/Card';
import addStocks from '../CardStock/addStocks';
import styles from './cardList.module.scss';

class CardList extends Component {
  static defaultFilters = {
    attributes: {
      is_action: false,
      is_new: false,
    },
    price: SortCheckbox.STATE.NONE,
    popular: SortCheckbox.STATE.NONE,
    tags: [],
    composition: [],
  };

  static magic = (array1, array2) => {
    for (const k in array1) {
      if (array2.indexOf(array1[k]) === -1) {
        return false;
      }
    }
    return true;
  };

  constructor(props) {
    super(props);

    const { items: cart_items = [] } = session.get('cart');
    const query = session.get('query', {});
    let popular = SortCheckbox.STATE.NONE;
    let price = SortCheckbox.STATE.NONE;
    const attributes = {};
    const tags = [];
    const composition = [];

    for (let q in query) {
      switch (q) {
        case 'is_action':
        case 'is_new':
          attributes[q] = true;
          break;
        case '1':
        case '6':
          tags.push(q);
          break;
        case 'popular':
          popular = +query[q];
          break;
        case 'price':
          price = +query[q];
          break;
        default:
          if (q[0] === 's') {
            q = q.slice(1, q.length);
            if (Number.isInteger(q)) {
              composition.push(q);
            }
          }
          break;
      }
    }

    this.state = {
      view_type: 'grid',
      cart_items,
      position: props.position,
      activeFilters: Object.assign(CardList.defaultFilters, {
        attributes,
        tags,
        popular: popular || SortCheckbox.STATE.NONE,
        price: price || SortCheckbox.STATE.NONE,
        composition,
      }),
      itemsGrid: 4,
    };
  }

  componentDidMount() {
    Emitter.addListener('CART_CHANGE', this.cartChangeHandler);
    cardsEmitter.addListener(
      'ProductList.CheckedCompositions',
      this.filterByCompositionHandler,
    );
    cardsEmitter.addListener(
      'ProductList.CheckedTags',
      this.filterByTagsHandler,
    );
    cardsEmitter.addListener(
      'ProductList.sortPrice',
      this.filterByPriceHandler,
    );
    cardsEmitter.addListener(
      'ProductList.sortAttributes',
      this.filterByAttributesHandler,
    );
    cardsEmitter.addListener(
      'ProductList.sortPopular',
      this.filterByPopularHandler,
    );
    window.addEventListener('resize', this.resize);
    setTimeout(() => {
      this.resize();
    }, 100);
  }

  componentWillUnmount() {
    Emitter.removeListener('CART_CHANGE', this.cartChangeHandler);
    cardsEmitter.removeListener(
      'ProductList.CheckedTags',
      this.filterByTagsHandler,
    );
    cardsEmitter.removeListener(
      'ProductList.CheckedCompositions',
      this.filterByCompositionHandler,
    );
    cardsEmitter.removeListener(
      'ProductList.sortAttributes',
      this.filterByAttributesHandler,
    );
    cardsEmitter.removeListener(
      'ProductList.sortPrice',
      this.filterByPriceHandler,
    );
    cardsEmitter.removeListener(
      'ProductList.sortPopular',
      this.filterByPopularHandler,
    );
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    if (window.innerWidth >= 1600) this.setState({ itemsGrid: 4 });
    else if (window.innerWidth < 1600 && window.innerWidth > 1200)
      this.setState({ itemsGrid: 3 });
    else this.setState({ itemsGrid: 2 });
  };

  filterByPopularHandler = (sortedPopular) => {
    const { activeFilters } = this.state;
    this.setState({
      activeFilters: Object.assign(activeFilters, {
        popular: sortedPopular,
        price: SortCheckbox.STATE.NONE,
      }),
    });
  };

  filterByPopular = (product) => {
    const {
      activeFilters: { popular },
    } = this.state;

    switch (popular) {
      case SortCheckbox.STATE.UP:
        product = sortBy(product, (a) => a.popular);
        break;
      case SortCheckbox.STATE.DOWN:
        product = sortBy(product, (a) => a.popular).reverse();
        break;
      default:
    }

    return product;
  };

  filterByPriceHandler = (sortedPrice) => {
    const { activeFilters } = this.state;
    this.setState({
      activeFilters: Object.assign(activeFilters, {
        price: sortedPrice,
        popular: SortCheckbox.STATE.NONE,
      }),
    });
  };

  filterByPrice = (product) => {
    const {
      activeFilters: { price },
    } = this.state;
    switch (price) {
      case SortCheckbox.STATE.UP:
        product.sort((a, b) => a.price - b.price);
        break;
      case SortCheckbox.STATE.DOWN:
        product.sort((a, b) => b.price - a.price);
        break;
      case SortCheckbox.STATE.NONE:
        product.sort((a, b) => b.price + a.price);
        break;
      default:
        product.sort((a, b) => b.price + a.price);
        break;
    }

    return product;
  };

  filterByAttributesHandler = (target) => {
    const {
      activeFilters,
      activeFilters: { attributes },
    } = this.state;
    const { value, checked } = target;

    if (value === 'is_action') attributes.is_action = checked;
    if (value === 'is_new') attributes.is_new = checked;

    for (const key in attributes) {
      if (!attributes[key]) delete attributes[key];
    }
    pushQuery(attributes);
    this.setState({
      activeFilters: Object.assign(activeFilters, { attributes }),
    });
  };

  filterByAttributes = (product) => {
    const {
      activeFilters: { attributes },
    } = this.state;
    if (attributes.is_action === true && product.is_action === false) {
      return false;
    }
    return !(attributes.is_new === true && product.is_new === false);
  };

  filterByCompositionHandler = (checkedCompositions) => {
    const { activeFilters } = this.state;
    const composition = Object.keys(checkedCompositions);

    this.setState({
      activeFilters: Object.assign(activeFilters, { composition }),
    });
  };

  filterByComposition = (product) => {
    const {
      activeFilters: { composition: checked_compositions },
    } = this.state;
    const { composition: productCompositions } = product;

    if (checked_compositions.length > 0) {
      const cardCompositions = productCompositions.map(
        (c) => `s${c.composition_id}`,
      );

      return CardList.magic(checked_compositions, cardCompositions);
    }
    return true;
  };

  filterByTagsHandler = (checkedTags) => {
    const { activeFilters } = this.state;
    const tags = Object.keys(checkedTags);

    this.setState({
      activeFilters: Object.assign(activeFilters, { tags }),
    });
  };

  filterByTag = (product) => {
    const {
      activeFilters: { tags: activeTags },
    } = this.state;
    const { tags: productTags } = product;

    if (activeTags.length > 0) {
      const cardCompositions = productTags.map((t) => `${t.tag_id}`);

      return CardList.magic(activeTags, cardCompositions);
    }
    return true;
  };

  cartChangeHandler = () => {
    const { items = [] } = session.get('cart');
    this.setState({
      cart_items: items,
    });
  };

  getFilteredProducts = () => {
    const { products } = this.props;
    const {
      activeFilters: { price, popular },
    } = this.state;

    let productFilter = products.filter(
      (product) =>
        this.filterByAttributes(product) &&
        this.filterByTag(product) &&
        this.filterByComposition(product),
    );

    if (price !== SortCheckbox.STATE.NONE) {
      productFilter = this.filterByPrice(productFilter);
    }

    if (popular !== SortCheckbox.STATE.NONE) {
      productFilter = this.filterByPopular(productFilter);
    }

    if (
      price === SortCheckbox.STATE.NONE &&
      popular === SortCheckbox.STATE.NONE
    ) {
      productFilter.sort((a, b) => b.position - a.position);
    }

    productFilter.sort((a, b) => {
      const aIsLongcard = a.product_type?.name === 'longcard';
      const bIsLongcard = b.product_type?.name === 'longcard';

      if (aIsLongcard && !bIsLongcard) return -1;
      if (!aIsLongcard && bIsLongcard) return 1;

      return 0;
    });

    return productFilter;
  };

  render() {
    const { menu = {}, notFilterPanel = false, t } = this.props;
    const { view_type, cart_items, activeFilters, itemsGrid } = this.state;
    const products = this.getFilteredProducts();

    const isTagsChoosen = !!Object.keys(activeFilters.tags).length;
    const isCompositionChoosen = !!Object.keys(activeFilters.composition)
      .length;
    const isNewChoosen = !!activeFilters.attributes.is_new;
    const isActionChoosen = !!activeFilters.attributes.is_action;

    const isShowStockCard = !(
      isTagsChoosen ||
      isCompositionChoosen ||
      isNewChoosen ||
      isActionChoosen
    );

    const CardComponents = products.map((product, index) => {
      const cart_item = cart_items.find((item) => item.id === product.id);
      return (
        <Card
          key={`card-${product.id}`}
          product={product}
          view_type={view_type}
          cart_item={cart_item}
          menu={menu}
          index={index}
          position={this.state.position}
        />
      );
    });

    const ProductsWithStocks = isShowStockCard
      ? addStocks({
          CardComponents,
          itemsGrid,
          menuId: menu.id,
        })
      : CardComponents;

    const notFound = (
      <div style={{ width: '100%' }}>
        <div className={styles.notFoundTitle}>{t('CardList.no_results')}.</div>
        <div className={styles.notFoundContent}>
          <img src={SearchImage} alt={t('CardList.man_with_binoculars')} />
        </div>
      </div>
    );

    const sorts = {
      price: activeFilters.price,
      popular: activeFilters.popular,
    };

    return (
      <div
        itemScope
        itemType="http://schema.org/ItemList"
        className={clsx('card-list', styles.root)}
      >
        {!notFilterPanel ? (
          <FilterPanel
            menu_id={this.props.menu.id}
            attributes={activeFilters.attributes}
            sorts={sorts}
            checkedTags={activeFilters.tags}
            checkedComps={activeFilters.composition}
            products={this.props.products}
          />
        ) : null}

        <div
          className={styles.content}
          data-out={ProductsWithStocks.length % 3}
        >
          {ProductsWithStocks.length === 0 ? notFound : ProductsWithStocks}

          <div className="card-wrapper--grid card-wrapper--grid--empty" />
          <div className="card-wrapper--grid card-wrapper--grid--empty" />
          <div className="card-wrapper--grid card-wrapper--grid--empty" />
          <div className="card-wrapper--grid card-wrapper--grid--empty" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(CardList);
