import { RefObject } from 'react';
import { IProductDecoration } from 'types/product';

const controlCardWrapperRef = (
  ref: RefObject<HTMLDivElement>,
  decoration: IProductDecoration,
) => {
  const cardWrapperRef = ref.current;

  if (!cardWrapperRef) return;

  const {
    border_color: borderColor,
    color_border_cart_hover: borderColorHover,
  } = decoration;

  cardWrapperRef.style.setProperty('border-color', borderColor);
  cardWrapperRef.setAttribute('itemScope', '');
  cardWrapperRef.setAttribute('itemtype', 'https://schema.org/Product');

  cardWrapperRef.addEventListener('mouseenter', () => {
    cardWrapperRef.style.setProperty('border-color', borderColorHover);
  });

  cardWrapperRef.addEventListener('mouseleave', () => {
    cardWrapperRef.style.setProperty('border-color', borderColor);
  });
};

export default controlCardWrapperRef;
