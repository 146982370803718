import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@googlemaps/js-api-loader';
import { renderToStaticMarkup } from 'react-dom/server';
import { googleMapsConfig } from './model/googleMapsConfig';
import { UseStoresType } from '../commonMap/ui/PickupMap';
import { BallonLayout } from './ui/BalloonLayout';
import { onBalloonClick } from '../commonMap/lib';

export const useStoresGoogle: UseStoresType = ({
  stores,
  mapRef,
  cityCoords,
  mapPointer,
  fullAddress,
  balloonMarkup,
  setCurrentCenter,
  currentStoreId,
  setCurrentStoreId,
  actionFn,
}) => {
  type T = (typeof stores)[number];

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { i18n } = useTranslation();
  const { language } = i18n;
  const loader = new Loader({
    apiKey: googleMapsConfig.apiKey,
    language: language === 'ua' ? 'uk' : language,
  });

  const [storesGeoCollection, setStoresGeoCollection] = useState<
    google.maps.marker.AdvancedMarkerElement[] | null
  >(null);
  const [balloon, setBalloon] = useState<google.maps.InfoWindow | null>(null);
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer | null>(null);

  const setGoogleMap = async () => {
    if (!mapRef.current) return;
    const { Map } = await loader.importLibrary('maps');
    const mapObject = new Map(mapRef.current, {
      center: cityCoords,
      zoom: 10,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      gestureHandling: 'greedy',
      maxZoom: 18,
      mapId: googleMapsConfig.mapId,
    });
    setMap(mapObject);
    const { InfoWindow } = await loader.importLibrary('maps');
    setBalloon(new InfoWindow({ headerDisabled: true, minWidth: 300 }));
    const { DirectionsRenderer } = await loader.importLibrary('routes');
    setDirectionsRenderer(
      new DirectionsRenderer({
        map: mapObject,
        suppressMarkers: true,
      }),
    );
  };

  useEffect(() => {
    setGoogleMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef]);

  const openBalloon = (marker: google.maps.marker.AdvancedMarkerElement) => {
    if (!balloon) return;
    try {
      const storeId = Number(marker.id);
      const store = stores.find(({ id }) => id === storeId);
      if (!store) return;
      const listener = balloon.addListener('domready', () => {
        document
          .getElementById('popover')
          ?.addEventListener('click', (event: MouseEvent) => {
            const { target } = event;

            onBalloonClick(
              target as HTMLElement,
              () => {
                if (actionFn) actionFn(store);
              },
              () => {
                balloon.close();
                setCurrentStoreId(null);
              },
            );
          });
      });
      const closeListener = balloon.addListener('closeclick', () => {
        listener.remove();
      });

      balloon.setContent(
        renderToStaticMarkup(<BallonLayout>{balloonMarkup}</BallonLayout>),
      );

      const changeListener = balloon.addListener('content_changed', () => {
        listener.remove();
        closeListener.remove();
        changeListener.remove();
      });
      balloon.open({
        anchor: marker,
        map,
      });
      if (currentStoreId !== store.id) {
        setCurrentStoreId(store.id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setGeoCollection = async () => {
    if (!map) return [];
    const { AdvancedMarkerElement: Marker } =
      await loader.importLibrary('marker');
    setStoresGeoCollection(
      stores.reduce(
        (acc: google.maps.marker.AdvancedMarkerElement[], store: T) => {
          if (!store.latitude || !store.longitude) return acc;
          const storeCoords = {
            lat: parseFloat(String(store.latitude)),
            lng: parseFloat(String(store.longitude)),
          };

          const icon = document.createElement('img');
          icon.src = mapPointer;
          icon.width = 32;

          const marker = new Marker({
            map,
            position: storeCoords,
            content: icon,
          });
          marker.id = String(store.id);
          marker.addListener('click', () => {
            // openBalloon(marker);
            // if (!e.latLng) return;
            if (currentStoreId !== store.id) {
              setCurrentStoreId(store.id);
            }
          });
          acc.push(marker);
          return acc;
        },
        [],
      ),
    );
  };

  useEffect(() => {
    if (!map || !balloon) return;
    setGeoCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, stores.length, balloon]);

  useEffect(() => {
    if (!map) return;
    map.addListener('click', (e: google.maps.MapMouseEvent) => {
      const coords = e.latLng;
      if (!coords) return;
      setCurrentCenter([coords.lng(), coords.lat()]);
    });
  }, [map]);

  useEffect(() => {
    if (!map || !storesGeoCollection || !balloon || !currentStoreId) return;
    const currentMarker = storesGeoCollection.find(
      (marker) => marker.id === String(currentStoreId),
    );

    if (currentMarker) {
      const latLong = currentMarker.position;
      if (latLong) map.setCenter(latLong);

      openBalloon(currentMarker);
      map.setZoom(16);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, balloon, storesGeoCollection, currentStoreId]);

  const makeRoute = async (
    from: number[],
    to: number[],
    boundToRoute = false,
  ) => {
    if (!directionsRenderer) return;
    const { DirectionsService, TravelMode } =
      await loader.importLibrary('routes');
    const directionsService = new DirectionsService();

    try {
      const formattedFrom = {
        lat: from[0],
        lng: from[1],
      };
      const formatedTo = {
        lat: to[0],
        lng: to[1],
      };
      directionsService.route(
        {
          origin: formattedFrom,
          destination: formatedTo,
          travelMode: TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            directionsRenderer.setOptions({
              preserveViewport: !boundToRoute,
            });
            directionsRenderer?.setDirections(response);
          }
        },
      );
    } catch (err) {
      console.log(err);
    }
  };

  const makeRouteToClosestStore = async (lat: number, lng: number) => {
    const { spherical } = await loader.importLibrary('geometry');

    if (!storesGeoCollection || !storesGeoCollection.length) return;
    const location = { lat, lng };
    const closestStore = storesGeoCollection.reduce((prev, curr) => {
      const storeCoords = curr.position;
      if (!storeCoords) return prev;
      const cpos = spherical.computeDistanceBetween(location, storeCoords);
      const prevStoreCoords = prev.position;
      if (!prevStoreCoords) return prev;
      const ppos = spherical.computeDistanceBetween(location, prevStoreCoords);
      if (!cpos || !ppos) return prev;
      return cpos < ppos ? curr : prev;
    });
    const coords = closestStore.position as google.maps.LatLngAltitudeLiteral;
    if (!coords) return;
    makeRoute([lat, lng], [coords.lat, coords.lng], true);
    openBalloon(closestStore);
  };

  useEffect(() => {
    if (!storesGeoCollection || !fullAddress || !currentStoreId) return;
    const { latitude, longitude } = fullAddress;
    const currentStore = stores.find(({ id }) => currentStoreId === id);
    if (!currentStore) return;
    makeRoute(
      [Number(latitude), Number(longitude)],
      [currentStore.latitude, currentStore.longitude],
      false,
    );
  }, [currentStoreId, storesGeoCollection]);

  useEffect(() => {
    if (!fullAddress || !storesGeoCollection) return;
    const { latitude, longitude } = fullAddress;
    makeRouteToClosestStore(Number(latitude), Number(longitude));
  }, [fullAddress, storesGeoCollection]);

  return {};
};
