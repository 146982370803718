import { useAppSelector } from '@shared/lib/store';

const selectCountry = (state: RootState) => state.countries.country;

const selectCountryCurrency = (state: RootState) =>
  state.countries.country.currency;
const selectCountryMainDomain = (state: RootState) =>
  state.countries.country.main_domain;

export const useSelectCountry = () => useAppSelector(selectCountry);

export const useSelectCountryCurrency = () =>
  useAppSelector(selectCountryCurrency);

export const useSelectCountryMainDomain = () =>
  useAppSelector(selectCountryMainDomain);
