import { SmartLink } from '@shared/lib/routing';
import styles from './horizontalHeaderMenu.module.scss';
import { IHeaderMenu } from '../model/types';

export function HorizontalHeaderMenu({
  headerMenu,
}: {
  headerMenu: IHeaderMenu[];
}) {
  return (
    <ul
      role="navigation"
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
      className={styles.root}
    >
      {headerMenu.map((item) => (
        <li key={item.id} className={styles.item}>
          <SmartLink
            href={item.url}
            activeClassName={styles.active}
            className={styles.link}
          >
            {item.title}
          </SmartLink>
        </li>
      ))}
    </ul>
  );
}
