import './ProductSingle.scss';
import { Component } from 'react';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import productHelper from 'modules/helpers/product-helper';
import menuHelper from 'modules/helpers/menu-helper';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import { seoEmitter } from 'modules/event-emitters';
// import viewProducts from '../../helpers/viewedProducts';
import { analytics } from 'frontend/analytics/analytics';
import {
  getEcomDataToAnalytics,
  getFbPixelDataToCart,
} from 'frontend/analytics/analyticsHelper';
import { LinkButton } from '@shared/ui/button';
import { connect } from 'react-redux';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/content-containers';
import { PageTitle } from '@shared/ui/titles';
import { Preloader } from '@shared/ui/preloader';
import { Banner } from '@widgets/seoPage';
import Emitter from '../../Components/NewCartOrder/Emitters';
import Card from '../../Components/Products/Card/Card';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import SingleProductHelmet from './SingleProductHelmet';
import CustomText from '../../Components/CustomText/CustomText';

class ProductSingle extends Component {
  static LOADED_KEY = 'product_loaded';

  static initialData = (fetch, params = {}, globalStore) => {
    const {
      match: {
        params: { product: text_id },
      },
    } = params;

    const setSuperProductComponents = (product) => {
      let storedProducts = globalStore.get('products');
      if (!storedProducts) {
        globalStore.set('products', {});
        storedProducts = globalStore.get('products');
      }
      const productsToUpload = product.toppings
        .map((id) => {
          if (!storedProducts[id]) return id;
          return null;
        })
        .filter((id) => id);

      if (!productsToUpload.length) return product;

      return fetch('product.get', { id: productsToUpload }).then(
        (products = {}) => {
          if (!storedProducts) {
            globalStore.set('products', {});
            storedProducts = globalStore.get('products');
          }
          // Set components of superproduct to global store products
          products.forEach((prod) => {
            storedProducts[prod.id] = prod;
          });
          return product;
        },
      );
    };

    const prodFetch = () =>
      text_id === 'roll-mesyaca'
        ? fetch('product.products_month')
        : fetch('product.get', { text_id });

    return prodFetch()
      .then((result) => {
        const arrRes = Array.isArray(result)
          ? result.filter((res) => res.product_month === true)
          : [result];
        const product = arrRes.length > 0 ? arrRes[0] : false;

        if (!product) {
          globalStore.set('is404', true);
          globalStore.set('product_render', true);
          return [];
        }

        globalStore.set('product', product);
        globalStore.set('product_render', true);
        productHelper.getRecommendatedProducts(fetch, {
          product_id: product.id,
          quantity: 3,
        });

        if (product.product_type && product.product_type.name === 'supercard') {
          return setSuperProductComponents(product);
        }

        return product;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    const { items = [] } = session.get('cart');
    const product = globalStore.get('product');

    this.state = {
      product,
      cart_items: items,
    };
  }

  componentDidMount() {
    const { history, match } = this.props;

    if (globalStore.get(ProductSingle.LOADED_KEY)) {
      globalStore.unlink(ProductSingle.LOADED_KEY);
    } else {
      const params = {};
      params.match = match;
      ProductSingle.initialData(api, params, globalStore).then((product) => {
        if (Array.isArray(product) && !product.length) {
          history.push(`/${session.get('city')}/menu/rolly/`);
        }
        const products = globalStore.get('products', {});
        const { specialStyle } = product ? products[product.id] : {};
        if (specialStyle) {
          product.specialStyle = specialStyle;
        }
        seoEmitter.emit('UPDATE');
        if (product) {
          Emitter.addListener('CART_CHANGE', this.cartChangeHandler);
        }
        this.setState({
          product,
          isFromStocks: Boolean(
            document.referrer && document.referrer.search(/\/akcii/g) !== -1,
          ),
        });
      });
    }
    const { product } = this.state;
    const { countryCurrency } = this.props;
    analytics.ecom(
      'detail',
      getEcomDataToAnalytics({ product, qty: 1 }),
      countryCurrency,
    );
    analytics.fbPixel(
      'ViewContent',
      getFbPixelDataToCart(product, countryCurrency),
    );
  }

  componentWillUnmount() {
    Emitter.removeListener('CART_CHANGE', this.cartChangeHandler);
    globalStore.set('product_render', false);
    globalStore.set('product', {});
  }

  cartChangeHandler = () => {
    const { items = [] } = session.get('cart');
    this.setState({
      cart_items: items,
    });
  };

  getCartItem = (product) => {
    const { cart_items } = this.state;
    let cartItem;
    if (cart_items && Array.isArray(cart_items)) {
      cartItem = cart_items.find((item) => item.id === product.id);
    }
    return cartItem;
  };

  render() {
    const {
      t,
      match: { params: { menu } = {} },
      location,
    } = this.props;
    const { product, isFromStocks } = this.state;
    if (!globalStore.get('product_render')) {
      return <Preloader />;
    }
    const { product_type: { name } = {}, text_id: productTextId } =
      product || {};
    const isBigCard = name === 'longcard';
    const selected_category = menuHelper.get(menu);
    if (!selected_category || !product) {
      return <ErrorComponent />;
    }
    const { text_id = '', title = '' } = selected_category || {};
    return (
      <>
        <Banner pathname={`/menu/${text_id}/${productTextId}`} />
        <SingleProductHelmet {...{ product, pathname: location.pathname }} />
        <PageContainer>
          <Breadcrumbs
            links={[
              {
                href: `/menu/${text_id}`,
                text: title,
              },
              {
                text: product.title,
              },
            ]}
          />
          <PageTitle isCenterOnMobile>{product.title}</PageTitle>
          <div className="page-product-single">
            <div
              itemScope
              itemType="http://schema.org/Product"
              className={`card-single ${isBigCard ? 'big-card' : ''}`}
            >
              <Card
                product={product}
                view_type="single"
                position="productPage"
                cart_item={this.getCartItem(product)}
              />
              {!isBigCard && this.renderAdditional}
            </div>
          </div>
          {isFromStocks && (
            <LinkButton href="/akcii">
              {t('ProductSingle.show_all_stocks')}
            </LinkButton>
          )}
          <CustomText />
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = ({ countries }) => ({
  countryCurrency: countries.country.currency,
});

export default connect(mapStateToProps)(ssr(ProductSingle));
