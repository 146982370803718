import globalStore from 'modules/global-store';

function get(id) {
  if (!id) {
    return null;
  }
  const products = globalStore.get('products', {});
  return products[id] || null;
}

function getToppings(product) {
  const toppings = [];

  if (product.toppings && product.toppings.length) {
    product.toppings.forEach((id) => {
      const _product = get(id);
      if (_product) {
        toppings.push(_product);
      }
    });
  }

  return toppings;
}

function push(product) {
  if (!product || !product.id) {
    return;
  }
  let products = globalStore.get('products');

  if (!products) {
    globalStore.set('products', {});
    products = globalStore.get('products');
  }

  products[product.id] = product;
}

/**
 * Return Promise from api fetch
 *
 * @param fetch
 * @param params
 * @returns Promise
 */
function getRecommendatedProducts(fetch, params) {
  return fetch('product.recommendations', params)
    .then((result) => fetch('product.get', { id: result }))
    .then((result) => globalStore.set('recommendated_products', result))
    .catch((e) => {
      console.log(e);
      return [];
    });
}

function storeCartProduct(product) {
  const products = globalStore.get('cart_products', []);
  if (products.find((p) => p.id === product.id)) {
    return;
  }
  products.push(product);
  globalStore.set('cart_products', products);
}

function getCartProduct(id) {
  return globalStore.get('cart_products', []).find((i) => +i.id === +id);
}

function calculateNewStatuses(product) {
  const { prices } = product;
  const keys = Object.keys(prices);
  if (keys.length === 1) {
    switch (keys[0]) {
      case '1':
        product.is_delivery = true;
        break;
      case '2':
        product.is_pickup = true;
        break;
      default:
        product.is_pickup = true;
        product.is_delivery = true;
        break;
    }
  } else {
    product.is_pickup = true;
    product.is_delivery = true;
  }
  return product;
}

export default {
  getToppings,
  get,
  push,
  getRecommendatedProducts,
  storeCartProduct,
  getCartProduct,
  calculateNewStatuses,
};
