import RcDropdown from 'rc-dropdown';
import { PropsWithChildren, useRef } from 'react';
import 'rc-dropdown/assets/index.css';
import { DropdownProps } from 'rc-dropdown/lib/Dropdown';
import DropdownMenu from './suggest-menu/DropdownMenu';

export interface IDropdownDataItem {
  key: string;
  value: unknown;
  label: string;
}

function Dropdown({
  children,
  visible = false,
  onSelect,
  menuData,
  overlay,
  dataChange,
  onOverlayClick,
  setActiveInSuggest,
  closeDropdown,
  ...rest
}: PropsWithChildren<
  DropdownProps & {
    onSelect: (value: unknown) => void;
    dataChange: boolean;
    menuData?: IDropdownDataItem[];
    closeDropdown?: () => void;
    setActiveInSuggest?: (value: string | null) => void;
  }
>) {
  const rootRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  if (!children) return null;

  const onMenuSelect = (value: unknown) => {
    onSelect(value);
    if (closeDropdown) closeDropdown();
  };

  const menu =
    overlay ||
    (menuData && (
      <DropdownMenu
        menuRef={menuRef}
        data={menuData}
        visible={visible}
        dataChange={dataChange}
        onSelect={onMenuSelect}
        setActiveInSuggest={setActiveInSuggest}
      />
    )) ||
    undefined;

  return (
    <div ref={rootRef} style={{ width: '100%' }}>
      <RcDropdown
        visible={visible}
        overlay={menu}
        onOverlayClick={onOverlayClick || closeDropdown}
        getPopupContainer={
          rootRef.current
            ? ((() => rootRef.current) as () => HTMLDivElement)
            : undefined
        }
        {...rest}
      >
        {children}
      </RcDropdown>
    </div>
  );
}

export default Dropdown;
