import { useForgetComplectationModal } from '@features/modals/comlectation-forget';
import { useHistory } from 'react-router-dom';
import Scroll from 'react-scroll';
import { deleteProductsHandler, loginHandler } from '.';

const scrollToTop = () => {
  Scroll.animateScroll.scrollTo(0, {
    duration: 300,
    delay: 100,
  });
};

export const useButtonsConfig = ({
  isLoginNeed,
  isPickupProducts,
  isDeliveryProducts,
}: {
  isLoginNeed: boolean;
  isPickupProducts: boolean;
  isDeliveryProducts: boolean;
}) => {
  const history = useHistory();
  const modal = useForgetComplectationModal();

  const handleAfterModalPickup = (success: boolean) => {
    if (!success) {
      scrollToTop();
      return;
    }
    history.push('pickup');
  };

  const handleAfterModalDelivery = (success: boolean) => {
    if (!success) {
      scrollToTop();
      return;
    }
    history.push('delivery');
  };

  const onDeliveryClick = () => {
    if (isLoginNeed) {
      modal.hide();
      loginHandler(handleAfterModalDelivery);
    }
    if (isPickupProducts) {
      modal.hide();
      deleteProductsHandler(true, handleAfterModalDelivery);
      return;
    }
    history.push('delivery');
  };

  const onPickupClick = () => {
    if (isLoginNeed) {
      modal.hide();
      loginHandler(handleAfterModalPickup);
      return;
    }
    if (isDeliveryProducts) {
      modal.hide();
      deleteProductsHandler(false, handleAfterModalPickup);
      return;
    }
    history.push('pickup');
  };

  return {
    onDeliveryClick,
    onPickupClick,
  };
};
