import { ChangeEvent, useState } from 'react';
import { CheckboxField } from '@shared/ui/checkbox';
import { tagIdValues } from '@entities/product';
import styles from './compliance.module.scss';
import { scheme } from '../model/scheme';

interface ICompliance {
  tagsIDs: tagIdValues[];
  isAllAgree: boolean;
  setIsAllAgree: (value: boolean) => void;
}

const getDefaultState = (isAllAgree: boolean) =>
  scheme.reduce(
    (acc, { id }) => ({
      ...acc,
      [id]: isAllAgree,
    }),
    {},
  ) as { [key: string]: boolean };

export function Compliance({
  setIsAllAgree,
  isAllAgree,
  tagsIDs,
}: ICompliance) {
  const initialState = getDefaultState(isAllAgree);
  const [compliance, setCompliance] = useState(initialState);

  const filteredScheme = scheme.filter(({ id }) => {
    if (id !== 'alergicProducts') return true;
    return tagsIDs.includes(tagIdValues.allergic);
  });

  const setComplianceHandler = ({
    id,
    value,
  }: {
    id: string;
    value: boolean;
  }) => {
    const newState = { ...compliance, [id]: value };
    setCompliance(newState);
    const item = scheme.find((el) => el.id === id);
    if (!item || !item.isNecessary) return;
    const allAgree = filteredScheme.every(({ isNecessary, id: itemId }) =>
      isNecessary ? newState[itemId] : true,
    );
    setIsAllAgree(allAgree);
  };

  return (
    <ul>
      {filteredScheme.map(({ id, label }) => (
        <li key={id} className={styles.complianceRow}>
          <CheckboxField
            id={id}
            value={`${compliance[id]}`}
            defaultChecked={initialState[id]}
            key={id}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = e.currentTarget.checked;
              setComplianceHandler({ id, value });
            }}
            label={label}
          />
        </li>
      ))}
    </ul>
  );
}
