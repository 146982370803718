import globalStore from 'modules/global-store';
import Scroll from 'react-scroll';
import * as session from 'modules/session';
import productHelper from 'modules/helpers/product-helper';

const DATE_FORMAT = 'YYYY-MM-DD';

function getProduct(productId) {
  const products = globalStore.take('products');
  return products.find((product) => +product.id === +productId);
}

function getToppings(items) {
  return items?.map((item, index, arr) => {
    const product = productHelper.getCartProduct(item.product_id);
    if (product && product.title) {
      return (
        product.title +
        (arr.length - 1 !== index ? ` x ${item.qty}, ` : ` x ${item.qty}.`)
      );
    }
  });
}

function getItemsFromCart() {
  const {
    cart: { items = [], actions = [], giftSettings = {} },
    userGifts = [],
  } = session.get();
  const immutableItems = JSON.parse(JSON.stringify(items));

  actions.forEach(({ action: { gift_select = [] } = {}, id } = {}) => {
    if (giftSettings[id] && !giftSettings[id].disable) {
      const selected = giftSettings[id].select || 0;
      if (!Array.isArray(gift_select[selected])) return;
      gift_select[selected].forEach((item) => {
        if (item.product_id) {
          immutableItems.push({ product_id: item.product_id });
        }
      });
    }
  });
  userGifts.forEach(({ product_id, qty }) =>
    immutableItems.push({ product_id, qty }),
  );
  return immutableItems.filter((el) => el.qty !== 0);
}

function isOnlyForSelf() {
  const items = getItemsFromCart() || [];
  let bool = false;
  items.forEach((item) => {
    const { is_delivery = true } =
      productHelper.getCartProduct(item.product_id) || {};
    if (!is_delivery) {
      bool = true;
    }
  });
  return bool;
}

function isOnlyForDelivery() {
  const items = getItemsFromCart() || [];
  let bool = false;
  items.forEach((item) => {
    const { is_pickup = true } =
      productHelper.getCartProduct(item.product_id) || {};
    if (!is_pickup) {
      bool = true;
    }
  });
  return bool;
}

function getItemsDisablesForOrderType(isDelivery) {
  const items = getItemsFromCart() || [];
  return items
    .map((item) => {
      const productInCart = productHelper.getCartProduct(item.product_id);
      const { is_pickup = true, is_delivery = true } = productInCart || {};
      if ((isDelivery && !is_delivery) || (!isDelivery && !is_pickup))
        // Временный костыль
        return item.product_id || item.id;
      return null;
    })
    .filter((id) => id);
}

function scrollToExecution(element, height = 250) {
  if (!element) return;
  const { top } = element.getBoundingClientRect();
  const { pageYOffset } = window;

  Scroll.animateScroll.scrollTo(top + pageYOffset - height, {
    duration: 300,
    delay: 150,
  });
}

export {
  getProduct,
  getToppings,
  DATE_FORMAT,
  isOnlyForSelf,
  isOnlyForDelivery,
  scrollToExecution,
  getItemsFromCart,
  getItemsDisablesForOrderType,
};
