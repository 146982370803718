import {
  AddressError,
  DeliveryMap,
  ICoordinates,
  IFullAddress,
  getMapHook,
} from '@shared/third-party-libs/commonMap';
import { useYandexMap } from '@shared/third-party-libs/yandex';
import { useGoogleMap } from '@shared/third-party-libs/google';

interface IDeliveryMap {
  fullAddress: IFullAddress | null;
  addressError: AddressError | null;
  isNowFetching: boolean;
  cityCoords: ICoordinates;
  setCurrentCenter: (center: number[] | null) => void;
  mapTypeByCountry: number | null;
  mapTypeByCity: number | null;
}

export function DeliveryMapWrapper({
  fullAddress,
  addressError,
  isNowFetching,
  cityCoords,
  setCurrentCenter,
  mapTypeByCountry,
  mapTypeByCity,
}: IDeliveryMap) {
  const useMap = getMapHook({
    mapTypeByCity,
    mapTypeByCountry,
    yandexHook: useYandexMap,
    googleHook: useGoogleMap,
  });

  if (!useMap) return null;

  return (
    <DeliveryMap
      useMap={useMap}
      {...{
        fullAddress,
        addressError,
        isNowFetching,
        cityCoords,
        setCurrentCenter,
      }}
    />
  );
}
