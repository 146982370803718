import { debounce } from 'lodash';
import { RefObject, useEffect, useState } from 'react';

// Время задержки отображения кнопки "Подтвердить адрес"
const debounceTime = 300;

export const useTouchControl = (mapRef: RefObject<HTMLDivElement>) => {
  const [touching, setTouching] = useState(false);

  const debouncedSetTouchFalse = debounce(() => {
    setTouching(false);
  }, debounceTime);

  const onTouchStart = () => {
    setTouching(true);
    const listener = () => {
      debouncedSetTouchFalse();
      document.removeEventListener('touchend', listener);
    };
    document.addEventListener('touchend', listener);
  };

  const onMouseDown = () => {
    setTouching(true);
    const listener = () => {
      debouncedSetTouchFalse();
      document.removeEventListener('mouseup', listener);
    };
    document.addEventListener('mouseup', listener);
  };

  const onMouseWheel = () => {
    setTouching(true);
    debouncedSetTouchFalse();
  };

  useEffect(() => {
    if (!mapRef.current) return;

    const currentRef = mapRef.current;
    currentRef.addEventListener('wheel', onMouseWheel);
    currentRef.addEventListener('touchstart', onTouchStart);
    currentRef.addEventListener('mousedown', onMouseDown);
    return () => {
      currentRef.removeEventListener('wheel', onMouseWheel);
      currentRef.removeEventListener('touchstart', onTouchStart);
      currentRef.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  return touching;
};
