import { useVideoModal } from '@features/video-modal';
import { useTranslation } from 'react-i18next';
import { useCheckCountries } from '@entities/countries';
import { config } from '../model/consts';
import styles from './vacancyVideo.module.scss';

function VacancyVideo() {
  const modal = useVideoModal();

  const {
    i18n: { language },
  } = useTranslation();
  const { isRussia } = useCheckCountries();

  if (!isRussia || language !== 'ru') return null;

  return (
    <div className={styles.vacancyVideoContainer}>
      {config.map(({ title, videoLink, previewImageUrl }) => (
        <button
          key={previewImageUrl}
          className={styles.videoItem}
          onClick={() => {
            modal.show({ videoLink, title });
          }}
          type="button"
        >
          <div
            className={styles.preview}
            style={{ backgroundImage: `url(${previewImageUrl})` }}
          >
            <div className={styles.playButton} />
          </div>
          <p className={styles.title}>{title}</p>
        </button>
      ))}
    </div>
  );
}

export default VacancyVideo;
