import React, { MutableRefObject, useRef } from 'react';
import type {
  ICoordinates,
  IFullAddress,
} from '@shared/third-party-libs/commonMap';
// импорт scss необходим для управления внешними стилями гугл карт,
// через модули раелизовать не представляется возможным
import './pickup.scss';

export interface IStoreItem {
  id: number;
  latitude: number;
  longitude: number;
}

export type ClickType = 'pickup' | 'review' | 'close' | null;

export type UseStoresType = <T extends IStoreItem>({
  stores,
  mapRef,
  cityCoords,
  mapPointer,
  fullAddress,
  balloonMarkup,
  currentStoreId,
  setCurrentStoreId,
  actionFn,
}: {
  stores: T[];
  mapRef: MutableRefObject<null>;
  cityCoords: ICoordinates;
  mapPointer: string;
  fullAddress: IFullAddress | null;
  balloonMarkup: JSX.Element | null;
  setCurrentCenter: (value: number[] | null) => void;
  currentStoreId: number | null;
  setCurrentStoreId: (id: number | null) => void;
  actionFn?: (store: T) => void;
}) => {
  enableScroll?: () => void;
};

export interface IPickupMap<T extends IStoreItem> {
  useStores: UseStoresType;
  balloonMarkup: JSX.Element | null;
  stores: T[];
  cityCoords: ICoordinates;
  mapPointer: string;
  fullAddress: IFullAddress | null;
  setCurrentCenter: (value: number[] | null) => void;
  currentStoreId: number | null;
  setCurrentStoreId: (id: number | null) => void;
  actionFn?: (id: any) => void;
}

export function PickupMap<T extends IStoreItem>({
  useStores,
  balloonMarkup,
  stores,
  cityCoords,
  mapPointer,
  fullAddress,
  currentStoreId,
  setCurrentStoreId,
  setCurrentCenter,
  actionFn,
}: IPickupMap<T>) {
  const mapRef = useRef(null);

  const { enableScroll } = useStores({
    stores,
    mapRef,
    cityCoords,
    mapPointer,
    fullAddress,
    balloonMarkup,
    currentStoreId,
    setCurrentCenter,
    setCurrentStoreId,
    actionFn,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <div
      id="pickup-map-container"
      ref={mapRef}
      role="application"
      onClick={enableScroll}
      // инлайн - единственный найденный способ задать стили карте (css перебивается внешними стилями гугла)
      style={{ width: '100%', height: '100%', position: 'relative' }}
    />
  );
}
