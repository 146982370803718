export const themeCssVariables = {
  /* palette */
  '--primary-main': '#d60f14',
  '--primary-1': '#da0000',
  '--primary-2': '#da0002',
  '--secondary-main': '#870000',
  '--secondary-1': '#840607',
  '--secondary-2': '#901e1f',
  '--common-1': '#f00',
  '--common-2': '#da0002',
  '--common-3': '#da0000',
  '--common-4': '#870000',
  '--common-5': '#626265',
  '--common-6': '#000',
  '--common-7': '#da0000',
  '--common-8': '#898989',
  '--brightyellow': '#f6d330',
  '--palma': '#2ca215',
  '--green': '#63ab10',
  '--verydarkgray': '#3a3a3a',
  '--mine-shaft': '#343434',
  '--tundora': '#4a4a4a',
  '--grayishblue': '#626265',
  '--gray': '#898989',
  '--silver-chalice': '#afafaf',
  '--grayishorange': '#b4b3b2',
  '--mercury': '#efefef',
  '--lightgray': '#dbdbdb',
  '--verylightgray': '#d4d4d4',
  '--silver': '#cbcbcb',
  '--alabaster': '#f7f7f7',
  '--white': '#fff',
  '--black': '#000',
  '--footer-icon': '#d50303',
  '--basket-qty': '#f00',
  '--basket-qty-mobile': '#fff',
  '--basket-qty-mobile-bg': '#f00',
  '--basket-icon-bg': 'transparent',
  '--basket-icon': '#f00',
  '--burger-color': '#e53f45',
  '--hover-menu': '#da0002',
  '--text': '#000',

  /* variables */
  '--counter-border-radius-desktop': '50%',
  '--counter-border-radius-mobile': '50%',
  '--filter-tags-border-radius': '30px',
  '--tabs-border-radius': '4px',
  '--sw-button-border-radius': '50px',
  '--desktop-menu-description-border-radius': '0',
};
