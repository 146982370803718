import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@features/modals/modal-ids';
import type { ISelectAddressModal } from '../ui/SelectAddressModal';

export const useSelectAddressModal = () => {
  const modal = useModal(modalIds.SelectAddressModalId);

  return {
    ...modal,
    show: (args: ISelectAddressModal) => modal.show(args),
  };
};
