import { useEffect, useRef } from 'react';
import style from './styles.module.scss';

function OneLineDraw() {
  const pathRef = useRef<SVGPathElement>(null);
  const drawLine = () => {
    const oneLinePath = pathRef.current;
    if (!oneLinePath) return;
    const halfWindowHeight = window.innerHeight / 2;
    const containerTop = oneLinePath.getBoundingClientRect().top;
    const containerHeight = oneLinePath.getBoundingClientRect().height;
    const pathLength = oneLinePath.getTotalLength();

    const percentDone = (halfWindowHeight - containerTop) / containerHeight;
    const drawnLength = percentDone * pathLength;

    if (percentDone > 0) {
      oneLinePath.style.strokeDasharray = [drawnLength, pathLength].join(' ');
    } else {
      oneLinePath.style.strokeDasharray = [0, pathLength].join(' ');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', drawLine);

    return () => {
      window.removeEventListener('scroll', drawLine);
    };
  }, []);

  return (
    <svg
      id="one-line-draw"
      className={style.root}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1071 1100"
    >
      <path
        ref={pathRef}
        d="M31.4 103.8c34.2 53.3 94 88.7 162.1 88.7h684.8c106.3 0 191.7 86.2 191.7 192.5s-86.2 192.5-191.9 192.5H250.7C144.4 577.5 59 663.7 59 770s86.2 192.5 192.5 192.5h260c31.2 0 56.5 25.3 56.5 56.5v80.8"
        fill="none"
        fillRule="evenodd"
        className="stroke-verylightgray"
      />
    </svg>
  );
}

export { OneLineDraw };
