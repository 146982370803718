import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useMaskito } from '@maskito/react';
import { maskitoTimeOptionsGenerator } from '@maskito/kit';

const timeMask = maskitoTimeOptionsGenerator({
  mode: 'HH:MM',
});

export function InputTime(
  props: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
) {
  const maskedInputRef = useMaskito({ options: timeMask });
  return <input ref={maskedInputRef} {...props} />;
}
