import clsx from 'clsx';
import styles from './burger.module.scss';

export function Burger({ isDesktop = false }: { isDesktop?: boolean }) {
  const lineStyles = clsx(styles.line, { [styles.desktopColor]: isDesktop });

  return (
    <div className={styles.container}>
      <span className={lineStyles} />
      <span className={lineStyles} />
      <span className={lineStyles} />
    </div>
  );
}
