import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import { isEmpty } from 'lodash';
import type { IMenuElement } from '@entities/menu';
import type { IProduct } from 'types/product';
import { ICart } from 'types/cartTypes';
import { IFbOptions } from './analyticTypes';
import isNeedUAEcom from './uaEcomCities';

const yandexConfig = {
  all: 15024628,
  spb: 66274327,
  msk: 92354776,
  mur: 44540251,
  izhevsk: 94030706,
} as const;

const getDataProductToAnalytics = (product: IProduct, qty: number) => {
  const menu: IMenuElement[] = globalStore.get('menu');
  if (isEmpty(menu) || isEmpty(product)) return {};

  const { title } = product;

  const menuItem = menu.find((item) => +item.id === +product.menu_id);

  const { price } = product;

  return {
    id: String(product.id),
    name: title,
    price,
    quantity: Math.abs(qty),
    category: menuItem?.title ?? '',
    brand: 'Суши Wok',
  };
};

export const getDataPurchaseToGUA = ({
  id,
  countryCurrency,
  cartProducts,
  cart,
  deliveryPrice = 0,
}: {
  id: string;
  countryCurrency: string;
  cartProducts: IProduct[];
  cart: ICart;
  deliveryPrice: number;
}) => {
  const { items, total } = cart;

  if (isEmpty(items))
    return {
      items: [],
      transaction_id: id,
      value: total + deliveryPrice,
      currency: countryCurrency,
    };

  const dataPurchase = items.map(({ product_id, qty }) => {
    const product = cartProducts.find((el) => +el.id === +product_id);
    if (!product) return {};
    return getDataProductToAnalytics(product, qty);
  });

  return {
    items: dataPurchase,
    transaction_id: id,
    value: total + deliveryPrice,
    currency: countryCurrency,
  };
};

export const getEcomDataToAnalytics = ({
  product,
  qty,
}: {
  product: IProduct;
  qty: number;
}) => ({ products: [getDataProductToAnalytics(product, qty)] });

export const getFbPixelDataToCart = (
  product: IProduct,
  countryCurrency: string,
): IFbOptions => ({
  content_type: 'product',
  currency: countryCurrency,
  value: product?.price,
  content_ids: product?.id,
});

export const getFbPixelDataToPurchase = ({
  countryCurrency,
  cart,
}: {
  countryCurrency: string;
  cart: ICart;
}): IFbOptions => {
  const { items, total } = cart;
  return {
    content_type: 'product',
    currency: countryCurrency,
    value: total ?? 0,
    content_ids: items?.map(({ product_id }) => +product_id) ?? [],
  };
};

export const getFbPixelDataToViewCategory = (
  products: IProduct[],
): IFbOptions => ({
  content_type: 'product',
  content_ids: products.map(({ id }) => id),
});

export const getDataPurchaseToAnalytics = ({
  id,
  deliveryType,
  deliveryPrice = 0,
}: {
  id: string;
  deliveryType: 'delivery' | 'pickup';
  deliveryPrice: number;
}) => {
  const cart = session.get('cart');
  const cartProducts: IProduct[] = globalStore.get('cart_products');

  const { items, promo_code = '', total } = cart;

  if (isEmpty(items))
    return {
      products: [],
      actionField: { id, revenue: total + deliveryPrice, coupon: promo_code },
    };

  const dataPurchase = items.map(
    ({ product_id, qty }: { product_id: string; qty: number }) => {
      const product = cartProducts.find((el) => +el.id === +product_id);
      if (!product) return {};
      return getDataProductToAnalytics(product, qty);
    },
  );

  if (deliveryType === 'delivery') {
    dataPurchase.push({
      id: '999999',
      name: 'Доставка',
      price: deliveryPrice,
      quantity: 1,
      category: 'Доставка',
      brand: 'Суши Wok',
    });
  }

  return {
    products: dataPurchase,
    actionField: { id, revenue: total + deliveryPrice, coupon: promo_code },
  };
};

export const getYandexIds = () => {
  const yandexIds: number[] = [];
  try {
    const city: string = session.get('city');

    if (!yandexConfig) return [];

    yandexIds.push(yandexConfig.all);

    if (yandexConfig[city as keyof typeof yandexConfig]) {
      yandexIds.push(yandexConfig[city as keyof typeof yandexConfig]);
    }
  } catch {
    return [];
  }

  return yandexIds;
};

export const getLayers = () => {
  const layers: any[] = [];
  try {
    const city: string = session.get('city');
    layers.push(window.dataLayerAll);
    switch (city) {
      case 'spb':
        layers.push(window.dataLayer);
        break;
      default:
        break;
    }
  } catch (error) {
    console.log('err', error);
  }
  return layers;
};

export const isNeedECom = () => {
  const city: string = session.get('city');
  return isNeedUAEcom(city);
};
