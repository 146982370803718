import { baseApi } from '@shared/api';
import type {
  AddressError,
  IFullAddress,
} from '@shared/third-party-libs/commonMap';

interface IAddressResponse {
  address: string;
  city: string;
  district: string | null;
  house: string | null;
  is_house: boolean;
  latitude: string;
  longitude: string;
  street: string | null;
}

interface IResponse {
  code: number;
  result: string;
  data: {
    content: {
      status: string;
      payload: IAddressResponse | null;
    };
  };
}

const normalizeAddressData = (
  coordsOrString: string | string[] | number[],
  cityName: string,
) => {
  if (!coordsOrString) return coordsOrString;
  if (Array.isArray(coordsOrString)) return coordsOrString.join(',');
  if (coordsOrString.toUpperCase().indexOf(cityName.toUpperCase()) >= 0)
    return coordsOrString;
  return `${cityName} ${coordsOrString}`;
};

const responseHandling = ({
  data: {
    content: { payload },
  },
}: IResponse): IFullAddress | AddressError => {
  if (!payload) {
    return 'Address not found';
  }
  if (!payload.house) {
    return 'Address not complete';
  }
  return payload as IFullAddress;
};

export const getAddressApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAddress: build.query<
      IFullAddress | AddressError,
      {
        coordsOrString: string | string[] | number[];
        cityName: string;
        lang: string;
        cityTextId: string;
      }
    >({
      query: ({ coordsOrString, cityName, cityTextId: city, lang }) => ({
        url: `/endpoint/geo/get_address`,
        params: {
          geocode: normalizeAddressData(coordsOrString, cityName),
          city,
          lang,
        },
      }),
      transformResponse: responseHandling,
    }),
  }),
});

export const { useGetAddressQuery } = getAddressApi;
