import { currencySymbols } from '@shared/config';
import { useSelectCountryCurrency } from './selectors';

export const useCurrency = () => {
  const currency = useSelectCountryCurrency();
  return currencySymbols[currency];
};
export const getCurrencySymbol = (currency: keyof typeof currencySymbols) =>
  currencySymbols[currency];

export const withCurrency = <T extends object>(
  Component: React.ComponentType<T & { currency: string }>,
) =>
  function (props: T) {
    const currency = useCurrency();
    return <Component {...props} currency={currency} />;
  };
