import useUserIsExist from 'frontend/data/user/useUserExist';
import Emitters from 'frontend/Components/NewCartOrder/Emitters';
import { SmartLink } from '@shared/lib/routing';
import { MouseEvent } from 'react';
import Uavatar from './assets/uavatar.svg';
import styles from './profileButton.module.scss';

export function ProfileButton() {
  const { isUserExist } = useUserIsExist();
  const loginHandler = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
  ) => {
    if (!isUserExist) {
      e.preventDefault();
      Emitters.emit('MODAL_CALL', { modal: 'AuthModal' });
    }
  };

  return (
    <SmartLink href="/profile" onClick={loginHandler} className={styles.link}>
      <Uavatar width={30} height={30} className={styles.svg} />
    </SmartLink>
  );
}
