import { SmartLink } from '@shared/lib/routing';
import styles from './styles.module.scss';

export function LinkTabs({ items }: { items: Record<string, string>[] }) {
  return (
    <div className={styles.root}>
      {items.map((item) => (
        <SmartLink
          key={item.url}
          href={item.url}
          className={styles.item}
          activeClassName={styles.active}
        >
          {item.title}
        </SmartLink>
      ))}
    </div>
  );
}
