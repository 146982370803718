import { VerticalBlocks } from '../vertical/vertical-block';
import { HorizontalBlocks } from '../horizontal/horizontal-blocks';
import type { IPromoLinks } from '../../model/types';
import style from './promo-blocks.module.scss';

type elementType = {
  name: string;
  link: string;
};

const verticalDataNames = ['about', 'franchise', 'takeaway'];
const horizontalDataNames = ['youtube_embed', 'bonus'];
const horizontalDataMobileNames = ['bonus', 'youtube_link'];

const normalizePromoLinks = (promoLinks: IPromoLinks) => {
  const { horizontalData, ...rest } = Object.entries(promoLinks).reduce<{
    verticalData: elementType[];
    horizontalData: elementType[];
    horizontalDataMobile: elementType[];
  }>(
    (acc, [key, value]) => {
      if (verticalDataNames.includes(key)) {
        const index = verticalDataNames.indexOf(key);
        acc.verticalData[index] = { name: key, link: value };
      }
      if (horizontalDataNames.includes(key)) {
        const index = horizontalDataNames.indexOf(key);
        acc.horizontalData[index] = { name: key, link: value };
      }
      if (horizontalDataMobileNames.includes(key)) {
        const index = horizontalDataMobileNames.indexOf(key);
        acc.horizontalDataMobile[index] = { name: key, link: value };
      }
      return acc;
    },
    { verticalData: [], horizontalData: [], horizontalDataMobile: [] },
  );

  const horizontalDataWithDownload = [
    ...horizontalData.slice(0, 1),
    { name: 'download', link: '' },
    ...horizontalData.slice(1),
  ];
  return {
    horizontalDataWithDownload,
    ...rest,
  };
};
export function PromoBlocks({ promoLinks }: { promoLinks?: IPromoLinks }) {
  if (!promoLinks) return null;
  const { verticalData, horizontalDataWithDownload, horizontalDataMobile } =
    normalizePromoLinks(promoLinks);
  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.root}>
          <section className={style.vertical}>
            <VerticalBlocks data={verticalData} />
          </section>
          <section className={style.horizontal}>
            <HorizontalBlocks
              data={horizontalDataWithDownload}
              mobileData={horizontalDataMobile}
            />
          </section>
        </div>
      </div>
    </div>
  );
}
