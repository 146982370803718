import './ConstructorWidget.scss';
import bind from 'autobind-decorator';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withCheckCountries } from '@entities/countries';
import { UrlShortener } from '@features/url-shortener';

class WokConstructorWidget extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.drivingWidget);
    this.drivingWidget();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.drivingWidget);
  }

  @bind drivingWidget() {
    const height = this.widget.offsetHeight;
    const top = this.widget.offsetTop;
    const heightContainer = this.container.offsetHeight;

    const scroll = top + height;

    const pageY = window.pageYOffset + heightContainer;

    if (pageY > scroll) {
      this.container.classList.add('relative');
    } else {
      this.container.classList.remove('relative');
    }
  }

  render() {
    const { constructor, button, currency, type, t, isUkraine } = this.props;

    return (
      <div
        className="constructor-widget"
        ref={(widget) => (this.widget = widget)}
      >
        <div
          className="constructor-widget__wrapper"
          ref={(container) => (this.container = container)}
        >
          <h3 className="constructor-widget__title">
            {t(`ConstructorWidget.your_${type}`)}
          </h3>
          <ul className="constructor-widget__list">
            <li className="constructor-widget__list__option">
              <span>{constructor.title}</span>
              <span>
                {constructor.basePrice} {currency}
              </span>
            </li>
            {constructor.collectToppings((topping) => (
              <li
                className="constructor-widget__list__option"
                key={`list-${topping.id}`}
              >
                <span className="short">{topping.title}</span>
                <span>{`${constructor.numberOfTopping(topping)} x ${
                  topping.price
                } ${currency}`}</span>
              </li>
            ))}
          </ul>
          <div className="constructor-widget__price">
            <span>
              {constructor.totalPrice} {currency}
            </span>
          </div>
          <div className="constructor-widget__btn-container">
            {button}
            {!isUkraine && <UrlShortener />}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(withCheckCountries(WokConstructorWidget));
