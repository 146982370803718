import { useEffect } from 'react';

interface WindowWithWebim extends Window {
  webim: {
    accountName: string;
    domain: string;
    location: string;
  };
}
function WebimButton() {
  useEffect(() => {
    (window as unknown as WindowWithWebim).webim = {
      accountName: 'sushiwokru001',
      domain: 'sushiwokru001.webim.ru',
      location: 'default',
    };

    const link = document.createElement('a');
    link.className = 'webim_button';
    link.href = '#';
    link.rel = 'webim';

    const img = document.createElement('img');
    img.src = 'https://sushiwokru001.webim.ru/button.php';

    link.appendChild(img);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://sushiwokru001.webim.ru/js/button.js';
    if (document.readyState === 'complete') {
      document.body.appendChild(link);
      document.body.appendChild(script);
    } else {
      document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
          document.body.appendChild(link);
          document.body.appendChild(script);
        }
      });
    }
  }, []);

  return null;
}

function SupportChat({
  isRussia,
  isUkraine,
}: {
  isRussia: boolean;
  isUkraine: boolean;
}) {
  return (
    <>
      {isUkraine && (
        <script
          async
          type="text/javascript"
          src="//widgets.binotel.com/chat/@widgets/DKLefGNgIaKgVeYLgVyL.js"
        />
      )}
      {isRussia && <WebimButton />}
    </>
  );
}

export default SupportChat;
