import { useTranslation } from 'react-i18next';
import { SmartLink } from '@shared/lib/routing';
import { Fragment } from 'react';
import clsx from 'clsx';

export function Breadcrumbs({
  links,
}: {
  links: { text?: string; href?: string }[];
}) {
  const { t } = useTranslation();

  const homeLink = {
    text: t('localization.Main'),
    href: '/',
  };
  const list = (
    <ol
      itemScope
      itemType="http://schema.org/BreadcrumbList"
      className="flex flex-wrap gap-2"
    >
      {[homeLink, ...links].map(({ href, text = '' }, index, collection) => {
        const capitalizeText = text.charAt(0).toUpperCase() + text.substring(1);
        const isLast = collection.length - 1 === index;

        return (
          <Fragment key={text}>
            <li
              className="text-gray"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              {isLast ? (
                <span aria-current="location" itemProp="name">
                  {capitalizeText}
                </span>
              ) : (
                <SmartLink
                  itemProp="item"
                  href={href}
                  className="hover:border-b hover:border-dashed hover:border-gray"
                >
                  <span itemProp="name">{capitalizeText}</span>
                </SmartLink>
              )}
              <meta itemProp="position" content={String(index + 1)} />
            </li>
            {!isLast && (
              <span aria-hidden="true" className="text-gray">
                {'>>'}
              </span>
            )}
          </Fragment>
        );
      })}
    </ol>
  );
  const isHiddenInMobile = links.length <= 1;
  return (
    <nav
      aria-label="Breadcrumb"
      className={clsx(isHiddenInMobile && 'hidden lg:block')}
    >
      {list}
    </nav>
  );
}
