import { defaultLang } from './default-lang';

/**
 * Determine the language to use from the given options.
 *
 * @param {{
 *   availableLangs: string[] | undefined,,
 *   cityLang: string | undefined,
 *   countryLang: string | undefined,
 *   queryLang: string | undefined | null,
 *   cookieLang: string | undefined ,
 * }} options
 * @returns {string} The chosen language.
 */
export const langDetector = ({
  availableLangs,
  cityLang,
  countryLang,
  queryLang,
  cookieLang,
}: {
  availableLangs?: string[];
  cityLang?: string;
  countryLang?: string;
  queryLang?: string | null;
  cookieLang?: string;
}): string => {
  const fallbackLang = cityLang || countryLang || defaultLang;
  const supportedLngs = availableLangs || [defaultLang];
  const selectedLang = [queryLang, cookieLang, fallbackLang].find((lang) =>
    supportedLngs.includes(lang ?? ''),
  );
  return selectedLang ?? supportedLngs[0];
};
